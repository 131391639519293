import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Charts, MailingList, LynxTranslations, Translations, Wasm } from '.'

export const SaharaPages = (): React.ReactElement => {
	return (
		<Routes>
			<Route path="charts" element={<Charts />} />
			<Route path="mailing-list" element={<MailingList />} />
			<Route path="translations" element={<Translations />} />
			<Route path="lynxTranslations" element={<LynxTranslations />} />
			<Route path="wasm" element={<Wasm />} />
		</Routes>
	)
}
