import { PermissionsType } from '@lynx/core/src/interfaces'
import { UserProfile } from '@lynx/core/src/interfaces/User'

export const ADD_BUSY = '@@profile/ADD_BUSY'
export const REMOVE_BUSY = '@@profile/REMOVE_BUSY'
export const LOGIN = '@@profile/LOGIN'
export const LOGINTOKEN = '@@profile/LOGINTOKEN'
export const LOGOUT = '@@profile/LOGOUT'
export const CHANGE_PROFILE_CONTEXT_MENU = '@@profile/CHANGE_PROFILE_CONTEXT_MENU'
export const SET_IS_PRO = '@@profile/SET_IS_PRO'
export const SET_USER_PERMISSIONS = '@@profile/SET_USER_PERMISSIONS'
export const SET_LOGIN_MODAL = '@@profile/SET_LOGIN_MODAL'
export const SET_LANG = '@@profile/SET_LANG'
export const SET_PROFILE_PICTURE = '@@profile/SET_PROFILE_PICTURE'
export const SET_EMAIL = '@@profile/SET_EMAIL'
export const SET_USER_SESSION_END = '@@profile/SET_USER_SESSION_END'
export const SET_DISPLAYNAME = '@@profile/SET_DISPLAYNAME'
export const SET_ZOOM = '@@profile/SET_ZOOM'
export const ACCEPTED_TERMS = '@@profile/ACCEPTED_TERMS'

export interface ProfileState {
	readonly email: string
	readonly displayName: string
	readonly role: number
	readonly loggedIn: boolean
	readonly gravatarUrl: string
	readonly avatarBlobUrl: string | null
	readonly userId: string
	readonly lang: string | null
	readonly location: string
	readonly permissions: PermissionsType
	readonly profileContextMenuOpen: boolean
	readonly showLoginModal: boolean
	readonly stickyLoginModal: boolean
	readonly showZoom: boolean
	readonly userSessionEnd: boolean
	readonly isPro: boolean
	readonly isTermsAccepted: boolean
}

export interface SetLoginModalAction {
	type: typeof SET_LOGIN_MODAL
	payload: {
		showLoginModal: boolean
		stickyLoginModal: boolean
	}
}

export interface Login {
	username: string
	password: string
	code?: string
	rememberMe: boolean
	scheme?: string
}

export interface LoginAction {
	type: typeof LOGIN
	payload: UserProfile
}

export interface setZoomAction {
	type: typeof SET_ZOOM
	payload: boolean
}

export interface SetEmailAction {
	type: typeof SET_EMAIL
	payload: string
}

export interface SetDisplayNameAction {
	type: typeof SET_DISPLAYNAME
	payload: string
}

export interface LoginTokenAction {
	type: typeof LOGINTOKEN
	payload: UserProfile
}

export interface LogoutAction {
	type: typeof LOGOUT
}

export interface setLangAction {
	type: typeof SET_LANG
	payload: string
}

export interface SetIsPro {
	type: typeof SET_IS_PRO
	payload: {
		isPro: boolean
	}
}

export interface SetUserPermissions {
	type: typeof SET_USER_PERMISSIONS
	payload: {
		permissions: PermissionsType
	}
}

export interface setProfilePictureAction {
	type: typeof SET_PROFILE_PICTURE
	payload: { avatarBlobUrl?: string; url: string }
}

interface ChangeProfileContextMenu {
	type: typeof CHANGE_PROFILE_CONTEXT_MENU
	payload: {
		open: boolean
	}
}

interface SetUserSessionEnd {
	type: typeof SET_USER_SESSION_END
	payload: boolean
}

interface AcceptedTermsAction {
	type: typeof ACCEPTED_TERMS
}

export type ProfileActionTypes =
	| LoginTokenAction
	| LoginAction
	| LogoutAction
	| ChangeProfileContextMenu
	| SetLoginModalAction
	| setLangAction
	| SetEmailAction
	| setZoomAction
	| SetDisplayNameAction
	| SetUserSessionEnd
	| setProfilePictureAction
	| AcceptedTermsAction
	| SetIsPro
	| SetUserPermissions
