import { DefaultErrorResponseType, ErrorResponseType } from '../interfaces/ServerErrors'
export * from './permissionsUtils'
export interface TokenData {
	fileOwnerId: string
	shareId: string
	driveItemId: string
	provider: string
	providerContainerId: string
}

export const sleepAsync = (ms: number): Promise<void> => {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export const resolveTemplatedString = <P, Q>(urlString: string, params: P | { [x: string]: unknown }, queryParams?: Q | { [x: string]: unknown }): string => {
	let urlWithReplacedParams = Object.keys(params || {}).reduce(
		(acc, key) => acc.replace(`{{${key}}}`, encodeURI(String(params[key as keyof typeof params]))),
		urlString
	)

	const formattedQueryParamsString = queryParams
		? Object.keys(queryParams).reduce((acc: string[], key) => [...acc, `${key}=${encodeURI(String(queryParams[key as keyof typeof queryParams]))}`], [])
		: []

	urlWithReplacedParams = formattedQueryParamsString.length ? urlWithReplacedParams + `?${formattedQueryParamsString.join('&')}` : urlWithReplacedParams

	if (urlWithReplacedParams.indexOf('{{') !== -1 && urlWithReplacedParams.indexOf('}}') !== -1)
		throw new Error(`string template ${urlWithReplacedParams} may not have been fully resolved`)
	return urlWithReplacedParams
}

export const resolveShareToken = (token: string): string => {
	if (token.substring(0, 7) === 'shared:') {
		token = token.split(':')[1]
	}
	return token
}

export const splitToken = (
	token: string
): { fileOwnerId: string; shareId: string; driveItemId: string; provider: 'lynxcloud' | 'organisation'; providerContainerId: string } => {
	token = resolveShareToken(token)
	token = atob(token)

	const tokenArr = token.split(':')

	return {
		fileOwnerId: tokenArr[0],
		shareId: tokenArr[1],
		driveItemId: tokenArr[2],
		provider: tokenArr[3] as 'lynxcloud' | 'organisation',
		providerContainerId: tokenArr[4]
	}
}

export const createShareToken = ({ fileOwnerId, shareId, driveItemId, provider, providerContainerId }: TokenData): string => {
	return btoa(`${fileOwnerId}:${shareId}:${driveItemId}:${provider}:${providerContainerId}`)
}

export const promiseAllSettled = <T>(promises: Promise<T>[]): Promise<PromiseSettledResult<T>[]> => {
	return Promise.all(
		promises.map((promise) =>
			promise
				.then((value) => ({
					status: 'fulfilled' as const,
					value
				}))
				.catch((reason) => ({
					status: 'rejected' as const,
					reason
				}))
		)
	)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const extractResponseError = <EO = unknown>(err: any): (EO extends unknown ? ErrorResponseType : DefaultErrorResponseType<EO>) | null => {
	const isServerResponseError = Boolean(
		typeof err?.response?.data === 'object' && 'options' in err.response.data && 'message' in err.response.data && 'name' in err.response.data
	)
	if (isServerResponseError) return err.response.data
	return null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEqualShallow = (obj1: any, obj2: any): boolean => {
	if (obj2 === undefined) return false
	return (
		Object.keys(obj1).length === Object.keys(obj2).length &&
		(Object.keys(obj1) as (keyof typeof obj1)[]).every((key) => {
			return Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key]
		})
	)
}

export const isUUID = (str: string): boolean => {
	const anyRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
	return anyRegex.test(str)
}

export const strokesToSVG = (
	strokes: { x: number[]; y: number[] }[]
): {
	svgString: string
	width: number
	height: number
} => {
	// Start the SVG element
	let minX = strokes[0].x[0]
	let maxX = strokes[0].x[0]
	let minY = strokes[0].y[0]
	let maxY = strokes[0].y[0]

	strokes.forEach((stroke) => {
		// Update min and max values for x
		minX = Math.min(minX, ...stroke.x)
		maxX = Math.max(maxX, ...stroke.x)
		// Update min and max values for y
		minY = Math.min(minY, ...stroke.y)
		maxY = Math.max(maxY, ...stroke.y)
	})
	const scaleDownValues = (originalWidth: number, originalHeight: number, scaleToValue: number): { scaledWidth: number; scaledHeight: number } => {
		// Determine if scaling is necessary
		let scale = 1
		if (originalWidth > scaleToValue || originalHeight > scaleToValue) {
			// Calculate the scale to get either width or height down to scaleToValue px, preserving aspect ratio
			scale = Math.min(scaleToValue / originalWidth, scaleToValue / originalHeight)
		}

		// Calculate the scaled dimensions
		const scaledWidth = originalWidth * scale
		const scaledHeight = originalHeight * scale

		return { scaledWidth, scaledHeight }
	}

	// Calculate width and height for the viewBox
	const width = maxX - minX
	const height = maxY - minY

	// Construct the viewBox attribute value
	const viewBox = `${minX} ${minY} ${width} ${height}`
	const { scaledWidth, scaledHeight } = scaleDownValues(width, height, 100)
	let svgContent = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}" height="${scaledHeight}px" width="${scaledWidth}px">`

	// Iterate through each stroke
	strokes.forEach((stroke) => {
		// Ensure the stroke has at least one point
		if (stroke.x.length > 0 && stroke.y.length > 0) {
			// Start the path element for this stroke
			let pathData = `M ${stroke.x[0]} ${stroke.y[0]} ` // Move to the first point

			// Add line segments to the path
			for (let i = 1; i < stroke.x.length; i++) {
				pathData += `L ${stroke.x[i]} ${stroke.y[i]} `
			}

			// Add the path element to the SVG content
			svgContent += `<path d="${pathData}" style="fill:none;stroke:white;stroke-width:5" />`
		}
	})

	// Close the SVG element
	svgContent += '</svg>'

	return {
		svgString: svgContent,
		width: scaledWidth,
		height: scaledHeight
	}
}

export const getStripeDashboardPath = (isProd: boolean): string => {
	return isProd ? 'https://dashboard.stripe.com' : 'https://dashboard.stripe.com/test'
}
