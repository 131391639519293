import ModalBase from '@lynx/client-core/src/components/Modal/Modal'
import { AccountProfile, PermissionsKeys, UserPermissionsKeys } from '@lynx/core/src/interfaces'
import { UserPermissions } from './UserPermissions'
import { userPermissions } from '@lynx/core/src/constants'
import { APIRequest } from '@lynx/client-core/src/api'
import { SettingsIcon } from '@lynx/client-core/src/components'
import css from './UserPermissions.module.scss'

interface Props {
	selectedUserPermissionsEdit: AccountProfile
	onModalClose: () => void
	onPermissionUpdated: () => Promise<void>
}

export const UserPermissionsModal = ({ selectedUserPermissionsEdit, onModalClose, onPermissionUpdated }: Props): React.ReactElement => {
	const togglePermission = async (permissionKey: PermissionsKeys): Promise<void> => {
		const toggleFunction = selectedUserPermissionsEdit.permissions[permissionKey].isGranted
			? APIRequest.User.revokeUserPermission
			: APIRequest.User.grantUserPermission
		await toggleFunction(selectedUserPermissionsEdit.userId, permissionKey)
		await onPermissionUpdated()
	}
	return (
		<ModalBase
			name="User Permissions"
			title={
				<>
					<SettingsIcon className={css.modalIcon} /> User Permissions
				</>
			}
			uncontrolled
			onHidden={(): void => onModalClose()}
		>
			<p>{selectedUserPermissionsEdit.email}</p>
			<UserPermissions permissionKeys={[...userPermissions]} permissions={selectedUserPermissionsEdit.permissions} togglePermission={togglePermission} />
		</ModalBase>
	)
}
