import { ClientProviders, DriveItemType } from '@lynx/client-core/src/api/interfaces'
import { EmptyDirectory, OrganisationAvatar, WarningYellowIcon } from '@lynx/client-core/src/components'
import { FileDropper } from '@lynx/client-core/src/components/FileDropper'
import { ProfileDriveItem } from '@lynx/core/src/interfaces/ObjectStore'
import { useThunkDispatch } from 'hooks'
import { DriveItem } from 'pages/Drives/DriveItem'
import { DriveItemTemplate, DriveItemTemplateLoadingSkeleton } from 'pages/Drives/DriveItemTemplate'
import { DriveItemRecentFiles } from 'pages/Drives/DriveItemsRecentFiles'
import { useNextPageTrigger } from 'pages/Drives/hooks/useNextPageTrigger'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ApplicationState } from 'store'
import { changeDrive, deselectDriveItem } from 'store/drive'
import css from './DriveList.module.scss'
import { Props } from './DriveList.types'
import { useDriveSort } from './hooks/useDriveSort'
import { showModal } from '@lynx/client-core/src/store/modal'
import { authenticateProvider } from 'pages/Drives/Drive.helpers'
import { AuthCookie } from '@lynx/client-core/src/modules'
import { RelinkModal } from 'pages/Drives/Modals/RelinkModal/RelinkModal'
import { RelinkModalContextType } from 'store/modal'

export const DriveList = ({ sortDirection, selectedFilter, onFileDropper, handleItemClick, onFavouriteClick }: Props): React.ReactElement | null => {
	const [dragging, setDragging] = useState(false)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const dispatchThunk = useThunkDispatch()
	const navigate = useNavigate()
	const {
		config: { API_SERVER, ENVIRONMENT },
		drive: { provider, writeable, loadingItemsCount, data, style, currentPath, favourites, drives, drivesSizes }
	} = useSelector((state: ApplicationState) => state)

	const listClasses = [style === 'table' ? css.listContainerMobile : css.listContainer]
	const isHome = currentPath.name === 'Home'

	const { lastBookElementRef } = useNextPageTrigger()

	const handleDragEnter = (): void => {
		if (!provider || !writeable) {
			return
		}
		setDragging(true)
	}

	const handleDragEnd = (): void => {
		setDragging(false)
	}

	const handleDeselectItem = (): void => {
		dispatch(deselectDriveItem())
	}

	const sortedItems = useDriveSort([...data.children], sortDirection)

	const items = sortDirection ? sortedItems : data.children

	const filteredItems = items.filter((item: DriveItemType) => {
		if (!isHome) {
			return true
		}
		if (selectedFilter === 'all') {
			return true
		}
		if (selectedFilter === 'favourites') {
			const isFavourite = favourites?.find((favourite) => favourite.itemId === item.id)
			return Boolean(isFavourite)
		}
	})

	const itemToElement = (item: DriveItemType, i: number): JSX.Element => (
		<DriveItem item={item} key={item.id + i} handleItemClick={handleItemClick} onFavouriteClick={onFavouriteClick} ref={lastBookElementRef} />
	)

	const loadingStateItems = Array.from({ length: loadingItemsCount }, (_, i) => <DriveItemTemplateLoadingSkeleton key={'loading-state' + i} />)

	const driveItems = (
		<React.Fragment>
			{Boolean(data.children.length) && (
				<div className={listClasses.join(' ')}>
					{filteredItems.map(itemToElement)}
					{loadingStateItems}
				</div>
			)}
			{Boolean(!data.children.length) && provider && currentPath.id !== 'sharedwithme' && (
				<div className={css.emptyDirectory}>
					<EmptyDirectory />
					<h4>{t('pages.drive.dropFilesHere')}</h4>
					<h4 className={css.subText}>{t('pages.drive.orNewButton')}</h4>
				</div>
			)}
		</React.Fragment>
	)

	const itemToSystemDrive = ({ icon, name, driveId, inError, errorMessage, label, provider, providerContainerId }: ProfileDriveItem): React.ReactElement => {
		const driveUsage = drivesSizes.find((drive) => (drive.driveId ? drive.driveId === driveId : true) && drive.provider === provider)
		const usagePercentage = driveUsage ? (driveUsage.usedBytes / driveUsage.totalBytes) * 100 : null
		const thumbnail = provider === 'organisation' ? <OrganisationAvatar avatarUrl={icon} /> : <img height={'100%'} src={icon} alt="img" />

		return (
			<DriveItemTemplate
				key={driveId}
				thumbnail={thumbnail}
				size={0}
				usagePercentage={usagePercentage}
				text={label}
				extra={
					inError
						? {
								icon: <WarningYellowIcon />,
								tooltip: errorMessage?.tooltip || t('server.drive.error.tooltip'),
								onClick: (): void => {
									dispatch(showModal({ name: 'RelinkModal', context: { type: RelinkModalContextType, provider } }))
								}
						  }
						: undefined
				}
				subText={name}
				isFavourite={false}
				handleItemClick={async (): Promise<void> => {
					if (inError) {
						authenticateProvider(API_SERVER, provider, AuthCookie.getAccessToken(ENVIRONMENT))
						return
					}
					await dispatchThunk(changeDrive(provider as ClientProviders, providerContainerId))
					if (!writeable) return
					if (providerContainerId) {
						navigate(`/drive/${provider}?driveId=${providerContainerId}`)
					} else {
						navigate(`/drive/${provider}`)
					}
				}}
			/>
		)
	}

	const driveElements = drives.visible.map(itemToSystemDrive)

	return (
		<div className={css.container} onClick={handleDeselectItem} onDragEnter={handleDragEnter}>
			{isHome ? (
				<div className={listClasses.join(' ')}>
					{driveElements}
					<DriveItemRecentFiles handleItemClick={handleItemClick} />
				</div>
			) : (
				driveItems
			)}
			{provider && <FileDropper active={dragging} onEnded={handleDragEnd} onDropped={onFileDropper} />}
			<RelinkModal
				onRelinkClicked={(provider: string): void => {
					authenticateProvider(API_SERVER, provider, AuthCookie.getAccessToken(ENVIRONMENT))
				}}
			/>
		</div>
	)
}
