import { APIRequest } from '@lynx/client-core/src/api'
import { AddCircleOutlineIcon, AutocompleteInput, Badge, BasicButton, EyeOffIcon, Input, MoneyIcon, OpenInNewIcon } from '@lynx/client-core/src/components'
import { useEffect, useState } from 'react'
import css from './OrganisationSubscriptionPrice.module.scss'
import { Price, PricingSearch } from '@lynx/core/src/interfaces'
import { OrganisationSubscriptionNewPrice } from 'pages/Admin/routes/elmstone/Subscriptions/CreateSubscription/CreateViewSubscriptionPrice/OrganisationSubscriptionNewPrice'
import { OrganisationSubscriptionViewPrice } from './OrganisationSubscriptionViewPrice'
import { getStripeDashboardPath } from '@lynx/core'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'

interface Props {
	onPriceSelected: (price: Price | null) => void
	onUserLicensesQuantitySelected: (quantity: number) => void
	type: 'organisation' | 'user'
}

export const OrganisationSubscriptionPrice = ({ onPriceSelected, onUserLicensesQuantitySelected, type }: Props): React.ReactElement => {
	const [prices, setPricingData] = useState<Price[]>([])
	const [userLicensesQuantity, setUserLicensesQuantity] = useState<number>(0)
	const [productData, setProductData] = useState<PricingSearch['productData'] | null>(null)
	const [isLoading, setIsLoading] = useState(false)
	const [selectedPrice, setSelectedPrice] = useState<Price | null>(null)
	const [isPriceDetailsModalVisible, setIsPriceDetailsModalVisible] = useState(false)
	const [isPriceViewVisible, setIsPriceViewVisible] = useState(false)
	const { config } = useSelector((state: ApplicationState) => state)

	const priceSelectedHandler = (item: Price | null): void => {
		setSelectedPrice(item)
		onPriceSelected(item)
		setUserLicensesQuantity(item?.type === 'tiered' ? 5 : 0)
	}

	useEffect(() => {
		onUserLicensesQuantitySelected(userLicensesQuantity)
	}, [userLicensesQuantity])

	const searchPrice = async (): Promise<PricingSearch | void> => {
		setIsLoading(true)
		try {
			const data = await APIRequest.Billing.searchStripePrice(type)
			setPricingData(data.prices)
			setProductData(data.productData)
			return data
		} catch (e) {
			setPricingData([])
		} finally {
			setIsLoading(false)
		}
	}

	const itemSearchLabel = (item: Price): string => {
		const getRecurringIntervalText = (interval: Price['recurringInterval']): string => (interval ? ` / ${interval}` : '')
		if (item.type === 'per_unit') {
			const unitAmount = item.currencyOptions.find(({ amountCurrencyCode }) => amountCurrencyCode === item.currency)?.unitAmount || 0
			const flatPriceFormatted = new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: item.currency.toUpperCase()
			}).format(unitAmount)
			const value = `${flatPriceFormatted} ${getRecurringIntervalText(item.recurringInterval)}`
			return value
		} else {
			const gbpPricing = item.tiersData.find((tier) => tier.tierCurrencyCode === 'gbp')
			const tier = gbpPricing || item.tiersData[0]
			const unitPriceFormatted = new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: tier.tierCurrencyCode.toUpperCase()
			}).format(tier.tiers[0].unitAmount)

			const flatPriceFormatted = new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: tier.tierCurrencyCode.toUpperCase()
			}).format(tier.tiers[0].flatAmount)

			const text = `Starts at ${unitPriceFormatted} ${tier.tierCurrencyCode.toUpperCase()} per unit + ${flatPriceFormatted} ${getRecurringIntervalText(
				item.recurringInterval
			)}`
			return text
		}
	}

	const getCustomItemLabel = (item: Price): React.ReactElement => {
		return (
			<div className={css.priceItem}>
				{itemSearchLabel(item)}{' '}
				{item.metadata?.isReseller && (
					<Badge variant="teal" size="xs">
						RESELLER
					</Badge>
				)}
			</div>
		)
	}

	const onPriceCreated = async (newPriceId: string, updatedPrices: PricingSearch['prices']): Promise<void> => {
		setPricingData(updatedPrices)
		const newPriceData = updatedPrices.find((price) => price.id === newPriceId)
		if (newPriceData) priceSelectedHandler(newPriceData)
		setIsPriceDetailsModalVisible(false)
	}

	useEffect(() => {
		searchPrice()
	}, [])

	const productUrl = `${getStripeDashboardPath(config.ENVIRONMENT === 'production')}/products/${productData?.id}`

	return (
		<div className={css.container}>
			{productData && (
				<h4 className={css.product}>
					Product:
					<a href={productUrl} className={css.productName} target="_blank" rel="noreferrer">
						{productData.name} <OpenInNewIcon />
					</a>
				</h4>
			)}
			<div className={css.priceSelectionContainer}>
				<AutocompleteInput
					getCustomItemLabel={getCustomItemLabel}
					selectedItem={selectedPrice ? { ...selectedPrice, label: itemSearchLabel(selectedPrice) } : selectedPrice}
					className={css.autocomplete}
					inputLabel="Price"
					items={prices.map((price) => ({ ...price, label: itemSearchLabel(price) }))}
					isLoading={isLoading}
					keyPath="label"
					onItemSelected={(item: Price | null): void => priceSelectedHandler(item)}
				/>
				<div className={css.btnContainer}>
					<BasicButton onClick={(): void => setIsPriceViewVisible(true)} disabled={!selectedPrice}>
						View Price
					</BasicButton>
					<BasicButton icon={AddCircleOutlineIcon} onClick={(): void => setIsPriceDetailsModalVisible(true)}>
						New Price
					</BasicButton>
				</div>
			</div>
			{selectedPrice && type === 'organisation' && (
				<div>
					<Input
						disabled={selectedPrice.type === 'per_unit'}
						type="number"
						messages={
							selectedPrice?.type === 'per_unit'
								? [{ message: 'This value won`t be coming from stripe and it has to be configured in Organisation List', type: 'info' }]
								: []
						}
						label="User Licenses Quantity"
						value={userLicensesQuantity}
						onChange={(e): void => setUserLicensesQuantity(Number(e.target.value))}
					/>
				</div>
			)}
			{isPriceViewVisible && selectedPrice && (
				<OrganisationSubscriptionViewPrice priceData={selectedPrice} onClose={(): void => setIsPriceViewVisible(false)} />
			)}
			{isPriceDetailsModalVisible && (
				<OrganisationSubscriptionNewPrice
					onClose={(): void => setIsPriceDetailsModalVisible(false)}
					onPriceCreated={onPriceCreated}
					productType={type}
				/>
			)}
		</div>
	)
}
