import {
	CheckCircleFilledIcon,
	CloudIcon,
	Document,
	FeatureSection,
	FooterItem,
	FooterLinksItem,
	HelpBubbleIcon,
	LynxActivitiesLogo,
	LynxWhiteboardLogo
} from '@lynx/client-core/src/components'
import { useBillingPlans, usePersistedState } from '@lynx/client-core/src/hooks'
import { PlanFreeIcon, PlanOrgIcon, PlanProIcon } from 'components/PlanIcons/PlanIcons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showModal } from 'store/modal'
import css from './Pricing.module.scss'
import { CurrencyDropdown } from './components'
import { Plans } from './components/Plans/Plans'
import { AuthenticationProvidersHelpModal, OnlineConversionsHelpModal } from './modals'
import { authenticationProviderIcons } from './data'

export const Pricing = (): React.ReactElement | null => {
	const { billingPlans } = useBillingPlans()
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [selectedCurrencyCode, setSelectedCurrencyCode] = usePersistedState('currency', 'usd')

	const handleCurrencySelected = async (code: string): Promise<void> => {
		setSelectedCurrencyCode(code)
	}

	if (!billingPlans) {
		return null
	}

	const handleOnlineConversionsHelpClicked = (): void => {
		dispatch(showModal({ name: 'OnlineConversionsHelpModal' }))
	}

	const handleAuthenticationProvidersHelpClicked = (): void => {
		dispatch(showModal({ name: 'AuthenticationProvidersHelpModal' }))
	}

	return (
		<>
			<Document title={'Pricing'} stableScrollBarGutter={true}>
				<div className={[css['containerPricing'], ''].join(' ')}>
					<div className={css['pageTitleRow']}>
						<h1 className={css['pageTitle']}>{t('pages.pricing.plans')}</h1>
						<div className={css['currencies']}>
							<CurrencyDropdown onItemClicked={handleCurrencySelected} currencyCode={selectedCurrencyCode} />
						</div>
					</div>

					<Plans currencyCode={selectedCurrencyCode} />
				</div>

				<div className={css['pageLightContent']}>
					<h1 className={css['pageTitle']}>{t('pages.pricing.sectionPlanComparison')}</h1>
					<table className={css['pricingTable']}>
						<thead>
							<tr>
								<th></th>
								<th>
									<PlanFreeIcon />
								</th>
								<th>
									<PlanProIcon />
								</th>
								<th>
									<PlanOrgIcon />
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colSpan={4}>
									<div className={css['tdProduct']}>
										<LynxWhiteboardLogo />
										LYNX Whiteboard
									</div>
								</td>
							</tr>
							<tr>
								<td>{t('pages.pricing.table.feature1')}</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillGrey']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>
							<tr>
								<td>{t('pages.pricing.table.feature2')}</td>
								<td className={css['tdCenter']}></td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>
							<tr>
								<td>{t('pages.pricing.table.aiImageCreation')}</td>
								<td className={css['tdCenter']}></td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>
							<tr>
								<td>{t('pages.pricing.table.aiImageUpscaling')}</td>
								<td className={css['tdCenter']}></td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>
							<tr>
								<td>
									{t('pages.pricing.table.feature3')} <span>({t('pages.pricing.table.providers')})</span>
								</td>
								<td className={css['tdBoldCenter']}>
									10 <span>{t('pages.pricing.table.providers')}</span>
								</td>
								<td className={css['tdBoldCenter']}>
									10 <span>{t('pages.pricing.table.providers')}</span>
								</td>
								<td className={css['tdBoldCenter']}>
									10 <span>{t('pages.pricing.table.providers')}</span>
								</td>
							</tr>
							<tr>
								<td>{t('pages.pricing.table.feature4')}</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillGrey']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>

							<tr>
								<td>{t('pages.pricing.table.localConversions')}: SMART Notebook, Promethean and PDF Import</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillGrey']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>
							<tr>
								<td>
									{t('pages.pricing.table.onlineConversions')} <HelpBubbleIcon onClick={handleOnlineConversionsHelpClicked} />
								</td>
								<td className={css['tdCenter']}></td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>
							<tr>
								<td>
									{t('pages.pricing.table.authenticationProviders')} <HelpBubbleIcon onClick={handleAuthenticationProvidersHelpClicked} />
								</td>
								<td className={css['tdCenter']} colSpan={3}>
									<div className={css['icon-layout']}>
										{authenticationProviderIcons.map((a) => (
											<div key={a.name}>{a.icon}</div>
										))}
									</div>
								</td>
							</tr>
							<tr>
								<td>{t('pages.pricing.table.prioritySupport')}</td>
								<td className={css['tdCenter']}></td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>
							<tr>
								<td colSpan={4}>
									<div className={css['tdProduct']}>
										<LynxActivitiesLogo />
										LYNX Activities
									</div>
								</td>
							</tr>
							<tr>
								<td>{t('pages.pricing.table.feature5')}</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillGrey']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>
							<tr>
								<td>{t('pages.pricing.table.feature6')}</td>
								<td className={css['tdCenter']}></td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>
							<tr>
								<td colSpan={4}>
									<div className={css['tdProduct']}>
										<CloudIcon />
										LYNX Cloud
									</div>
								</td>
							</tr>
							<tr>
								<td>{t('pages.pricing.table.feature7')}</td>
								<td className={css['tdBoldCenter']}>{billingPlans['free'].storageLimitGB} GB</td>
								<td className={css['tdBoldCenter']}>{billingPlans['pro'].storageLimitGB} GB</td>
								<td className={css['tdBoldCenter']}>
									{billingPlans['organisation'].storageLimitGB} GB {t('pages.pricing.org.organisationDrive')}
								</td>
							</tr>
							<tr>
								<td>{t('pages.pricing.table.feature8')}</td>
								<td className={css['tdCenter']}></td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillPro']} />
								</td>
								<td className={css['tdCenter']}>
									<CheckCircleFilledIcon className={css['svgFillBlue']} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<FeatureSection backgroundColor="#F6F6F6" height="auto">
					<FooterItem />
					<FooterLinksItem />
				</FeatureSection>
			</Document>
			<OnlineConversionsHelpModal />
			<AuthenticationProvidersHelpModal />
		</>
	)
}
