import React from 'react'
import { NavLink } from 'react-router-dom'
import css from './NavigationItem.module.scss'
import { PermissionsKeys } from '@lynx/core/src/interfaces'

export interface NavigationItemProps {
	to: string
	children: React.ReactNode
	accentColor: string
	visible?: boolean
	permissions?: PermissionsKeys | PermissionsKeys[]
}

export const NavigationItem = ({ accentColor = '#cccccc', children, to, visible = true }: NavigationItemProps): React.ReactElement | null => {
	const classNames = [css['container']]
	!visible && classNames.push(css['container--hidden'])

	return (
		<NavLink
			className={({ isActive }): string => {
				isActive && classNames.push(css['container--active'])
				return classNames.join(' ')
			}}
			to={to}
		>
			<div>{children}</div>
			<div className={css['container__bar']} style={{ borderBottomColor: accentColor }} />
		</NavLink>
	)
}
