import React from 'react'
import css from './OrganisationInfo.module.scss'
import { OrganisationAvatar } from '../OrganisationAvatar'
import { MapPinIcon, ProBadgeIcon } from '../Icons'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

interface Props {
	organisationId: number
	organisationName: string
	organisationCountry: string
	isPro?: boolean
}

export const OrganisationInfo = ({ organisationId, organisationName, organisationCountry, isPro }: Props): React.ReactElement => {
	const {
		config: { API_SERVER, LYNXCLOUD_CLIENT }
	} = useSelector((state: ApplicationState) => state)
	const orgAvatarUrl = `${API_SERVER}/object-store/organisation-avatar?organisationId=${organisationId}`
	const orgUrl = `${LYNXCLOUD_CLIENT}/organisations/${organisationId}`
	return (
		<div className={css.container}>
			<a href={orgUrl}>
				<div className={css.orgContainer}>
					<div className={css.orgPic}>
						<OrganisationAvatar avatarUrl={orgAvatarUrl} />
						{isPro && <ProBadgeIcon className={css.proIcon} />}
					</div>
					<div>
						<div className={css.name}>{organisationName}</div>
						<div className={css.address}>
							<MapPinIcon /> {organisationCountry}
						</div>
					</div>
				</div>
			</a>
		</div>
	)
}
