import { APIRequest } from '@lynx/client-core/src/api'
import { Alert, BasicButton, Checkbox } from '@lynx/client-core/src/components'
import ModalBase from '@lynx/client-core/src/components/Modal/Modal'
import { extractResponseError } from '@lynx/core'
import { AccountProfile, CustomerSearch, Price } from '@lynx/core/src/interfaces'
import { SearchOrganisation } from '@lynx/core/src/interfaces/Organisation'
import { STRIPE_ERROR } from '@lynx/core/src/interfaces/ServerErrors'
import {
	OrganisationSubscriptionPrice,
	OrganisationSearch,
	SubscriptionCustomer,
	SubscriptionTrialPeriod,
	UserSearch
} from 'pages/Admin/routes/elmstone/Subscriptions/CreateSubscription'
import { CurrencyDropdown } from 'pages/Pricing/components'
import { useEffect, useState } from 'react'
import css from './NewSubscriptionModal.module.scss'

interface Props {
	closeModal: () => void
	type: 'organisation' | 'user'
}

export const NewSubscriptionModal = ({ closeModal, type = 'organisation' }: Props): React.ReactElement => {
	const [selectedCustomer, setSelectedCustomer] = useState<CustomerSearch | null>(null)
	const [selectedOrganisation, setSelectedOrganisation] = useState<SearchOrganisation | null>(null)
	const [selectedUser, setSelectedUser] = useState<AccountProfile | null>(null)
	const [selectedPrice, setSelectedPrice] = useState<Price | null>(null)
	const [userLicensesQuantity, setUserLicensesQuantity] = useState<number>(0)
	const [hadTrialBefore, setHadTrialBefore] = useState<boolean | null>(null)
	const [isSendInvoice, setIsSendInvoice] = useState<boolean>(false)
	const [currencyCode, setCurrencyCode] = useState('gbp')
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState(false)
	const [newSubscriptionId, setNewSubscriptionId] = useState('')
	const targetSelect = type === 'organisation' ? selectedOrganisation : selectedUser

	const onSubscriptionSubmit = async (): Promise<void> => {
		if (!selectedCustomer || !targetSelect || !selectedPrice) return

		setIsLoading(true)
		setErrorMessage(null)

		try {
			const defaultPayload = {
				customerId: selectedCustomer.id,
				priceId: selectedPrice.id,
				isSendInvoice,
				currency: currencyCode,
				coupon: null
			}
			let res: null | Awaited<
				ReturnType<typeof APIRequest.Billing.createUserSubscription> | ReturnType<typeof APIRequest.Billing.createOrganisationSubscription>
			> = null
			switch (type) {
				case 'user': {
					if (!selectedUser) throw new Error('User not selected')
					res = await APIRequest.Billing.createUserSubscription({
						userId: selectedUser.userId,
						...defaultPayload
					})
					break
				}
				case 'organisation': {
					if (!selectedOrganisation) throw new Error('Organisation not selected')
					res = await APIRequest.Billing.createOrganisationSubscription({
						organisationId: selectedOrganisation.id,
						userLicensesQuantity: selectedPrice?.type === 'per_unit' ? 1 : userLicensesQuantity,
						...defaultPayload
					})
					break
				}
			}

			setNewSubscriptionId(res.subscriptionId)
		} catch (e: any) {
			const responseError = extractResponseError(e)
			const isDriveSizeExceededError = responseError?.name === STRIPE_ERROR
			if (isDriveSizeExceededError) setErrorMessage(responseError.options.stripeErrorMessage)
			else setErrorMessage(e?.message)
		} finally {
			setIsLoading(false)
		}
	}

	const onCustomerSelected = (customer: CustomerSearch | null): void => {
		setSelectedCustomer(customer)
	}

	const populateCustomer = async (customerId: string): Promise<void> => {
		try {
			const data = await APIRequest.Billing.getStripeCustomer(customerId)
			if (data) setSelectedCustomer(data)
		} catch (e) {
			// do nothing
		}
	}

	useEffect(() => {
		const customerId = selectedOrganisation?.customerId || selectedUser?.customerId
		if (customerId) populateCustomer(customerId)
	}, [selectedOrganisation || selectedUser])

	const alreadySubscriptionTextPrefix = type === 'organisation' ? `Organisation (ID: ${selectedOrganisation?.id})` : 'User'

	const getRedirectUrl = (): string => {
		const base = '/admin/elmstone/subscriptions?hideList=true'
		if (selectedUser) return `${base}&tableSearch=${selectedUser.email}&`
		if (selectedOrganisation) return `${base}&tableSearch=${selectedOrganisation.id}&tab=orgs`
		return base
	}
	return (
		<ModalBase name="New Subscription" title="New Subscription" uncontrolled onHidden={closeModal} innerContainerClasses={css.modalInnerContainer}>
			<div className={css.container}>
				{type === 'organisation' ? (
					<OrganisationSearch onOrganisationSelected={setSelectedOrganisation} />
				) : (
					<UserSearch onUserSelected={setSelectedUser} />
				)}
				<SubscriptionCustomer onCustomerSelected={onCustomerSelected} selectedCustomer={selectedCustomer} />
				<OrganisationSubscriptionPrice onPriceSelected={setSelectedPrice} onUserLicensesQuantitySelected={setUserLicensesQuantity} type={type} />
				<div>
					<div className={css.currencyLabel}>Currency</div>
					<CurrencyDropdown currencyCode={currencyCode} onItemClicked={(code): void => setCurrencyCode(code)} />
				</div>

				{type === 'user' && (
					<SubscriptionTrialPeriod
						hadTrialBefore={hadTrialBefore}
						selectedCustomer={selectedCustomer}
						selectedUser={selectedUser}
						setHadTrialBefore={setHadTrialBefore}
					/>
				)}
				<div style={{ visibility: hadTrialBefore ? 'visible' : 'hidden' }}>
					Send Invoice
					<Checkbox checked={isSendInvoice} onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setIsSendInvoice(e.target.checked)} />
				</div>
				<div className={css.controlsContainer}>
					{Boolean(targetSelect?.isPro && targetSelect.subscriptionData) && (
						<Alert styleClass="warning" className={css.alertEl}>
							{alreadySubscriptionTextPrefix} already has active subscription, new subscription will replace the existing one
						</Alert>
					)}
					{errorMessage && (
						<Alert styleClass="danger" className={css.alertEl}>
							{errorMessage}
						</Alert>
					)}
					{newSubscriptionId && (
						<>
							<Alert styleClass="success" className={css.alertEl}>
								Subscription has been created
								<BasicButton size="xs" to={getRedirectUrl()} className={css.navBtn} isReloadDocument>
									Click to see
								</BasicButton>
							</Alert>
							<div className={css.waitNote}>(Please allow a few seconds to let stripe process and send webhooks triggers)</div>
						</>
					)}
					<div className={css.btnContainer}>
						<BasicButton onClick={closeModal}>Cancel</BasicButton>
						<BasicButton
							variant="blue"
							onClick={onSubscriptionSubmit}
							isLoading={isLoading}
							disabled={!(selectedCustomer && targetSelect && selectedPrice)}
						>
							Create
						</BasicButton>
					</div>
				</div>
			</div>
		</ModalBase>
	)
}
