import React, { useEffect } from 'react'
import { PermissionsKeys } from '@lynx/core/src/interfaces'
import { useHasUserPermissions } from '../../hooks/useHasUserPermissions'
import { OrganisationPermissionModalContextType, showModal } from '../../store/modal'
import { useDispatch } from 'react-redux'
import css from './WithPermissions.module.scss'

interface Props {
	targetPermissions: PermissionsKeys | PermissionsKeys[]
	children: React.ReactNode
	modalEnabled?: boolean
	overlay?: boolean
}

export const WithPermissions = ({ targetPermissions, children, modalEnabled, overlay }: Props): React.ReactElement | null => {
	const hasPermissions = useHasUserPermissions(targetPermissions)
	const dispatch = useDispatch()
	const firstPerm = Array.isArray(targetPermissions) ? targetPermissions[0] : targetPermissions
	const openPermissionBlockedModal = (): void => {
		dispatch(
			showModal({
				name: OrganisationPermissionModalContextType,
				context: {
					type: OrganisationPermissionModalContextType,
					permissionKey: firstPerm
				}
			})
		)
	}
	useEffect(() => {
		if (!hasPermissions && modalEnabled) {
			openPermissionBlockedModal()
		}
	}, [hasPermissions, modalEnabled])

	if (!hasPermissions && overlay)
		return (
			<div className={css.overlayContainer} onClick={openPermissionBlockedModal}>
				<div className={css.children}>{children}</div>
			</div>
		)
	if (!hasPermissions) return null
	return <>{children}</>
}
