import { allPermissionsData } from '../constants'
import { organisationPermissionDefaultValue } from '../constants'
import { organisationPermissionsData } from '../constants'
import { allPermissions } from '../constants'
import { AdminPermission, userPermissionsData } from '../constants'
import { PermissionsType } from '../interfaces'
import { OrganisationPermissionsKeys } from '../interfaces'
import { PermissionsKeys } from '../interfaces'
import { UserPermissionsKeys } from '../interfaces'
import { Entries } from '../typeUtils'

export const hasUserPermission = (targetPermissions: PermissionsKeys | PermissionsKeys[], userPermissions: PermissionsType): boolean => {
	const isAdmin = userPermissions[AdminPermission].isGranted
	if (isAdmin || !targetPermissions.length) return true
	const targetPermissionsArray = Array.isArray(targetPermissions) ? targetPermissions : [targetPermissions]
	return targetPermissionsArray.some((permission) => userPermissions[permission].isGranted)
}

const permissionIdToPermissionKey = (permissionId: number): PermissionsKeys => {
	const permission = (Object.entries(allPermissionsData) as Entries<typeof allPermissionsData>).find(
		([, permissionData]) => permissionData.permissionId === permissionId
	)
	if (!permission) throw new Error(`PermissionId ${permissionId} not found`)
	const [permissionKey] = permission
	if (!(permissionKey in allPermissionsData)) throw new Error(`Permission ${permissionKey} not found`)
	return permissionKey
}

export const userPermissionKeyToPermissionId = (permissionKey: UserPermissionsKeys): number => {
	const permissionData = userPermissionsData[permissionKey]
	if (!permissionData) throw new Error(`User PermissionKey ${permissionKey} not found`)
	return permissionData.permissionId
}

export const organisationPermissionKeyToPermissionId = (permissionKey: OrganisationPermissionsKeys): number => {
	const permissionData = organisationPermissionsData[permissionKey]
	if (!permissionData) throw new Error(`Organisation PermissionKey ${permissionKey} not found`)
	return permissionData.permissionId
}

export const getDefaultUserPermissions = (perms: PermissionsKeys[] = [...allPermissions]): PermissionsType =>
	perms.reduce((acc, permission) => ({ ...acc, [permission]: allPermissionsData[permission].defaultValue }), {} as PermissionsType)

export const mapPermissionsToStructuredObject = (
	perms: PermissionsKeys[],
	permissions: { permissionTypeId: number; isGranted: boolean; organisationId?: number; organisationName?: string; organisationCountry?: string }[],
	onError: (error: unknown) => void
): PermissionsType => {
	const defaultPermissionsClone = getDefaultUserPermissions(perms)
	const defaultPermissions = getDefaultUserPermissions(perms)
	permissions.forEach(({ permissionTypeId, isGranted, organisationId, organisationName, organisationCountry }) => {
		try {
			const key = permissionIdToPermissionKey(permissionTypeId)
			const defaultIsGranted = defaultPermissionsClone[key].isGranted

			if (isGranted !== defaultIsGranted) {
				const value: typeof organisationPermissionDefaultValue = {
					isGranted
				}
				if (organisationId && organisationName) {
					value.organisationId = organisationId
					value.organisationName = organisationName
					value.organisationCountry = organisationCountry
				}
				defaultPermissions[key] = value
			}
		} catch (e) {
			onError(e)
		}
	})
	return defaultPermissions
}
