import { LoadingSpinner, SendIcon } from '@lynx/client-core/src/components'
import { AccountProfile } from '@lynx/core/src/interfaces'
import { SearchOrganisation } from '@lynx/core/src/interfaces/Organisation'
import css from './SendInvoiceIcon.module.scss'

interface Props {
	hideText?: boolean
	item: SearchOrganisation | AccountProfile
	sendingInvoiceId: string | number | null
	onSendInvoiceClicked: () => Promise<void>
}

export const SendInvoiceIcon = ({ hideText, item, sendingInvoiceId, onSendInvoiceClicked }: Props): React.ReactElement => {
	const id = 'id' in item ? item.id : item.userId

	const icon =
		sendingInvoiceId === id ? (
			<LoadingSpinner />
		) : (
			<SendIcon className={css.sendInvoiceIcon} title="Send invoice" onClick={(): Promise<void> => onSendInvoiceClicked()} />
		)

	return (
		<>
			{icon}
			{!hideText && <div onClick={(): Promise<void> => onSendInvoiceClicked()}>Send Invoice</div>}
		</>
	)
}
