import React, { useEffect, useState } from 'react'
import { Input, InputProps } from '../Input/Input'
import css from './AutocompleteInput.module.scss'
import { HighlightedText } from '../HighlightedText'
import { ChevronLeftIcon, CloseIcon } from '../Icons'
import { useDebounce, useOutsideClick, usePrevious } from '../../hooks'
import { LoadingSpinner } from '../LoadingSpinner'
import { DefaultInputProps } from '../Input/Input.types'

interface AutocompleteInputProps<T> {
	items: T[]
	selectedItem?: T | null
	onItemSelected: (item: T | null) => void
	onInputValueChange?: (value: string) => void
	keyPath: string
	isLoading?: boolean
	fetchItems?: (text: string) => void
	filterFn?: (item: T, inputValue: string) => boolean
	inputMessages?: (text: string) => InputProps['messages']
	inputLabel?: string
	inputPlaceholder?: string
	inputIcon?: DefaultInputProps['icon']
	className?: string
	getCustomItemLabel?: (item: T) => React.ReactElement
}
const getDeepValue = (obj: any, path: string): string => {
	const pathArray = path.split('.')
	return pathArray.reduce((acc, key) => {
		if (acc && key in acc) {
			return acc[key]
		}
		return ''
	}, obj)
}
export const AutocompleteInput = <T,>({
	keyPath,
	items,
	onItemSelected,
	selectedItem,
	isLoading,
	onInputValueChange,
	fetchItems,
	filterFn,
	inputMessages,
	inputLabel,
	inputIcon,
	inputPlaceholder,
	className,
	getCustomItemLabel
}: AutocompleteInputProps<T>): React.ReactElement => {
	const [inputValue, setInputValue] = useState('')
	const [selectedItemInternal, setSelectedItemInternal] = useState<T | null>(null)
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const inputRef = React.useRef<HTMLInputElement>(null)
	const componentRef = React.useRef<HTMLDivElement>(null)
	const previousValue = usePrevious(inputValue)
	const [inputPosition, setInputPosition] = useState({ offsetTop: 0, height: 0 })

	useOutsideClick(componentRef, () => {
		setIsDropdownOpen(false)
	})

	const fetchItemsInternal = useDebounce((searchValue: string) => {
		fetchItems?.(searchValue)
	}, 300)

	useEffect(() => {
		if (selectedItem) onItemSelectedInternalFromParent(selectedItem)
		if (selectedItem === null) onClearValue()
	}, [selectedItem])

	useEffect(() => {
		setSelectedItemInternal(selectedItemInternal)
	}, [selectedItemInternal])

	useEffect(() => {
		if (!inputValue && previousValue) {
			setSelectedItemInternal(null)
			setIsDropdownOpen(true)
		}
		onInputValueChange?.(inputValue)
	}, [inputValue])

	useEffect(() => {
		if (!selectedItemInternal) fetchItemsInternal(inputValue)
	}, [inputValue, selectedItemInternal])

	const onItemSelectedInternalFromParent = (item: T): void => {
		setSelectedItemInternal(item)
		setInputValue(getDeepValue(item, keyPath))
		setIsDropdownOpen(false)
	}

	const onItemSelectedInternal = (item: T): void => {
		setSelectedItemInternal(item)
		onItemSelected(item)
		setInputValue(getDeepValue(item, keyPath))
		setIsDropdownOpen(false)
	}

	const onClearValue = (): void => {
		setInputValue('')
		setSelectedItemInternal(null)
		onItemSelected(null)
	}

	const internalFilterFn = (item: T): boolean => {
		// override it option
		const value = getDeepValue(item, keyPath)
		// string only for now
		return String(value).toLowerCase().includes(inputValue.toLowerCase())
	}

	useEffect(() => {
		setInputPosition({ offsetTop: inputRef.current?.offsetTop || 0, height: inputRef.current?.offsetHeight || 0 })
	}, [])

	const filterFunction = filterFn ? (item: T): boolean => filterFn(item, inputValue) : internalFilterFn
	const filteredItems = fetchItems ? items : items.filter(filterFunction)

	const isDropdownDisabled = !filteredItems.length

	const suffixComponent = (
		<div className={css.suffixComponentContainer}>
			{isLoading && <LoadingSpinner size="sm" />}
			{!isLoading && selectedItemInternal ? (
				<CloseIcon className={css.suffixIcon} onClick={onClearValue} />
			) : (
				<ChevronLeftIcon
					onClick={(): void | boolean => !isDropdownDisabled && setIsDropdownOpen(!isDropdownOpen)}
					className={[css.suffixIcon, isDropdownDisabled && css.suffixIconDisabled].join(' ')}
					style={{ transform: `rotate(${isDropdownOpen && filteredItems.length ? 90 : -90}deg)` }}
				/>
			)}
		</div>
	)

	const onInputFocus = (): void => {
		setIsDropdownOpen(!isDropdownOpen)
	}

	return (
		<div className={[css.container, className, inputLabel ? css.label : ''].join(' ')} ref={componentRef}>
			{getCustomItemLabel && selectedItem && (
				<div
					className={`${css.customItemLabelContainer} ${inputIcon && css.icon}`}
					style={{ top: inputPosition.offsetTop, height: inputPosition.height }}
				>
					{getCustomItemLabel(selectedItem)}
				</div>
			)}
			<Input
				placeholder={inputPlaceholder}
				ref={inputRef}
				value={inputValue}
				icon={inputIcon}
				onFocus={onInputFocus}
				onChange={(e): void | boolean => !selectedItemInternal && setInputValue(e.target.value)}
				suffixComponent={suffixComponent}
				messages={inputMessages ? inputMessages(inputValue) : []}
				label={inputLabel}
			/>
			{isDropdownOpen && !isDropdownDisabled && (
				<div className={css.dropdownContainer}>
					{filteredItems.map((item, i) => (
						<div key={'autocomplete' + i + keyPath} className={css.dropdownItem} onClick={(): void => onItemSelectedInternal(item)} tabIndex={0}>
							{getCustomItemLabel ? getCustomItemLabel(item) : <HighlightedText text={getDeepValue(item, keyPath)} highlight={inputValue} />}
						</div>
					))}
				</div>
			)}
		</div>
	)
}
