import { Document } from '@lynx/client-core/src/components'
import { useThunkDispatch } from 'hooks'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { loadOrganisations, setActiveOrganisation } from 'store/organisations'
import { Body } from './Body'
import { Landing } from './components'
import { useCurrentOrganisation } from './hooks'
import {
	BulkImportPeopleModal,
	ConfirmAccountCreationModal,
	CreateGroupModal,
	DeleteGroupModal,
	InvitePeopleModal,
	InviteSentModal,
	ManageDomainsModal,
	ManageGroupModal,
	NextcloudConfigurationModal,
	OAUTHConfigurationModal,
	OrganisationCreateModal,
	OrganisationCreatedModal,
	OrganisationDeleteDriveModal,
	OrganisationDeleteModal,
	OrganisationDepartedModal,
	OrganisationEditModal,
	SAMLConfigurationModal,
	UserDeleteModal,
	VerifyDomainModal,
	RenameGroupModal,
	ResetOrganisationUserPasswordModal,
	EntraIdConfigurationModal,
	UserDisableModal,
	UserEnableModal
} from './modals'
import { useParams } from 'react-router-dom'
import { useNavigatePreserve } from '@lynx/client-core/src/hooks'
import { UserLeaveOrganisationModal } from './modals/UserLeaveOrganisationModal/UserLeaveOrganisationModal'

export const Organisations = (): React.ReactElement | null => {
	const dispatchThunk = useThunkDispatch()
	const { organisationId } = useParams()
	const navigate = useNavigatePreserve()

	const organisation = useCurrentOrganisation()
	const { isLoaded, activeOrganisationId } = useSelector((state: ApplicationState) => state.organisations)

	useEffect(() => {
		activeOrganisationId && navigate(`/organisations/${activeOrganisationId}`)
	}, [activeOrganisationId])

	useEffect(() => {
		const mount = async (): Promise<void> => {
			await dispatchThunk(loadOrganisations(Number(organisationId)))
		}
		mount()
	}, [])

	if (!isLoaded) return null

	const hasOrganisation = Boolean(organisation)

	return (
		<Document title="Organisations" description="Organisations" keywords="Organisations" auth={true}>
			{hasOrganisation ? <Body /> : <Landing />}
			<BulkImportPeopleModal />
			<OrganisationDepartedModal />
			<OrganisationEditModal />
			<OrganisationCreateModal />
			<OrganisationCreatedModal />
			<OrganisationDeleteModal />
			<OrganisationDeleteDriveModal />
			<CreateGroupModal />
			<DeleteGroupModal />
			<ManageDomainsModal />
			<InvitePeopleModal />
			<UserDeleteModal />
			<InviteSentModal />
			<ManageGroupModal />
			<VerifyDomainModal />
			<ConfirmAccountCreationModal />
			<SAMLConfigurationModal />
			<NextcloudConfigurationModal />
			<OAUTHConfigurationModal />
			<RenameGroupModal />
			<ResetOrganisationUserPasswordModal />
			<EntraIdConfigurationModal />
			<UserDisableModal />
			<UserEnableModal />
			<UserLeaveOrganisationModal />
		</Document>
	)
}
