import i18next from 'i18next'
import React from 'react'
import ReactToPrint from 'react-to-print'
import { BasicButton, Dropdown, SearchContainer } from '..'
import { DownloadIcon, MoreVertIcon, PrintIcon, RefreshIcon } from '../Icons'
import css from './TableToolbar.module.scss'
import { TableToolbarProps } from './interfaces'

export const TableToolbar = ({
	setTableSearchValue,
	tableSearchValue,
	onDownloadCsv,
	tableRef,
	toolBarItems = [],
	showSearch,
	isSearchVisible,
	onRefetch
}: TableToolbarProps): React.ReactElement => {
	const [isLoading, setIsLoading] = React.useState(false)
	const dropdownItems = [
		{
			component: (
				<div onClick={onDownloadCsv}>
					<DownloadIcon />
					<div>{i18next.t('components.table.downloadCsv')}</div>
				</div>
			)
		},
		{
			component: (
				<ReactToPrint
					trigger={(): React.ReactElement => (
						<div>
							<PrintIcon />
							<div>{i18next.t('components.table.print')}</div>
						</div>
					)}
					content={(): HTMLDivElement | null => tableRef && tableRef.current}
				/>
			)
		},
		...toolBarItems
	]

	return (
		<div className={css.toolbarContainer}>
			{showSearch && (
				<SearchContainer
					handleSearchChange={(value: string): void => {
						setTableSearchValue(value)
					}}
					isSearchVisible={isSearchVisible}
					searchText={tableSearchValue}
				/>
			)}
			{onRefetch && (
				<BasicButton
					isLoading={isLoading}
					onClick={async (): Promise<void> => {
						setIsLoading(true)
						await onRefetch()
						setIsLoading(false)
					}}
					title="Refresh"
					icon={RefreshIcon}
					className={css.refreshBtn}
				></BasicButton>
			)}
			<Dropdown title={<MoreVertIcon className={css.dropdownIcon} />} className={css['dropdown']}>
				{dropdownItems.map(({ component }, i) => (
					<li key={`${i}dropdownItem`}>{component}</li>
				))}
			</Dropdown>
		</div>
	)
}
