import { TypeOf } from '../typeUtils'
import { DriveSize } from './ObjectStore'
import { SubmitTranslationInterface } from './Translation'
import { PermissionsType } from './UserPermissions'

export const notificationPrefix = 'notification:'
export const aiPrefix = 'ai:'
export const logoutEverywhere = `${notificationPrefix}logoutEverywhere` as const
export const conversionCompleted = `${notificationPrefix}conversionCompleted` as const
export const fileUploaded = `file:uploaded` as const // this has to be without prefix to support legacy versions
export const aiImageGenerationProgress = `${aiPrefix}image-generation-progress` as const
export const aiImageGenerationFailed = `${aiPrefix}image-generation-failed` as const

export const aiTextGenerationFailed = `${aiPrefix}text-generation-failed` as const
export const aiTextGenerationProgress = `${aiPrefix}text-generation-progress` as const

export const organisationInviteAccepted = `${notificationPrefix}organisationInviteAccepted` as const
export const inviteEmailSendAction = `${notificationPrefix}inviteEmailSendAction` as const
export const emailSentTo = `${notificationPrefix}emailSentTo` as const
export const couldNotSendEmail = `${notificationPrefix}couldNotSendEmail` as const
export const somethingWentWrong = `${notificationPrefix}somethingWentWrong` as const
export const organisationHasBeenApplied = `${notificationPrefix}organisationHasBeenApplied` as const
export const organisationNameNotFound = `${notificationPrefix}organisationNameNotFound` as const
export const linkCloudDrives = `${notificationPrefix}linkCloudDrives` as const
export const subscribeNewsletter = `${notificationPrefix}subscribeNewsletter` as const
export const fileDeleted = `${notificationPrefix}fileDeleted` as const
export const fileShared = `${notificationPrefix}fileShared` as const
export const collectionShared = `${notificationPrefix}collectionShared` as const
export const organisationInviteSent = `${notificationPrefix}organisationInviteSent` as const
export const translationRequestUpdate = `${notificationPrefix}translationRequestUpdate` as const
export const recentFilesUpdated = `${notificationPrefix}recentFilesUpdated` as const
export const updateProfilePicture = `${notificationPrefix}updateProfilePicture` as const
export const enableOneDriveSharePoints = `${notificationPrefix}enableOneDriveSharepoints` as const
export const driveStorageLimitExceeded = `${notificationPrefix}driveStorageLimitExceeded` as const
export const driveStorageLimitNearlyFull = `${notificationPrefix}driveStorageLimitNearlyFull` as const
export const driveStorageLimitChanged = `driveSize:changed` as const
export const profileUpdated = `profile:updated` as const

export const softDeleteNotificationTypes = [updateProfilePicture, subscribeNewsletter, linkCloudDrives]

export const notificationsTypes = [
	conversionCompleted,
	fileUploaded,
	organisationInviteAccepted,
	organisationInviteSent,
	collectionShared,
	fileShared,
	fileDeleted,
	inviteEmailSendAction,
	emailSentTo,
	couldNotSendEmail,
	somethingWentWrong,
	organisationHasBeenApplied,
	organisationNameNotFound,
	linkCloudDrives,
	subscribeNewsletter,
	translationRequestUpdate,
	logoutEverywhere,
	recentFilesUpdated,
	updateProfilePicture,
	enableOneDriveSharePoints,
	driveStorageLimitExceeded,
	driveStorageLimitNearlyFull
]
interface LogoutEverywhereNotificationType<Payload> {
	type: typeof logoutEverywhere
	payload: Payload extends 'parsed' ? null : string
}

interface ConversionCompletedNotificationType<Payload> {
	type: typeof conversionCompleted
	payload: Payload extends 'parsed' ? { filename: string } : string
}

interface SubscribeNewsletterNotificationType<Payload> {
	type: typeof subscribeNewsletter
	payload: Payload extends 'parsed' ? { isAskMeLater: boolean; isNo: boolean; isYes: boolean } : string
}

interface LinkCloudDrivesNotificationType<Payload> {
	type: typeof linkCloudDrives
	payload: Payload extends 'parsed' ? null : string
}

interface FileUploadNotificationType<Payload> {
	type: typeof fileUploaded
	payload: Payload extends 'parsed' ? { filename: string } : string
}

interface FileDeleteNotificationType<Payload> {
	type: typeof fileDeleted
	payload: Payload extends 'parsed' ? { filename: string } : string
}

interface FileShareNotificationType<Payload> {
	type: typeof fileShared
	payload: Payload extends 'parsed' ? { filename: string } : string
}

interface CollectionShareNotificationType<Payload> {
	type: typeof collectionShared
	payload: Payload extends 'parsed' ? { collectionName: string } : string
}

interface OrganisationInviteAcceptedNotificationType<Payload> {
	type: typeof organisationInviteAccepted
	payload: Payload extends 'parsed' ? { title: string; body: string; organisationId: number; inviteId: number } : string
}

interface OrganisationInviteSentNotificationType<Payload> {
	type: typeof organisationInviteSent
	payload: Payload extends 'parsed' ? { title: string; body: string; organisationId: number; inviteId: number } : string
}

interface InviteEmailSendNotificationType<Payload> {
	type: typeof inviteEmailSendAction
	payload: Payload extends 'parsed' ? { email: string } : string
}

interface EmailSentNotificationType<Payload> {
	type: typeof emailSentTo
	payload: Payload extends 'parsed' ? { email: string } : string
}

interface CouldNotSendEmailNotificationType<Payload> {
	type: typeof couldNotSendEmail
	payload: Payload extends 'parsed' ? { email: string } : string
}

interface SomethingWentWrongNotificationType<Payload> {
	type: typeof somethingWentWrong
	payload: Payload extends 'parsed' ? null : string
}

interface OrganisationHasBeenAppliedNotificationType<Payload> {
	type: typeof organisationHasBeenApplied
	payload: Payload extends 'parsed' ? { organisationName: string } : string
}

interface OrganisationNameNotFoundNotificationType<Payload> {
	type: typeof organisationNameNotFound
	payload: Payload extends 'parsed' ? null : string
}
interface TranslationRequestUpdateNotificationType<Payload> {
	type: typeof translationRequestUpdate
	payload: Payload extends 'parsed' ? { requestedTranslationsData: SubmitTranslationInterface[]; status: 'pr-rejected' | 'pr-merged' } : string
}
interface RecentFilesUpdatedNotificationType<Payload> {
	type: typeof recentFilesUpdated
	payload: Payload extends 'parsed' ? null : string
}

interface UpdateProfilePictureNotificationType<Payload> {
	type: typeof updateProfilePicture
	payload: Payload extends 'parsed' ? null : string
}
interface EnableOneDriveSharePointsNotificationType<Payload> {
	type: typeof enableOneDriveSharePoints
	payload: Payload extends 'parsed' ? { oneDriveId: string } : string
}
interface DriveStorageLimitExceededNotificationType<Payload> {
	type: typeof driveStorageLimitExceeded
	payload: Payload extends 'parsed' ? { provider: 'lynxcloud' | 'organisation'; driveId?: string } : string
}

interface DriveStorageLimitNearlyFullNotificationType<Payload> {
	type: typeof driveStorageLimitNearlyFull
	payload: Payload extends 'parsed' ? { usedBytes: number; totalBytes: number; provider: 'lynxcloud' | 'organisation'; driveId?: string } : string
}

interface DriveStorageChangedNotificationType<Payload> {
	type: typeof driveStorageLimitChanged
	payload: Payload extends 'parsed' ? { driveSize: DriveSize } : string
}

interface ProfileUpdatedNotificationType<Payload> {
	type: typeof profileUpdated
	payload: Payload extends 'parsed' ? { isPro: boolean; permissions?: PermissionsType } : string
}

export interface AiImageGenerationProgressNotificationType<Payload> {
	type: typeof aiImageGenerationProgress
	payload: Payload extends 'parsed'
		? {
				id: string
				prompt: string
				images: { url: string; split: '2x2' | '1x1' }[]
				progress: number
				provider: 'DALLE-3' | 'Midjourney'
		  }
		: string
}

export interface AiImageGenerationFailedNotificationType<Payload> {
	type: typeof aiImageGenerationFailed
	payload: Payload extends 'parsed'
		? {
				id: string
				prompt: string
				errorMessage: string
		  }
		: string
}

export interface AiTextGenerationProgressNotificationType<Payload> {
	type: typeof aiTextGenerationProgress
	payload: Payload extends 'parsed'
		? {
				id: string
				progress: number
				chunk: string
				provider: 'ChatGPT'
		  }
		: string
}

export interface AiTextGenerationFailedNotificationType<Payload> {
	type: typeof aiTextGenerationFailed
	payload: Payload extends 'parsed'
		? {
				id: string
				errorMessage: string
		  }
		: string
}

export interface INotificationBase {
	id: number
	userId: string
	notifierId: string
	isViewed: boolean
	createdAt: string
	updatedAt: string
}

export type SubscribeNewsletterNotification<Payload = 'parsed'> = INotificationBase & SubscribeNewsletterNotificationType<Payload>
export type LinkCloudDrivesNotification<Payload = 'parsed'> = INotificationBase & LinkCloudDrivesNotificationType<Payload>

export type NotificationTypeOf<NT> = TypeOf<SubscribeNewsletterNotification | LinkCloudDrivesNotification, NT>

export type NotificationTypes<Payload = null> =
	| ConversionCompletedNotificationType<Payload>
	| OrganisationInviteAcceptedNotificationType<Payload>
	| OrganisationInviteSentNotificationType<Payload>
	| FileUploadNotificationType<Payload>
	| FileDeleteNotificationType<Payload>
	| FileShareNotificationType<Payload>
	| CollectionShareNotificationType<Payload>
	| EmailSentNotificationType<Payload>
	| CouldNotSendEmailNotificationType<Payload>
	| SomethingWentWrongNotificationType<Payload>
	| InviteEmailSendNotificationType<Payload>
	| OrganisationHasBeenAppliedNotificationType<Payload>
	| OrganisationNameNotFoundNotificationType<Payload>
	| LinkCloudDrivesNotificationType<Payload>
	| SubscribeNewsletterNotificationType<Payload>
	| TranslationRequestUpdateNotificationType<Payload>
	| LogoutEverywhereNotificationType<Payload>
	| RecentFilesUpdatedNotificationType<Payload>
	| UpdateProfilePictureNotificationType<Payload>
	| EnableOneDriveSharePointsNotificationType<Payload>
	| AiImageGenerationProgressNotificationType<Payload>
	| AiImageGenerationFailedNotificationType<Payload>
	| DriveStorageLimitExceededNotificationType<Payload>
	| DriveStorageLimitNearlyFullNotificationType<Payload>
	| DriveStorageChangedNotificationType<Payload>
	| ProfileUpdatedNotificationType<Payload>

export type INotification<Payload = null> = NotificationTypes<Payload> & INotificationBase

export type RequestSendNotificationPayload = NotificationTypes<'parsed'> & {
	/** Defaulted to current req user */
	userId?: string
}

export interface RequestDeleteNotificationsPayload {
	notificationId: number
}

export interface UpdateNotificationPayload {
	id: number
	isViewed?: boolean
	payload?: NotificationTypes<'parsed'>['payload']
}
