import { Reducer } from 'redux'
import { initialState } from './initialState'
import {
	CHANGE_PROFILE_CONTEXT_MENU,
	LOGIN,
	LOGINTOKEN,
	LOGOUT,
	ProfileActionTypes,
	ProfileState,
	SET_DISPLAYNAME,
	SET_LANG,
	SET_LOGIN_MODAL,
	SET_PROFILE_PICTURE,
	SET_USER_SESSION_END,
	ACCEPTED_TERMS,
	SET_IS_PRO,
	SET_USER_PERMISSIONS
} from './types'

const reducer: Reducer<ProfileState, ProfileActionTypes> = (state: ProfileState = initialState, action: ProfileActionTypes) => {
	switch (action.type) {
		case ACCEPTED_TERMS: {
			return {
				...state,
				isTermsAccepted: true
			}
		}
		case LOGINTOKEN:
		case SET_IS_PRO: {
			const { isPro } = action.payload
			return {
				...state,
				isPro
			}
		}
		case SET_USER_PERMISSIONS: {
			const { permissions } = action.payload
			return {
				...state,
				permissions
			}
		}
		case LOGIN: {
			const { userId, email, displayName, role, gravatarUrl, lang, isPro, isTermsAccepted, permissions } = action.payload
			return {
				...state,
				userId,
				email,
				displayName,
				role,
				loggedIn: true,
				gravatarUrl,
				location: window.location.pathname,
				permissions,
				lang,
				isPro,
				isTermsAccepted
			}
		}
		case CHANGE_PROFILE_CONTEXT_MENU: {
			return {
				...state,
				profileContextMenuOpen: action.payload.open
			}
		}
		case SET_LANG: {
			const lang = action.payload
			return {
				...state,
				lang
			}
		}
		case SET_LOGIN_MODAL: {
			const LoginModalState = action.payload
			return {
				...state,
				...LoginModalState
			}
		}
		case SET_USER_SESSION_END: {
			const userSessionEnd = action.payload
			return {
				...state,
				userSessionEnd
			}
		}
		case SET_DISPLAYNAME: {
			const displayName = action.payload
			return {
				...state,
				displayName
			}
		}
		case SET_PROFILE_PICTURE: {
			return {
				...state,
				avatarBlobUrl: action.payload.avatarBlobUrl || null,
				gravatarUrl: action.payload.url
			}
		}
		case LOGOUT: {
			const { lang, showLoginModal, stickyLoginModal, ...rest } = initialState
			return { ...state, ...rest }
		}
		default: {
			return state
		}
	}
}

export { reducer as profileReducer }
