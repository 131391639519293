import { APIRequest } from '@lynx/client-core/src/api'
import {
	AddCircleOutlineIcon,
	Alert,
	AutocompleteInput,
	Badge,
	BasicButton,
	Checkbox,
	FaceFilledIcon,
	Input,
	InputProps
} from '@lynx/client-core/src/components'
import ModalBase from '@lynx/client-core/src/components/Modal/Modal'
import { useEffect, useState } from 'react'
import css from './SubscriptionCustomer.module.scss'
import { CustomerSearch } from '@lynx/core/src/interfaces'

interface Props {
	selectedCustomer: CustomerSearch | null
	onCustomerSelected: (c: CustomerSearch | null) => void
}

export const SubscriptionCustomer = ({ selectedCustomer, onCustomerSelected }: Props): React.ReactElement => {
	const [isNewCustomerModalOpen, setIsNewCustomerModalOpen] = useState(false)

	const [customers, setCustomers] = useState<CustomerSearch[]>([])
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isReseller, setIsReseller] = useState(false)
	const [isCreatingCustomer, setIsCreatingCustomer] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string>('')

	const searchCustomer = async (searchValue: string): Promise<void> => {
		try {
			const data = await APIRequest.Billing.searchStripeCustomer(searchValue)
			setCustomers(data.customers)
		} catch (e) {
			setCustomers([])
		} finally {
			setIsLoading(false)
		}
	}

	const isCustomerInvalid = async (): Promise<boolean> => {
		try {
			setErrorMessage('')
			setIsCreatingCustomer(true)
			const data = await APIRequest.Billing.searchStripeCustomer(email)

			const customerExists = Boolean(data.customers.length)
			if (customerExists) setErrorMessage('Customer with this email already exists')
			return customerExists
		} catch (e) {
			return false
		} finally {
			setIsCreatingCustomer(false)
		}
	}

	const createNewCustomer = async (): Promise<void> => {
		if (await isCustomerInvalid()) return
		try {
			setIsCreatingCustomer(true)
			const newCustomer = await APIRequest.Billing.createStripeCustomer({ name, email, isReseller })
			setName('')
			setEmail('')
			setIsReseller(false)
			onCustomerSelected(newCustomer)
			setIsNewCustomerModalOpen(false)
		} catch (e) {
			// do nothing
		} finally {
			setIsCreatingCustomer(false)
		}
	}

	useEffect(() => {
		if (isNewCustomerModalOpen) {
			setName('')
			setEmail('')
			setIsReseller(false)
			setErrorMessage('')
		}
	}, [isNewCustomerModalOpen])

	const getCustomerLabel = (item: CustomerSearch): string => `${item.email} ${item.name ? `(${item.name})` : ''}`
	const getCustomItemLabel = (item: CustomerSearch): React.ReactElement => (
		<div className={css.customerItem}>
			{getCustomerLabel(item)}
			{item.isReseller && (
				<Badge variant="teal" size="xs">
					RESELLER
				</Badge>
			)}
		</div>
	)
	return (
		<div className={css.container}>
			<AutocompleteInput
				inputIcon={FaceFilledIcon}
				getCustomItemLabel={getCustomItemLabel}
				selectedItem={selectedCustomer ? { ...selectedCustomer, label: getCustomerLabel(selectedCustomer) } : null}
				fetchItems={searchCustomer}
				items={customers.map((c) => ({ ...c, label: getCustomerLabel(c) }))}
				isLoading={isLoading}
				keyPath="label"
				inputLabel="Stripe Customer Account"
				onItemSelected={onCustomerSelected}
				inputMessages={(text: string): InputProps['messages'] =>
					text && text.length < 3 ? [{ message: 'Min 3 characters to search', type: 'info' }] : []
				}
				className={css.autocomplete}
			/>
			<div className={css.btnContainer}>
				<BasicButton icon={AddCircleOutlineIcon} onClick={(): void => setIsNewCustomerModalOpen(true)}>
					New Customer
				</BasicButton>
			</div>
			{isNewCustomerModalOpen && (
				<ModalBase name="New Customer" title="New Customer" uncontrolled onHidden={(): void => setIsNewCustomerModalOpen(false)}>
					<div className={css.newCustomerContainer}>
						<Input label="Name" value={name} onChange={(e): void => setName(e.target.value)} />
						<Input label="Email" value={email} onChange={(e): void => setEmail(e.target.value)} />
						<div className={css.resellerLabel}>
							<div>Reseller</div>
							<Checkbox checked={isReseller} onChange={(e): void => setIsReseller(e.target.checked)} />
						</div>

						{errorMessage && (
							<Alert styleClass="danger" className={css.alertEl}>
								{errorMessage}
							</Alert>
						)}
						<div className={css.controlBtnsContainer}>
							<BasicButton onClick={(): void => setIsNewCustomerModalOpen(false)} disabled={isCreatingCustomer}>
								Cancel
							</BasicButton>
							<BasicButton onClick={(): Promise<void> => createNewCustomer()} isLoading={isCreatingCustomer} variant="blue">
								Create
							</BasicButton>
						</div>
					</div>
				</ModalBase>
			)}
		</div>
	)
}
