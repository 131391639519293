import {
	APIQueryParams,
	ChangeInvoiceStatusPayload,
	Coupon,
	CreatePricePayload,
	CreateSubscriptionPayload,
	CreateUserSubscriptionPayload,
	CustomerCreatePayload,
	CustomerSearch,
	OrganisationSubscriptionPricing,
	PricingSearch,
	SubscriptionTableMonthlyData
} from '@lynx/core/src/interfaces'
import { Logger } from '../../modules/Logger'
import { BaseCall } from '../BaseCall'
import { APIConfig, APIResponse } from '../interfaces'

export interface Plan {
	id: string
	subscriptionType: string
	storageLimitGB: number
	stripePriceId: string | null
	redirect: string | null
	amount: number
	currency: string
}

export type PlansResponse = Plan[]

interface CheckoutSession {
	url: string
	sessionId: string
}

interface CancelSubscriptionPortalSession {
	url: string
	id: string
}

interface CancelSubscriptionPortalSession {
	url: string
	id: string
}

interface HistoryPortalSession {
	url: string
	id: string
}

interface ManageSubscriptionPortalSession {
	url: string
	id: string
}

export interface BillingDetails {
	userId: string
	customerId: string
	subscriptionId: string
	hadPro: boolean
	current_period_end: number
	cancel_at_period_end: boolean
	isProManuallyAssigned: boolean
}

export class Billing extends BaseCall {
	public searchCoupon = async (): Promise<{ coupons: Coupon[] }> => {
		try {
			const createdPriceData = await this.client.post(`/billing/stripe/searchCoupon`)
			return createdPriceData.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to create stripe price')
		}
	}
	public createStripePrice = async (payload: CreatePricePayload): Promise<{ newPriceId: string; updatedPrices: PricingSearch['prices'] }> => {
		try {
			const createdPriceData = await this.client.post(`/billing/stripe/createPrice`, payload)
			return createdPriceData.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to create stripe price')
		}
	}

	public changeInvoiceStatus = async (payload: ChangeInvoiceStatusPayload): Promise<void> => {
		try {
			await this.client.post(`/billing/stripe/changeInvoiceStatus`, payload)
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public sendInvoice = async (payload: { organisationId: number } | { userId: string }): Promise<void> => {
		try {
			await this.client.post(`/billing/stripe/sendInvoice`, payload)
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to send invoice')
		}
	}

	public checkPastSubscriptions = async (userId: string, payload: { email: string; customerId?: string }): Promise<{ hadTrialBefore: boolean }> => {
		try {
			const res = await this.client.post(`/billing/stripe/checkPastSubscriptions/${userId}`, payload)
			return res.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to send invoice')
		}
	}

	public createOrganisationSubscription = async (data: CreateSubscriptionPayload): Promise<{ subscriptionId: string }> => {
		try {
			const res = await this.client.post(`/billing/stripe/createOrganisationSubscription`, data)
			return res.data
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public createUserSubscription = async (data: CreateUserSubscriptionPayload): Promise<{ subscriptionId: string }> => {
		try {
			const res = await this.client.post(`/billing/stripe/createUserSubscription`, data)
			return res.data
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public searchStripeCustomer = async (searchValue: string): Promise<{ customers: CustomerSearch[] }> => {
		try {
			const response = await this.client.post(`/billing/stripe/searchCustomer`, {
				searchValue
			})
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to get report')
		}
	}

	public getStripeCustomer = async (customerId: string): Promise<CustomerSearch> => {
		try {
			const response = await this.client.post(`/billing/stripe/getCustomer`, {
				customerId
			})
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to get report')
		}
	}

	public searchStripePrice = async (type: 'user' | 'organisation'): Promise<PricingSearch> => {
		try {
			const response = await this.client.post(`/billing/stripe/searchPrice?type=${type}`)
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to get report')
		}
	}

	public createStripeCustomer = async (data: CustomerCreatePayload): Promise<CustomerSearch> => {
		try {
			const res = await this.client.post<{ customer: CustomerSearch }>(`/billing/stripe/createCustomer`, data)
			return res.data.customer
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to create stripe customer')
		}
	}

	public getReport = async (
		params: APIQueryParams
	): Promise<APIResponse<{ users: SubscriptionTableMonthlyData[]; organisations: SubscriptionTableMonthlyData[] }>> => {
		try {
			const response = await this.client.get<APIResponse<{ users: SubscriptionTableMonthlyData[]; organisations: SubscriptionTableMonthlyData[] }>>(
				`/billing/report`,
				{
					params
				}
			)
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to get report')
		}
	}

	public getAccountUrl = async (type: 'user' | 'organisation', id: string | number): Promise<{ url: string } | null> => {
		try {
			const response = await this.client.get<APIResponse<{ url: string }>>(`/billing/accountUrl/${type}/${id}`)
			return response.data?.data || null
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public getEnquiries = async (params: APIQueryParams): Promise<APIResponse<{ id: number; formValues: string; createdDate: Date }[]>> => {
		try {
			const response = await this.client.get<APIResponse<{ id: number; formValues: string; createdDate: Date }[]>>(`/billing/enquiries`, { params })
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to get enquiries')
		}
	}

	public sendEnquiryForm = async (data: unknown): Promise<void> => {
		try {
			await this.client.post(`/billing/contactUs`, data)
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to send enquiry form')
		}
	}

	public getManageSubscriptionPortalURL = async (): Promise<CancelSubscriptionPortalSession> => {
		try {
			const response = await this.client.post<ManageSubscriptionPortalSession>(`/billing/manageSubscriptionPortal`)
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to create manage subscription portal session')
		}
	}

	public getBillingConfiguration = async (): Promise<{ isBillingTrialPeriodForced: boolean }> => {
		try {
			const response = await this.client.get<{ isBillingTrialPeriodForced: boolean }>(`/billing/configuration`)
			return response.data
		} catch (e) {
			Logger.error(e)
			return { isBillingTrialPeriodForced: false }
		}
	}

	public getOrganisationPricing = async (organisationId: number): Promise<OrganisationSubscriptionPricing> => {
		try {
			const response = await this.client.get<OrganisationSubscriptionPricing>(`/billing/organisation/${organisationId}/pricing`)
			return response.data
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public updateOrganisationPricing = async (organisationId: number, payload: { quantity: number }): Promise<OrganisationSubscriptionPricing> => {
		try {
			const response = await this.client.patch<OrganisationSubscriptionPricing>(`/billing/organisation/${organisationId}/pricing`, payload)
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to update organisation pricing')
		}
	}

	public createCheckoutSession = async ({
		lookup_key,
		currency,
		redirect
	}: {
		lookup_key: string
		currency?: string
		redirect?: { success_url: string; cancel_url: string }
	}): Promise<CheckoutSession> => {
		try {
			const response = await this.client.post<CheckoutSession>(`/billing/createCheckoutSession`, {
				currency,
				lookup_key,
				redirect
			})
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to create checkout session')
		}
	}

	public cancelSubscriptionPortal = async (): Promise<CancelSubscriptionPortalSession> => {
		try {
			const response = await this.client.post<CancelSubscriptionPortalSession>(`/billing/cancelSubscriptionPortal`)
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to create checkout session')
		}
	}

	public cancelSubscription = async (): Promise<void> => {
		try {
			await this.client.delete(`/billing/cancelSubscription`)
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to create checkout session')
		}
	}

	public changePaymentMethod = async (): Promise<CancelSubscriptionPortalSession> => {
		try {
			const response = await this.client.post<CancelSubscriptionPortalSession>(`/billing/manageSubscriptionPortal`)
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to change payment method')
		}
	}

	public showPaymentHistory = async (): Promise<HistoryPortalSession> => {
		try {
			const response = await this.client.post<HistoryPortalSession>(`/billing/historySubscriptionPortal`)
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to show payment history')
		}
	}

	public getSubscriptionPlans = async (): Promise<Plan[]> => {
		try {
			const response = await this.client.get<PlansResponse>(`/billing/plans`)
			return response.data
		} catch (e) {
			Logger.error(e)
			throw new Error('Failed to get plans')
		}
	}

	public updateSubscriptionPlan = async (id: string, storageLimitGB: number): Promise<void> => {
		try {
			await this.client.put(`/billing/plans`, {
				id,
				storageLimitGB
			})
		} catch (e) {
			Logger.error(e)
			throw e
		}
	}

	public getBillingDetails = async (): Promise<BillingDetails | null> => {
		try {
			const response = await this.client.get<BillingDetails>(`/billing/details`)
			return response.data
		} catch (e) {
			Logger.error(e)
			return null
		}
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
