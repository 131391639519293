import { BaseCall } from '../BaseCall'
import * as qs from 'query-string'
import { APIConfig } from '../interfaces'

export interface VersionChange {
	changeType: string
	description: string
}

export interface Version {
	changes: VersionChange[]
	versionDate: string
	versionNumber: string
	versionType: string
}

export interface ChangeLogs {
	versions: Version[]
	productName: string
	title: string
}

export class Logs extends BaseCall {
	// Logs Routes

	public getServerLogs = (): Promise<string> => {
		return this.client.get('/view/elmstone/logs').then((response) => {
			return response.data
		})
	}

	public getDiagnosticLogs = (): Promise<string> => {
		return this.client
			.get('/view/elmstone/diagnostic-logs')
			.then((response) => {
				return response.data.logs
			})
			.catch((err) => {
				// console.log(err)
			})
	}

	public getChangeLogs = async (product: string, environment: string, os: string): Promise<ChangeLogs | undefined> => {
		try {
			const changeLogsResponse = await this.client.get<ChangeLogs>(`/view/change-logs/${product}/${environment}/${os}`, {
				withCredentials: true
			})

			return changeLogsResponse.data
		} catch (e) {
			// console.log(e)
		}
	}

	public getUserManagement = (userId: string): Promise<any> => {
		return this.client.get(`/view/user-management/${userId}`).then((response) => {
			return response.data
		})
	}

	public getUserData = (params: any): Promise<any> => {
		return this.client
			.get(`/userdata/users`, {
				params: params
			})
			.then((response) => {
				return response.data
			})
	}

	public getRegistrationsData = (params: any): Promise<any> => {
		return this.client
			.get(`/userdata/registrations`, {
				params: params
			})
			.then((response) => {
				return response.data
			})
	}

	getKeys = async (submissionData: any): Promise<void> => {
		const query = qs.stringify(submissionData)
		return this.client
			.get('/api-v2/elmstone/codes?' + query, {
				responseType: 'blob',
				withCredentials: true
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'keys.csv')
				document.body.appendChild(link)
				link.click()
			})
	}

	public setConfig(config: APIConfig): void {
		this.setBaseUrl(config.API_SERVER)
	}
}
