import React from 'react'
import css from './OrganisationPermissionModal.module.scss'
import { useTranslation } from 'react-i18next'
import { OrganisationInfo } from '../../OrganisationInfo'

interface Props {
	permissionLabel: string
	organisationId: number
	organisationName: string
	organisationCountry: string
}

export const MissingPermissionsDialog = ({ permissionLabel, organisationId, organisationName, organisationCountry }: Props): React.ReactElement => {
	const { t } = useTranslation()

	return (
		<div className={css.container}>
			<p>
				{permissionLabel} {t('pages.organisation.disabledFeatureOrg')}
			</p>
			<OrganisationInfo organisationCountry={organisationCountry} organisationName={organisationName} organisationId={organisationId} />
		</div>
	)
}
