import { AddCircleOutlineIcon, Alert, BasicButton, Input, Modal, OrganisationAvatar, Slider, TransitionSwitch } from '@lynx/client-core/src/components'
import { InputMessages } from '@lynx/client-core/src/components/Input/InputMessages'
import { OrganisationSubscriptionPricing } from '@lynx/core/src/interfaces'
import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { OrganisationUserProLicenseModalType, hideModal } from 'store/modal'
import css from './OrganisationUserProLicenseModal.module.scss'

interface Props {
	organisation: OrganisationRedux
	subscriptionPricing: OrganisationSubscriptionPricing
	updateOrganisationPricing: (quantity: number) => Promise<void>
}

export const OrganisationUserProLicenseModal = ({ organisation, subscriptionPricing, updateOrganisationPricing }: Props): React.ReactElement | null => {
	const [sliderValue, setSliderValue] = useState<number>(subscriptionPricing.quantity)
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)
	const { t } = useTranslation()
	const tierBeforeHighest = subscriptionPricing.tiers[subscriptionPricing.tiers.length - 2].upTo
	const getMaxInitialTierForSlider = (): number => {
		if (!tierBeforeHighest) return 200
		if (tierBeforeHighest > 200) return tierBeforeHighest * 2
		return 200
	}
	const [maxTier, setMaxTier] = useState<number>(getMaxInitialTierForSlider())

	const proUsersCount = organisation.users.filter((user) => user.isAssignedPro).length

	const selected = subscriptionPricing.tiers.find((tier) => (tier.upTo === null ? true : sliderValue <= tier.upTo))
	if (!selected) throw new Error('No tier found')

	const unitAmount = selected.unitAmount
	const flatAmount = selected.flatAmount
	const isExceedingProUsers = proUsersCount > sliderValue
	const errors: { message: string; type: 'error' }[] = []

	if (error) errors.push({ message: error, type: 'error' })
	if (isExceedingProUsers) errors.push({ message: t('components.userLicenseLimit.youWontHaveLicenses'), type: 'error' })

	const total = unitAmount * sliderValue + flatAmount
	const formattedPrice = new Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: subscriptionPricing.currency.toUpperCase()
	}).format(total / 100)

	const onCancel = (): void => {
		setSliderValue(subscriptionPricing.quantity)
		dispatch(hideModal())
	}

	const onSave = async (): Promise<void> => {
		setIsLoading(true)
		setError(null)
		try {
			await updateOrganisationPricing(sliderValue)
		} catch (err) {
			setError(t('components.userLicenseLimit.anErrorOccurred'))
		} finally {
			setIsLoading(false)
		}
	}

	const onModalVisible = (): void => {
		setSliderValue(subscriptionPricing.quantity)
		setError(null)
		setMaxTier(getMaxInitialTierForSlider())
	}

	return (
		<Modal name={OrganisationUserProLicenseModalType} title={t('components.userLicenseLimit.modalTitle')} variant="blue" onVisible={onModalVisible}>
			<div className={css['container']}>
				<div className={css.logoContainer}>
					{organisation.avatarUrl && <OrganisationAvatar avatarUrl={organisation.avatarUrl} />}
					<div className={css.orgDetails}>
						<span className={css.orgName}>{organisation.organisationName}</span>
						<span className={css.id}>ID: {organisation.id}</span>
					</div>
				</div>
				<div className={css.numberOfLicensesContainer}>
					{t('components.userLicenseLimit.selectNoLicenses')}
					{maxTier === sliderValue && (
						<BasicButton size="xs" onClick={(): void => setMaxTier(maxTier * 2)} icon={AddCircleOutlineIcon} className={css.addMoreBtn}>
							{maxTier * 2}+
						</BasicButton>
					)}
				</div>
				<div className={css.inputsContainer}>
					<Slider value={sliderValue} max={maxTier} onChange={setSliderValue} disabled={subscriptionPricing.isReseller} />{' '}
					<Input
						type="number"
						className={css.numberInput}
						value={sliderValue}
						onChange={(e): void => setSliderValue(Number(e.target.value))}
						disabled={subscriptionPricing.isReseller}
					/>
				</div>
				<TransitionSwitch elKey={String(Boolean(errors.length))}>
					{(Boolean(errors.length) && (
						<div className={css.errorMessages}>
							<InputMessages messages={errors} />
						</div>
					)) || <div />}
				</TransitionSwitch>
				{subscriptionPricing.isReseller ? (
					<Alert styleClass="warning">{t('components.userLicenseLimit.contactReseller')}</Alert>
				) : (
					<div className={css.containerPrice}>
						{formattedPrice} {t('components.userLicenseLimit.perYear')}{' '}
						<span className={css.current}>{subscriptionPricing.quantity === sliderValue && `(${t('components.userLicenseLimit.current')})`}</span>
					</div>
				)}

				<div className={css.controls}>
					<BasicButton onClick={onCancel}>{t('components.userLicenseLimit.cancel')}</BasicButton>
					<BasicButton
						onClick={onSave}
						variant="blue"
						disabled={subscriptionPricing.quantity === sliderValue || isExceedingProUsers || subscriptionPricing.isReseller}
						isLoading={isLoading}
					>
						{t('components.userLicenseLimit.save')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
