import { getDefaultUserPermissions } from '@lynx/core/src/utils/permissionsUtils'
import { ProfileState } from './types'
import { allPermissions } from '@lynx/core/src/constants'

export const initialState: ProfileState = {
	userId: '',
	email: '',
	displayName: '',
	role: 4,
	loggedIn: false,
	gravatarUrl: '',
	avatarBlobUrl: null,
	location: window.location.pathname,
	lang: null,
	permissions: getDefaultUserPermissions([...allPermissions]),
	profileContextMenuOpen: false,
	showLoginModal: false,
	stickyLoginModal: false,
	showZoom: false,
	userSessionEnd: false,
	isPro: false,
	isTermsAccepted: false
}
