import { PermissionsKeys } from '@lynx/core/src/interfaces'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { hasUserPermission } from '@lynx/core'

export const useHasUserPermissions = (targetPermissions: PermissionsKeys | PermissionsKeys[]): boolean => {
	const { permissions: userPermissions } = useSelector((state: ApplicationState) => state.profile)
	const hasPermission = hasUserPermission(targetPermissions, userPermissions)
	return hasPermission
}
