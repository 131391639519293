import { hasUserPermission } from '@lynx/core'
import { SubscriptionsAdminWritePermission } from '@lynx/core/src/constants'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './CreateSubscriptionButton.module.scss'
import { AddCircleOutlineIcon, BasicButton } from '@lynx/client-core/src/components'
import { ReactElement } from 'react'

interface Props {
	title: string
	setIsNewSubscriptionModalOpen: (v: boolean) => void
}

export const CreateSubscriptionButton = ({ title, setIsNewSubscriptionModalOpen }: Props): ReactElement => {
	const { permissions } = useSelector((state: ApplicationState) => state.profile)

	return hasUserPermission(SubscriptionsAdminWritePermission, permissions) ? (
		<div className={css.titleContainer}>
			<h1>{title}</h1>
			<div className={css.btnContainer}>
				<BasicButton variant="blue" icon={AddCircleOutlineIcon} onClick={(): void => setIsNewSubscriptionModalOpen(true)} size="sm">
					New Subscription
				</BasicButton>
			</div>
		</div>
	) : (
		<>{title}</>
	)
}
