import { UserProfile } from '@lynx/core/src/interfaces/User'
import { Action, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AuthCookie } from '../../modules/AuthCookie'
import { ApplicationState } from '../'
import { addBusy, removeBusy } from '../busy'
import { unsubscribeChannel } from '../messaging'
import {
	ACCEPTED_TERMS,
	Login,
	LOGIN,
	LOGOUT,
	ProfileActionTypes,
	CHANGE_PROFILE_CONTEXT_MENU,
	SET_DISPLAYNAME,
	SET_EMAIL,
	SET_LANG,
	SET_ZOOM,
	SET_LOGIN_MODAL,
	SET_USER_SESSION_END,
	SET_PROFILE_PICTURE,
	setProfilePictureAction,
	SET_IS_PRO,
	SET_USER_PERMISSIONS
} from './types'
import { clearDrive } from '../drive/actions'
import { Logger } from '../../modules'
import { APIRequest } from '../../api'
import { ProfileReturn } from '../../api/interfaces'
import { PermissionsType, UserPermissionsType } from '@lynx/core/src/interfaces'

export const logoutInternal = (): ProfileActionTypes => {
	return {
		type: LOGOUT
	}
}

const setEmailInternal = (email: string): ProfileActionTypes => {
	return {
		type: SET_EMAIL,
		payload: email
	}
}

export const setUserSessionEnd = (payload: boolean): ProfileActionTypes => {
	return {
		type: SET_USER_SESSION_END,
		payload
	}
}

export const acceptedTerms = (): ProfileActionTypes => {
	return {
		type: ACCEPTED_TERMS
	}
}

const setDisplayNameInternal = (displayName: string): ProfileActionTypes => {
	return {
		type: SET_DISPLAYNAME,
		payload: displayName
	}
}

export const setLang = (lang: string): ProfileActionTypes => {
	return {
		type: SET_LANG,
		payload: lang
	}
}

export const setZoom = (showZoom: boolean): ProfileActionTypes => {
	return {
		type: SET_ZOOM,
		payload: showZoom
	}
}

export const setLoginModal = (showLoginModal: boolean, stickyLoginModal = false): ProfileActionTypes => {
	return {
		type: SET_LOGIN_MODAL,
		payload: {
			showLoginModal,
			stickyLoginModal
		}
	}
}

export const setIsPro = (payload: { isPro: boolean }): ProfileActionTypes => {
	return {
		type: SET_IS_PRO,
		payload
	}
}

export const setUserPermissions = (payload: { permissions: PermissionsType }): ProfileActionTypes => {
	return {
		type: SET_USER_PERMISSIONS,
		payload
	}
}

const loginInternal = (userProfile: UserProfile): ProfileActionTypes => {
	return {
		type: LOGIN,
		payload: userProfile
	}
}

export const setProfilePicture = (data: setProfilePictureAction['payload']): ProfileActionTypes => {
	return {
		type: SET_PROFILE_PICTURE,
		payload: data
	}
}

export const clientLogout =
	(): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
		const isBlockingAction = true
		try {
			dispatch(addBusy(isBlockingAction))
			const {
				profile: { userId },
				config
			} = getState()
			if (userId) await unsubscribeChannel(userId)(dispatch, getState, null)
			AuthCookie.delete('lynxbridge', config.ENVIRONMENT)

			dispatch(clearDrive())
			dispatch(logoutInternal())
		} catch (err) {
			Logger.error(err)
		} finally {
			dispatch(removeBusy(isBlockingAction))
		}
	}

export const logout =
	(): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<void> => {
		clientLogout()(dispatch, getState, null)
		try {
			await APIRequest.User.logout()
		} catch (err) {
			Logger.error(err)
		}
	}

export const logoutEverywhere = (): ThunkAction<Promise<void>, ApplicationState, null, Action<string>> => async (): Promise<void> => {
	try {
		await APIRequest.User.logoutEverywhere()
	} catch (err) {
		Logger.error(err)
	}
}

export const setEmail =
	(email: string): ThunkAction<Promise<ProfileReturn>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<ProfileReturn> => {
		const isBlockingAction = true

		dispatch(addBusy(isBlockingAction))

		try {
			const changed: ProfileReturn = await APIRequest.User.changeEmail(email)

			if (changed.success) {
				dispatch(setEmailInternal(email))
			}

			dispatch(removeBusy(isBlockingAction))

			return changed
		} catch (err) {
			dispatch(removeBusy(isBlockingAction))
			return { success: false, message: 'Something went wrong' }
		}
	}

export const setDisplayName =
	(displayName: string): ThunkAction<Promise<ProfileReturn>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch): Promise<ProfileReturn> => {
		const isBlockingAction = true

		dispatch(addBusy(isBlockingAction))

		try {
			const changed: ProfileReturn = await APIRequest.User.changeDisplayName(displayName)

			if (changed.success) {
				dispatch(setDisplayNameInternal(displayName))
			}

			dispatch(removeBusy(isBlockingAction))

			return changed
		} catch (err) {
			dispatch(removeBusy(isBlockingAction))
			return { success: false, message: 'Something went wrong' }
		}
	}

let maxSessionAttempts = 0
export const login =
	(login: Login | null = null): ThunkAction<Promise<string>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<string> => {
		const isBlockingAction = true

		dispatch(addBusy(isBlockingAction))
		try {
			let loginProfile
			if (login) {
				const { username, password, code } = login
				await logout()(dispatch, getState, null)
				loginProfile = await APIRequest.User.login(username, password, code)
			} else {
				maxSessionAttempts++
				if (maxSessionAttempts > 10) {
					setTimeout(() => {
						maxSessionAttempts = 0
					}, 120000)
					return ''
				}
				const sessionLogin = await APIRequest.User.session()
				if (!sessionLogin) {
					return ''
				}

				const { access_token, refresh_token, displayName, role, userId, gravatarUrl, email, lang, isPro, isTermsAccepted, permissions } = sessionLogin
				let currentLang = lang
				if (!lang) {
					currentLang = 'en'
				}
				loginProfile = {
					user: {
						displayName,
						role,
						userId,
						gravatarUrl,
						email,
						permissions,
						lang: currentLang,
						isPro,
						isTermsAccepted
					},
					access_token,
					refresh_token
				}
				await APIRequest.User.logout()
			}

			const code = login?.code || ''
			const rememberMe = login?.rememberMe || false
			const saveProfile = rememberMe || !code

			if (loginProfile && saveProfile) {
				const { access_token: accessToken, refresh_token: refreshToken, user } = loginProfile
				if (!accessToken || !refreshToken) {
					dispatch(removeBusy(isBlockingAction))
					return ''
				}

				AuthCookie.create(getState().config.ENVIRONMENT, accessToken, refreshToken)

				APIRequest.setAuthorization(accessToken)

				dispatch(loginInternal(user))
				dispatch(removeBusy(isBlockingAction))

				return accessToken
			} else if (loginProfile) {
				const { access_token: accessToken, refresh_token: refreshToken, user } = loginProfile
				if (!accessToken || !refreshToken) {
					dispatch(removeBusy(isBlockingAction))
					return ''
				}
				APIRequest.setAuthorization(accessToken)
				dispatch(loginInternal(user))
				dispatch(removeBusy(isBlockingAction))
				return 'no-save'
			}

			return ''
		} catch (e) {
			const err = e as any
			Logger.error(e)

			const isServerDown = err.code === 'ERR_NETWORK'
			if (!isServerDown) logout()(dispatch, getState, null)

			dispatch(removeBusy(isBlockingAction))
			if (err.response?.data['login-failed-message']) {
				return err.response.data['login-failed-message']
			}
			return ''
		}
	}

export const changeProfileContextMenu = (open: boolean): ProfileActionTypes => {
	return {
		type: CHANGE_PROFILE_CONTEXT_MENU,
		payload: {
			open
		}
	}
}

export const loginCookie =
	(): ThunkAction<Promise<string | void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<string | void> => {
		const isBlockingAction = true
		dispatch(addBusy(isBlockingAction))

		try {
			const cookie = AuthCookie.get('lynxbridge', getState().config.ENVIRONMENT)
			if (!cookie || cookie === 'LOGGED_OUT') {
				dispatch(removeBusy(isBlockingAction))
				return ''
			}

			const tokens = atob(cookie).split(' ')

			if (tokens.length < 2) {
				Logger.error('token length error')
				dispatch(removeBusy(isBlockingAction))
				return ''
			}
			const [cookieRefreshToken, cookieAccessToken] = tokens

			const accessToken =
				(await APIRequest.User.renew({
					refreshToken: cookieRefreshToken,
					accessToken: cookieAccessToken
				})) || null

			const accToken = await loginWithTokens({ accessToken, refreshToken: cookieRefreshToken })(dispatch, getState, null)

			return accToken
		} catch (e) {
			logout()(dispatch, getState, null)
			dispatch(removeBusy(isBlockingAction))

			return ''
		}
	}

export const loginWithTokenId =
	(tokenId: string): ThunkAction<Promise<string | void>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<string | void> => {
		const isBlockingAction = true
		dispatch(addBusy(isBlockingAction))
		const tokens = await APIRequest.User.getTokensFromTokenId(tokenId)
		const { accessToken, refreshToken } = tokens
		const token = await loginWithTokens({ accessToken, refreshToken })(dispatch, getState, null)
		return token
	}

export const loginWithTokens =
	({
		accessToken,
		refreshToken
	}: {
		accessToken: string | null
		refreshToken: string
	}): ThunkAction<Promise<string>, ApplicationState, null, Action<string>> =>
	async (dispatch: Dispatch, getState: () => ApplicationState): Promise<string> => {
		if (!accessToken) {
			Logger.error('access token was empty')
			return ''
		}
		AuthCookie.create(getState().config.ENVIRONMENT, accessToken, refreshToken)
		APIRequest.setAuthorization(accessToken)
		const userProfile = await APIRequest.User.profile()
		if (userProfile) {
			dispatch(loginInternal(userProfile))
			return accessToken
		}

		return ''
	}
