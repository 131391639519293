import React from 'react'
import * as Modals from 'components/modals'
import { Document, WithPermissions } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import { Drive } from './Drive'
import { OrganisationDrivePermission } from '@lynx/core/src/constants'

export const DrivePage = (): React.ReactElement => {
	return (
		<Document title={i18next.t('components.drive.driveBrowser.title')} description="CloudDrive" keywords="CloudDrive" auth={true}>
			<WithPermissions targetPermissions={OrganisationDrivePermission} modalEnabled>
				<Drive />
			</WithPermissions>
			<Modals.InformationModal />
		</Document>
	)
}
