import { APIRequest } from '@lynx/client-core/src/api'
import { Label, ListItem, Toggle } from '@lynx/client-core/src/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './EmailPreferences.module.scss'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { hasUserPermission } from '@lynx/core'
import { BetaPermission } from '@lynx/core/src/constants'

export const EmailPreferences = (): React.ReactElement => {
	const { t } = useTranslation()
	const { permissions } = useSelector((state: ApplicationState) => state.profile)

	const onSubcribeBetaEmailChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		const shouldSubscribeBeta = e.target.checked
		shouldSubscribeBeta ? await APIRequest.Newsletter.subscribe('beta') : await APIRequest.Newsletter.unsubscribe('beta')
	}

	const onSubcribeEmailChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		const shouldSubscribe = e.target.checked
		shouldSubscribe ? await APIRequest.Newsletter.subscribe('production') : await APIRequest.Newsletter.unsubscribe('production')
	}

	return (
		<ListItem header={t('pages.profile.emailPreferences')}>
			<div className={css['container']}>
				{hasUserPermission(BetaPermission, permissions) && (
					<div>
						<Label text={t('pages.profile.emailUpdatesBeta')} />
						<Toggle onChange={onSubcribeBetaEmailChange} />
					</div>
				)}
				<div>
					<Label text={t('pages.profile.emailUpdates')} />
					<Toggle onChange={onSubcribeEmailChange} />
				</div>
			</div>
		</ListItem>
	)
}
