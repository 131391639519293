import { Plan } from '@lynx/client-core/src/api/Billing/Billing'
import { Document, Tabs } from '@lynx/client-core/src/components'
import React, { useState } from 'react'
import { GlobalStorageConfiguration } from './GlobalStorageConfiguration'
import { UsersList } from './UsersList.tsx'
import { OrganisationList } from './OrganisationsList'
import css from './Subscriptions.module.scss'
import { hasUserPermission } from '@lynx/core'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { SubscriptionsAdminWritePermission } from '@lynx/core/src/constants'

export const Subscriptions = (): React.ReactElement => {
	const { permissions } = useSelector((state: ApplicationState) => state.profile)
	const [plans, setPlans] = useState<Plan[]>([])
	const [selectedTab, setSelectedTab] = useState<string>('users')

	return (
		<Document title="Subscriptions" keywords="subscriptions" auth={true} disableOverflow={true}>
			<div>
				{hasUserPermission(SubscriptionsAdminWritePermission, permissions) && <GlobalStorageConfiguration setPlans={setPlans} />}
				<div className={css.tabsContainer}>
					<Tabs
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						tabs={[
							{ label: 'Users', key: 'users' },
							{ label: 'Organisations', key: 'orgs' }
						]}
					/>
				</div>
				<div style={{ display: selectedTab === 'orgs' ? 'block' : 'none' }}>
					<OrganisationList plans={plans} />
				</div>
				<div style={{ display: selectedTab === 'users' ? 'block' : 'none' }}>
					<UsersList plans={plans} />
				</div>
			</div>
		</Document>
	)
}
