import { AddCircleOutlineIcon, BasicButton, BuildingIcon, OrganisationAvatar, ProBadgeIcon } from '@lynx/client-core/src/components'
import { showModal } from '@lynx/client-core/src/store/modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { setActiveOrganisation } from 'store/organisations'
import css from './AllOrganisations.module.scss'

interface AllOrganisationsProps {
	className?: string
}

export const AllOrganisations = ({ className }: AllOrganisationsProps): React.ReactElement | null => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { organisations } = useSelector((state: ApplicationState) => state)

	const handleOrganisationClicked = async (id: number): Promise<void> => {
		dispatch(setActiveOrganisation(id))
	}

	const handleNewOrganisationClicked = (): void => {
		dispatch(showModal({ name: 'OrganisationCreateModal' }))
	}

	const organisationElements = organisations.organisations.map(({ id, organisationName, isPro, avatarUrl }, key) => (
		<li
			key={id}
			onClick={(): void => {
				handleOrganisationClicked(id)
			}}
		>
			<div className={css['icons']}>
				{avatarUrl && <OrganisationAvatar avatarUrl={avatarUrl} />}
				{isPro && <ProBadgeIcon className={css['icons__pro']} />}
			</div>
			<div>
				<div>{organisationName}</div>
				<div className={css['id']}>ID: {id}</div>
			</div>
		</li>
	))

	const classNames = [css['container']]
	className && classNames.push(className)

	return (
		<div className={classNames.join(' ')}>
			<h4>{t('pages.organisations.allOrganisations')}</h4>
			<ul className={css['container__list']}>{organisationElements}</ul>
			<BasicButton icon={AddCircleOutlineIcon} variant="blue" style={{ width: '100%' }} onClick={handleNewOrganisationClicked}>
				{t('pages.organisations.newOrganisation')}
			</BasicButton>
		</div>
	)
}
