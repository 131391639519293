import { ProBadgeIcon } from '@lynx/client-core/src/components'
import { organisationPermissions } from '@lynx/core/src/constants'
import { OrganisationRedux } from '@lynx/core/src/interfaces/Organisation'
import { UserPermissions } from 'pages/Admin/routes/elmstone/Subscriptions/UserPermissions'
import { useTranslation } from 'react-i18next'
import css from './OrganisationPermissionsManagement.module.scss'
import { APIRequest } from '@lynx/client-core/src/api'
import { PermissionsKeys } from '@lynx/core/src/interfaces'
import { useThunkDispatch } from 'hooks'
import { loadOrganisations } from 'store/organisations'

interface Props {
	organisation: OrganisationRedux
}

export const OrganisationPermissionsManagement = ({ organisation }: Props): React.ReactElement => {
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()

	const togglePermission = async (permissionKey: PermissionsKeys): Promise<void> => {
		const toggleFunction = organisation.permissions[permissionKey].isGranted
			? APIRequest.Organisations.revokeUserPermission
			: APIRequest.Organisations.grantUserPermission
		await toggleFunction(organisation.id, permissionKey)
		await dispatchThunk(loadOrganisations())
	}
	return (
		<div className={css.container}>
			<h1 className={css.title}>
				{t('pages.organisation.customiseFeatures')} <ProBadgeIcon />
			</h1>
			<div className={css.subTitle}>{t('pages.organisation.managementOrg')}</div>
			<UserPermissions permissionKeys={[...organisationPermissions]} permissions={organisation.permissions} togglePermission={togglePermission} />
		</div>
	)
}
