import * as React from 'react'
import { useDispatch } from 'react-redux'
import css from './OrganisationPermissionModal.module.scss'
import { useSelector } from 'react-redux'
import { allPermissionsData } from '@lynx/core/src/constants'
import { MissingPermissionsDialog } from './MissingPermissionsDialog'
import { OrganisationPermissionModalContextType, hideModal } from '../../../store/modal'
import { ModalDefaultLayout } from '../../Modal/templates/ModalDefaultLayout/ModalDefaultLayout'
import { Modal } from '../../Modal'
import { BasicButton } from '../../BasicButton/BasicButton'
import { ApplicationState } from '../../../store'
import { useTranslation } from 'react-i18next'

export const OrganisationPermissionModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const {
		modal: { context },
		profile: { permissions }
	} = useSelector((state: ApplicationState) => state)
	const { t } = useTranslation()
	const permissionKey = context?.type === OrganisationPermissionModalContextType ? context.permissionKey : ''
	const permData = permissionKey && permissions[permissionKey]
	if (!permData || !permData.organisationId || !permData.organisationName) return null
	const { organisationId, organisationName, organisationCountry } = permData

	const permissionLabel = allPermissionsData[permissionKey].label

	return (
		<Modal name={OrganisationPermissionModalContextType} hideCloseButton variant="yellow">
			<ModalDefaultLayout
				title={`${permissionLabel} ${t('pages.organisation.disabled')}`}
				body={
					<MissingPermissionsDialog
						permissionLabel={permissionLabel}
						organisationId={organisationId}
						organisationName={organisationName}
						organisationCountry={organisationCountry || ''}
					/>
				}
				footer={
					<BasicButton
						className={css.okButton}
						variant="grey"
						onClick={(): void => {
							dispatch(hideModal())
						}}
					>
						OK
					</BasicButton>
				}
			></ModalDefaultLayout>
		</Modal>
	)
}
