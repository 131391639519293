import { Document, List, WithPermissions } from '@lynx/client-core/src/components'
import i18next from 'i18next'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import css from './Profile.module.scss'
import { AccountDetails, AccountRemoval, Authentication, CloudDrives, Devices, EmailPreferences, Organisations, YourPlanFree, YourPlanPro } from './components'
import { OrganisationDrivePermission } from '@lynx/core/src/constants'

export const Profile = (): React.ReactElement => {
	return (
		<Document title={i18next.t('pages.pageTitles.profile')} description="Profile" keywords="Profile" auth={true} className={css['container']}>
			<DndProvider backend={HTML5Backend}>
				<List>
					<AccountDetails />
					<YourPlanFree />
					<YourPlanPro />
					<EmailPreferences />
					<Organisations />
					<WithPermissions targetPermissions={OrganisationDrivePermission}>
						<CloudDrives />
					</WithPermissions>
					<Authentication />
					<Devices />
					<AccountRemoval />
				</List>
			</DndProvider>
		</Document>
	)
}
