import React, { useEffect } from 'react'
import css from './SubscriptionTrialPeriod.module.scss'
import { AccountProfile, CustomerSearch } from '@lynx/core/src/interfaces'
import { APIRequest } from '@lynx/client-core/src/api'
import { LoadingSpinner } from '@lynx/client-core/src/components'

interface Props {
	selectedUser: AccountProfile | null
	selectedCustomer: CustomerSearch | null
	setHadTrialBefore: (hadTrialBefore: boolean) => void
	hadTrialBefore: boolean | null
}

export const SubscriptionTrialPeriod = ({ selectedCustomer, selectedUser, hadTrialBefore, setHadTrialBefore }: Props): React.ReactElement => {
	const [isLoading, setIsLoading] = React.useState(false)
	const getUserTrialData = async (): Promise<void> => {
		if (!selectedUser) return
		try {
			setIsLoading(true)
			const data = await APIRequest.Billing.checkPastSubscriptions(selectedUser?.userId || '', {
				email: selectedUser.email,
				customerId: selectedCustomer?.id || ''
			})
			setHadTrialBefore(data.hadTrialBefore)
		} catch (err) {
			// do noything
		} finally {
			setIsLoading(false)
		}
	}

	const value = hadTrialBefore === null ? '-' : <div>{hadTrialBefore ? 0 : 14}</div>
	const valueDisplay = isLoading ? <LoadingSpinner /> : value
	useEffect(() => {
		getUserTrialData()
	}, [selectedUser, selectedCustomer])
	return (
		<div className={css.container}>
			<label className={css.label}>Trial period days</label>
			{valueDisplay}
		</div>
	)
}
