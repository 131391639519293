import { Modal } from '@lynx/client-core/src/components'
import css from './AuthenticationProvidersHelpModal.module.scss'
import { useTranslation } from 'react-i18next'
import { authenticationProviderIcons } from 'pages/Pricing/data'

export const AuthenticationProvidersHelpModal = (): React.ReactElement => {
	const { t } = useTranslation()

	const providers = authenticationProviderIcons.map(({ icon, name }) => (
		<div key={'providers-' + name}>
			<div>{icon}</div>
			<div>{name}</div>
		</div>
	))
	return (
		<Modal name="AuthenticationProvidersHelpModal" title={t('pages.pricing.table.authenticationProviders')} variant="transparent" className={css['modal']}>
			<div className={css['container']}>{providers}</div>
		</Modal>
	)
}
