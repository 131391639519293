import Styles from './Share.module.scss'
import { APIRequest } from '@lynx/client-core/src/api'
import { Document, WithPermissions } from '@lynx/client-core/src/components'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import i18next from 'i18next'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Routes, useNavigate, useParams } from 'react-router'
import { Route } from 'react-router-dom'
import { ApplicationState } from 'store'
import extensionsData from './data.json'
import { Status } from './interfaces'
import { EmailShareModal } from './Modals'
import GroupShareModal from './Modals/GroupShareModal/GroupShareModal'
import { SignUpModal } from './Modals/SignUpModal'
import { Image, Lynx, PDF, Standard, ThumbnailsExport, Video } from './routes'
import { LynxActivityExport } from './routes/LynxActivityExport/LynxActivityExport'
import { Mesh } from './routes/Mesh'
import { SaveAsModal } from 'pages/Drives/Modals/SaveAsModal'
import { OrganisationSharingPermission } from '@lynx/core/src/constants'

const extensions: { [key: string]: { handler: string; savesTo: string[]; routeOnly?: boolean } } = extensionsData

export const Share = (): React.ReactElement | null => {
	const { profile } = useSelector((state: ApplicationState) => state)
	const previousLoggedIn = usePrevious(profile.loggedIn)
	const navigate = useNavigate()
	const [status, setStatus] = useState<Status>('loading')
	const params = useParams<{ token: string }>()

	const loadShare = async (): Promise<void> => {
		const { token } = params
		if (!token) {
			Logger.error('missing token')
			return
		}

		const shareDetails = await APIRequest.Sharing.info(token)
		if (shareDetails.error) {
			const { message } = shareDetails
			switch (message) {
				case 'private': {
					setStatus('private')
					return
				}
				case 'missing': {
					setStatus('missing')
					return
				}
			}
		}

		if (!shareDetails?.data) {
			setStatus('missing')
			return
		}

		setStatus('found')

		const extension = shareDetails.data.name.split('.').pop()?.toLowerCase()
		if (extension && extensions[extension]) {
			const redirectUrl = `/share/${token}/${extensions[extension].handler}`
			const parsedUrl = qs.parseUrl(window.location.href)
			const reformedUrl = qs.stringifyUrl({ url: redirectUrl, query: parsedUrl.query })
			navigate(reformedUrl)
			return
		}

		navigate(`/share/${token}/standard`)
	}

	useEffect(() => {
		loadShare()
	}, [])

	useEffect(() => {
		if (!previousLoggedIn && profile.loggedIn && status === 'private') {
			loadShare()
		}
	}, [profile.loggedIn])

	switch (status) {
		case 'missing': {
			return (
				<div className={Styles['message-container']}>
					<div>{i18next.t('pages.share.missing')}</div>
				</div>
			)
		}
		case 'private': {
			return (
				<div className={Styles['message-container']}>
					<div>{i18next.t('pages.share.private')}</div>
				</div>
			)
		}
	}

	return (
		<Document title={i18next.t('pages.pageTitles.share')} srcs={['https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js']}>
			<div className={Styles['container']}>
				<WithPermissions targetPermissions={OrganisationSharingPermission} modalEnabled>
					<Routes>
						<Route path="/image" element={<Image />} />
						<Route path="/lynx" element={<Lynx />} />
						<Route path="/lynxactivityexport" element={<LynxActivityExport />} />
						<Route path="/pdf" element={<PDF />} />
						<Route path="/thumbnails" element={<ThumbnailsExport />} />
						<Route path="/standard" element={<Standard />} />
						<Route path="/video" element={<Video />} />
						<Route path="/mesh" element={<Mesh />} />
					</Routes>
				</WithPermissions>
			</div>
			<GroupShareModal />
			<EmailShareModal />
			<SignUpModal />
			<SaveAsModal />
		</Document>
	)
}
