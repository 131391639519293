import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Sites, CountryFix, CodeGen, Feedback, Subscriptions, Enquiries, Base64 } from '.'
import { PaymentAuditTrail } from './PaymentAuditTrail/PaymentAuditTrail'

export const ElmstonePages = (): React.ReactElement => {
	return (
		<Routes>
			<Route path="code-gen" element={<CodeGen />} />
			<Route path="country-fix" element={<CountryFix />} />
			<Route path="feedback" element={<Feedback />} />
			<Route path="sites" element={<Sites />} />
			<Route path="subscriptions" element={<Subscriptions />} />
			<Route path="payment-audit-trail" element={<PaymentAuditTrail />} />
			<Route path="enquiries" element={<Enquiries />} />
			<Route path="base64" element={<Base64 />} />
		</Routes>
	)
}
