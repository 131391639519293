import { APIRequest } from '@lynx/client-core/src/api'
import { GrabHandleDotsIcon, OpenIcon, OrganisationAvatar, UnlinkIcon, VisibilityHide, VisibilityShow } from '@lynx/client-core/src/components'
import { AreYouSureModalContextType, hideModal, showModal } from '@lynx/client-core/src/store/modal'
import { ProfileDriveItem, Providers } from '@lynx/core/src/interfaces/ObjectStore'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import css from './CloudDrivesCardBody.module.scss'
import { ProfileOverlay } from './ProfileOverlay'

export type Item = ProfileDriveItem

interface DriveCardBodyProps {
	drive: Item
	onHideDriveClicked: (driveId: string) => void
	onUnhideDriveClicked: (driveId: string) => void
	onUnlinked: () => void
	isHidden?: boolean
	usagePercentage: number | null
}

export const CloudDrivesCardBody = ({
	drive: { driveId, provider, providerContainerId, icon, name, label, type },
	onHideDriveClicked,
	onUnhideDriveClicked,
	onUnlinked,
	isHidden,
	usagePercentage = 0
}: DriveCardBodyProps): React.ReactElement => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleOpenDriveClicked = async (): Promise<void> => {
		if (providerContainerId) {
			navigate(`/drive/${provider}?driveId=${providerContainerId}`)
		} else {
			navigate(`/drive/${provider}`)
		}
	}

	const handleUnlinkDriveClicked = (): void => {
		dispatch(
			showModal({
				name: 'AreYouSure',
				context: {
					type: AreYouSureModalContextType,
					handleConfirm: async (): Promise<void> => {
						const res = await APIRequest.ObjectStore.unlink(provider as Providers, providerContainerId)
						if (res.status === 200) {
							onUnlinked()
							dispatch(hideModal())
						}
					},
					confirmText: i18next.t('pages.profile.unlink'),
					confirmBody: i18next.t('pages.profile.unlinkConfirm')
				}
			})
		)
	}

	const handleHideDriveClicked = (): void => {
		onHideDriveClicked(driveId)
	}

	const handleUnhideDriveClicked = (): void => {
		onUnhideDriveClicked(driveId)
	}

	const ellipsisClassNames = [css['container__ellipsis']]
	let canUnlink = true
	if (type === 'organisation' || type === 'sharepoint' || provider === 'lynxcloud') {
		canUnlink = false
	}

	const iconElement = type === 'organisation' ? <OrganisationAvatar avatarUrl={icon} /> : <img src={icon} alt="icon" />

	return (
		<div className={css['container']}>
			<GrabHandleDotsIcon style={{ visibility: isHidden ? 'hidden' : 'visible' }} />
			<div className={css['container__image']}>{iconElement}</div>
			<div className={css['container__name']}>
				<div>{name}</div>
				{Boolean(usagePercentage) && (
					<div className={`${css['container__name__usage']}`}>
						{t('pages.profile.usage')} {Math.round(usagePercentage || 0)}%
					</div>
				)}
			</div>
			<div className={css['container__overflow-hide']}>{label}</div>
			<div className={css['container__open']}>
				<OpenIcon onClick={handleOpenDriveClicked} />
			</div>
			<div className={ellipsisClassNames.join(' ')}>
				<ProfileOverlay>
					<ul>
						{!isHidden && (
							<li onClick={handleHideDriveClicked}>
								<VisibilityHide /> {t('pages.profile.hideDrive')}
							</li>
						)}
						{isHidden && (
							<li onClick={handleUnhideDriveClicked}>
								<VisibilityShow /> {t('pages.profile.unhideDrive')}
							</li>
						)}
						{canUnlink && (
							<li onClick={handleUnlinkDriveClicked}>
								<UnlinkIcon /> {t('pages.profile.unlink')}
							</li>
						)}
					</ul>
				</ProfileOverlay>
			</div>
		</div>
	)
}
