export const AdminPermission = 'admin' as const
export const TranslationPermission = 'translation' as const
export const BetaPermission = 'beta' as const
export const ReportsPermission = 'reports' as const
export const SubscriptionsAdminWritePermission = 'subscriptionsAdminWrite' as const
export const SubscriptionsAdminReadPermission = 'subscriptionsAdminRead' as const
export const SaharaPermission = 'sahara' as const

export const userPermissions = [
	AdminPermission,
	TranslationPermission,
	SaharaPermission,
	BetaPermission,
	SubscriptionsAdminWritePermission,
	SubscriptionsAdminReadPermission,
	ReportsPermission
] as const

export const userPermissionDefaultValue: { isGranted: boolean } = {
	isGranted: false
}

export const userPermissionsData: {
	[key in (typeof userPermissions)[number]]: { description: string; label: string; permissionId: number; defaultValue: typeof userPermissionDefaultValue }
} = {
	[AdminPermission]: {
		description: 'Full Access - Anything',
		label: 'Admin',
		permissionId: 0,
		defaultValue: userPermissionDefaultValue
	},
	[TranslationPermission]: {
		description: 'Full Access - Translations page',
		label: 'Translations',
		permissionId: 1,
		defaultValue: userPermissionDefaultValue
	},
	[SaharaPermission]: {
		description: 'Full Access - Sahara section on Admin page',
		label: 'Sahara',
		permissionId: 2,
		defaultValue: userPermissionDefaultValue
	},
	[ReportsPermission]: {
		description: 'Full Access - Reports section on Admin page',
		label: 'Reports',
		permissionId: 3,
		defaultValue: userPermissionDefaultValue
	},
	[BetaPermission]: {
		description: 'Full Access - Beta features',
		label: 'BetaFeatures',
		permissionId: 4,
		defaultValue: userPermissionDefaultValue
	},
	[SubscriptionsAdminWritePermission]: {
		description: 'Write - Subscriptions',
		label: 'SubscriptionsWrite',
		permissionId: 5,
		defaultValue: userPermissionDefaultValue
	},
	[SubscriptionsAdminReadPermission]: {
		description: 'Read - Subscriptions',
		label: 'SubscriptionsRead',
		permissionId: 6,
		defaultValue: userPermissionDefaultValue
	}
}
