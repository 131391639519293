import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import css from './OrganisationAvatar.module.scss'
import { AuthCookie } from '../../modules'
import { BuildingIcon } from '../Icons'
import { ApplicationState } from '../../store'

interface Props {
	avatarUrl: string
}

export const OrganisationAvatar = ({ avatarUrl }: Props): React.ReactElement => {
	const { config } = useSelector((state: ApplicationState) => state)
	const [imgBlob, setImgBlob] = useState<string | null>(null)

	const fetchAvatar = async (): Promise<void> => {
		const { ENVIRONMENT } = config
		const accessToken = AuthCookie.getAccessToken(ENVIRONMENT)

		const res = await fetch(avatarUrl, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		})
		const isDefaultAvatar = res.headers.get('Content-Disposition')?.endsWith('default-avatar.png')
		if (isDefaultAvatar) {
			setImgBlob(null)
			return
		}
		const blob = await res.blob()
		const bloburl = URL.createObjectURL(blob)
		setImgBlob(bloburl)
	}

	useEffect(() => {
		avatarUrl && fetchAvatar()
	}, [avatarUrl])

	if (imgBlob) return <img src={imgBlob} alt="org-avatar" className={css.orgImg} />

	return <BuildingIcon className={css.svgIcon} />
}
