import { APIRequest } from '@lynx/client-core/src/api'
import { Document, Table } from '@lynx/client-core/src/components'
import { useDebounce } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'
import { Card } from 'components/elmstone/Card'
import React, { useEffect, useState } from 'react'

interface RegistrationsState {
	columns: any[]
	data: any[]
	title: string
	totalCount: number
	searchText: string
	page: number
	rowsPerPage: number
}

export const Registrations = (): React.ReactElement => {
	const [state, setState] = useState<RegistrationsState>({
		columns: [
			{ valueKey: 'lynxCode', label: 'LYNX Code' },
			{ valueKey: 'name', label: 'Name' },
			{ valueKey: 'email', label: 'Email' },
			{ valueKey: 'organisation', label: 'Organisation' },
			{ valueKey: 'lynxVersion', label: 'LYNX version' },
			{ valueKey: 'updatedAt', label: 'Last connected' },
			{ valueKey: 'createdAt', label: 'Created date' }
		],
		data: [],
		title: '',
		totalCount: 0,
		searchText: '',
		page: 0,
		rowsPerPage: 30
	})

	useEffect(() => {
		getUsers(0, '')
	}, [])

	const changePage = (page: number): void => {
		setState({ ...state, page })
		getUsers(page, state.searchText, false, state.rowsPerPage)
	}

	const changeSearchText = (text: string): void => {
		setState({ ...state, searchText: text })
		getUsers(0, text, false, state.rowsPerPage)
	}

	const getUsers = useDebounce(async (page = 0, searchText: string, clearSearch = false, rowsPerPage = 30) => {
		try {
			const responseData = await APIRequest.Logs.getRegistrationsData({
				start: page * rowsPerPage, // Start Row
				length: rowsPerPage, // Rows per page
				searchText: clearSearch ? '' : searchText // Search Text
			})

			setState({
				...state,
				data: responseData.data,
				totalCount: responseData.totalCount
			})
		} catch (err) {
			Logger.error(err)
		}
	}, 300)

	return (
		<Document title="LYNX Registrations" description="LYNX Registrations" keywords="Registrations" auth={true} disableOverflow={true}>
			<Card>
				<Table
					pageSizes={[state.rowsPerPage]}
					onPageChange={changePage}
					isCustomSearchRender
					totalCount={state.totalCount}
					onTableSearchChange={changeSearchText}
					title={'LYNX Registrations'}
					headers={state.columns}
					items={state.data}
				/>
			</Card>
		</Document>
	)
}
