import { APIRequest } from '@lynx/client-core/src/api'
import { Plan } from '@lynx/client-core/src/api/Billing/Billing'
import { CancelFilledIcon, Checkbox, InvoiceIcon, SaveIcon, SettingsIcon, Table } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { showModal } from '@lynx/client-core/src/store/modal'
import { hasUserPermission } from '@lynx/core'
import { AdminPermission, SubscriptionsAdminWritePermission, userPermissions, userPermissionsData } from '@lynx/core/src/constants'
import { formatDateString } from '@lynx/core/src/date'
import { AccountProfile } from '@lynx/core/src/interfaces'
import { PlanFreeIcon, PlanProIcon } from 'components/PlanIcons/PlanIcons'
import { Card } from 'components/elmstone/Card'
import { useDataWithPaginationAndSearch } from 'hooks/useDataWithPaginationAndSearch'
import {
	CreateSubscriptionButton,
	NewSubscriptionModal,
	NoStripeAccountModal,
	SubscriptionsOverlay,
	useChangeInvoiceStatus
} from 'pages/Admin/routes/elmstone/Subscriptions'
import { OptionsContainer, getTableSearchParam, renderStorageElement, useSubscriptionPlan } from 'pages/Admin/routes/elmstone/Subscriptions/ListShared'
import { SendInvoiceIcon } from 'pages/Admin/routes/elmstone/Subscriptions/shared/SendInvoiceIcon'
import { InvoiceStatus, PaymentDue, getEditColumnData } from 'pages/Admin/routes/elmstone/Subscriptions/shared/tableColumns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import css from './UsersList.module.scss'
import { UserPermissionsModal } from 'pages/Admin/routes/elmstone/Subscriptions/UserPermissions/UserPermissionsModal'

interface Props {
	plans: Plan[]
}

export const UsersList = ({ plans }: Props): React.ReactElement => {
	const { getPlanStorageLimit } = useSubscriptionPlan(plans, 'pro')
	const [isNewSubscriptionModalOpen, setIsNewSubscriptionModalOpen] = useState(false)
	const [editedStorage, setEditedStorage] = useState<number | null>(null)
	const dispatch = useDispatch()
	const [selectedUserPermissionsId, setSelectedUserPermissionsId] = useState<string | null>(null)
	const [sendingInvoiceId, setSendingInvoiceId] = useState<string | null>(null)
	const {
		profile: { permissions: profileUserPermissions },
		config
	} = useSelector((state: ApplicationState) => state)
	const [isProvidersHeaderVisible, setIsProvidersHeaderVisible] = useState<boolean>(false)
	const isAdmin = hasUserPermission(AdminPermission, profileUserPermissions)
	const fetchData = async ({
		start,
		length,
		searchText
	}: {
		start: number
		length: number
		searchText: string
	}): Promise<{ data: AccountProfile[]; totalCount: number }> => {
		const res = await APIRequest.User.getAll({ start, length, searchText })
		if (!res) {
			return { data: [], totalCount: 0 }
		}
		return { data: res.data.accounts, totalCount: res.data.totalCount }
	}

	const {
		state: { data, totalCount, rowsPerPage, page },
		isLoading,
		setIsLoading,
		editableRowId,
		setEditableRowId,
		getData,
		changeSearchText,
		changePage
	} = useDataWithPaginationAndSearch<AccountProfile>({ fetchData, initialPageSize: 30 })

	const selectedUserEditPermissions = data.find((item) => item.userId === selectedUserPermissionsId) || null
	const handleTogglePro = async (item: AccountProfile): Promise<void> => {
		let updated = false
		if (item.isPro) {
			updated = await APIRequest.User.removeProSubscription(item.userId)
		} else {
			updated = await APIRequest.User.addProSubscription(item.userId)
		}
		updated && getData(page, getTableSearchParam() || '', rowsPerPage)
	}

	const handleViewStripeSubscription = async (item: AccountProfile): Promise<void> => {
		const stripeAccount = await APIRequest.Billing.getAccountUrl('user', item.userId)
		if (stripeAccount?.url) {
			window.open(stripeAccount.url, '_blank')
			return
		}

		dispatch(showModal({ name: 'NoStripeAccountModal' }))
	}

	const updateUserStorageLimit = async ({ userId, storageLimitGB: previousStorageLimit }: AccountProfile): Promise<void> => {
		if (editableRowId && editedStorage) {
			try {
				setIsLoading(true)
				await APIRequest.User.updateUserStorageLimit(userId, editedStorage, previousStorageLimit)
				setEditableRowId('')
				getData(page, getTableSearchParam(), rowsPerPage)
			} finally {
				setIsLoading(false)
			}
		}
	}

	const { getDropdownEl, changeInvoiceStatusModal } = useChangeInvoiceStatus({
		onInvoiceStatusChanged: (): void => getData(page, getTableSearchParam(), rowsPerPage)
	})

	const onEditRowClicked = (item: AccountProfile): void => {
		setEditableRowId(editableRowId !== item.userId ? item.userId : '')
		setEditedStorage(item.storageLimitGB || getPlanStorageLimit(item.isPro))
	}

	const onSendInvoiceClicked = async (item: AccountProfile): Promise<void> => {
		try {
			setSendingInvoiceId(item.userId)
			await sendInvoice(item)
			getData(page, getTableSearchParam(), rowsPerPage)
		} finally {
			setSendingInvoiceId(null)
		}
	}

	const sendInvoice = async ({ userId }: AccountProfile): Promise<void> => {
		await APIRequest.Billing.sendInvoice({ userId })
	}

	const permissionColumn = isAdmin
		? [
				{
					valueKey: 'permissions',
					label: 'Permissions',
					customBodyRender: (item: AccountProfile): React.ReactNode => {
						const userPermissionLabels = userPermissions
							.map((perm) => (item.permissions[perm] ? userPermissionsData[perm].label : ''))
							.filter(Boolean)
						return userPermissionLabels.length ? userPermissionLabels.join(', ') : ' '
					}
				}
		  ]
		: []

	const subscriptionColumns = [
		{
			valueKey: 'paymentMethodType',
			label: 'Payment Source',
			customBodyRender: (item: AccountProfile) => <div>{item.subscriptionData?.paymentMethodType || ''}</div>
		},

		{
			label: 'Payment Due',
			valueKey: 'paymentDue',
			customBodyRender: (item: AccountProfile) => <PaymentDue item={item} />
		},
		{
			valueKey: 'invoiceStatus',
			label: 'Invoice Status',
			customBodyRender: (item: AccountProfile) => <InvoiceStatus item={item} />
		}
	]

	const columns: TableHeaders = [
		{ valueKey: 'email', label: 'Email' },
		...(isProvidersHeaderVisible ? [{ valueKey: 'providers', label: 'Auth Providers' }] : []),
		{
			valueKey: 'lastUpdated',
			label: 'Last Updated',
			customBodyRender: (item: AccountProfile): React.ReactNode => formatDateString(new Date(item.lastUpdated), 'DD/MM/YYYY')
		},
		...permissionColumn,
		{
			valueKey: 'isPro',
			label: 'Plan',
			customBodyRender: (item: AccountProfile): React.ReactNode => (item.isPro ? <PlanProIcon /> : <PlanFreeIcon />)
		},
		{
			valueKey: 'storageLimitGB',
			label: 'Storage',
			customBodyRender: (item: AccountProfile) =>
				renderStorageElement({
					id: item.userId,
					storageLimitGB: item.storageLimitGB,
					editableRowId,
					setEditedStorage,
					isLoading,
					planStorageLimit: getPlanStorageLimit(item.isPro),
					editedStorage
				})
		},
		...(hasUserPermission(SubscriptionsAdminWritePermission, profileUserPermissions) ? subscriptionColumns : []),
		{
			valueKey: '__edit',
			label: '',
			customBodyRender: (item: AccountProfile): React.ReactElement => {
				const { storageLimitGB, userId } = item
				const { canMarkAsPaid, canMarkAsUncollectible, canMarkAsVoid, canSendInvoice, invoiceUrl, customerId, invoiceId } = getEditColumnData(
					item,
					config.ENVIRONMENT
				)
				const permissionsCountTrue = Object.values(item.permissions).filter(Boolean).length
				const isStorageLimitModified = (storageLimitGB || getPlanStorageLimit(item.isPro)) !== editedStorage

				return (
					<OptionsContainer>
						{canSendInvoice && (
							<SendInvoiceIcon
								item={item}
								hideText
								onSendInvoiceClicked={(): Promise<void> => onSendInvoiceClicked(item)}
								sendingInvoiceId={sendingInvoiceId}
							/>
						)}
						{editableRowId === userId ? (
							<>
								<CancelFilledIcon onClick={(): boolean | void => !isLoading && setEditableRowId('')} />
								{isStorageLimitModified && <SaveIcon onClick={(): boolean | Promise<void> => !isLoading && updateUserStorageLimit(item)} />}
								{invoiceId && (
									<a
										href={invoiceUrl}
										target="_blank"
										title="See invoice"
										onClick={(): Window | null => window.open(invoiceUrl, '_blank')}
										rel="noreferrer"
									>
										<InvoiceIcon />
									</a>
								)}
							</>
						) : (
							<SubscriptionsOverlay>
								<ul>
									{editableRowId !== item.userId &&
										item.isPro &&
										hasUserPermission(SubscriptionsAdminWritePermission, profileUserPermissions) && (
											<li
												onClick={(): void => {
													onEditRowClicked(item)
												}}
											>
												Edit
											</li>
										)}
									{hasUserPermission(SubscriptionsAdminWritePermission, profileUserPermissions) && (
										<li
											onClick={(): void => {
												handleTogglePro(item)
											}}
										>
											Toggle Pro Subscription
										</li>
									)}
									{customerId && (
										<li
											onClick={(): void => {
												handleViewStripeSubscription(item)
											}}
										>
											View Stripe Account
										</li>
									)}
									{canSendInvoice && (
										<li>
											<SendInvoiceIcon
												item={item}
												onSendInvoiceClicked={(): Promise<void> => onSendInvoiceClicked(item)}
												sendingInvoiceId={sendingInvoiceId}
											/>
										</li>
									)}
									{canMarkAsPaid && invoiceId && getDropdownEl('paid', invoiceId, 0, item.userId)}
									{canMarkAsUncollectible && invoiceId && getDropdownEl('uncollectible', invoiceId, 0, item.userId)}
									{canMarkAsVoid && invoiceId && getDropdownEl('void', invoiceId, 0, item.userId)}
									{isAdmin && (
										<li
											onClick={(): void => {
												setSelectedUserPermissionsId(item.userId)
											}}
										>
											<SettingsIcon /> Permissions edit {permissionsCountTrue ? `(${permissionsCountTrue})` : ''}
										</li>
									)}
								</ul>
							</SubscriptionsOverlay>
						)}
					</OptionsContainer>
				)
			}
		}
	]

	const title = <CreateSubscriptionButton title="Users" setIsNewSubscriptionModalOpen={(): void => setIsNewSubscriptionModalOpen(true)} />

	return (
		<>
			<Card>
				<div className={css.headersSwitchContainer}>
					<div className={css.title}>Headers</div>
					<div className={css.header}>
						<div>Auth Providers</div>
						<Checkbox checked={isProvidersHeaderVisible} onChange={(): void => setIsProvidersHeaderVisible((prev) => !prev)} />
					</div>
				</div>
				<Table
					isSearchVisible
					pageSizes={[rowsPerPage]}
					onPageChange={changePage}
					totalCount={totalCount}
					onTableSearchChange={changeSearchText}
					title={title}
					headers={columns}
					items={data}
					isCustomSearchRender={true}
					onRefetch={(): void => getData(page, getTableSearchParam() || '', rowsPerPage)}
				/>
			</Card>
			{isNewSubscriptionModalOpen && <NewSubscriptionModal type="user" closeModal={(): void => setIsNewSubscriptionModalOpen(false)} />}
			{changeInvoiceStatusModal}
			<NoStripeAccountModal />
			{selectedUserEditPermissions && (
				<UserPermissionsModal
					onPermissionUpdated={async (): Promise<void> => getData(page, getTableSearchParam() || '', rowsPerPage)}
					onModalClose={(): void => setSelectedUserPermissionsId(null)}
					selectedUserPermissionsEdit={selectedUserEditPermissions}
				/>
			)}
		</>
	)
}
