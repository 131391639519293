import {
	AccountIcon,
	LanguageMenu,
	OrganisationsDropdownIcon,
	ProfileDropdown,
	SignInIcon,
	SignOutEverywhereIcon,
	SignOutIcon
} from '@lynx/client-core/src/components'
import { NavigationBar, NavigationItemProps } from '@lynx/client-core/src/components/NavigationBar'
import { isLynx } from '@lynx/client-core/src/utils'
import { NotificationMenuItem } from 'components/Notifications'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ApplicationState } from 'store'
import { setLoginModal } from 'store/profile'
import { Logo } from './components/Logo'
import css from './Header.module.scss'
import { useBillingConfiguration } from 'hooks'
import { hasUserPermission } from '@lynx/core'
import {
	OrganisationActivitiesPermission,
	OrganisationDrivePermission,
	ReportsPermission,
	SaharaPermission,
	SubscriptionsAdminReadPermission,
	SubscriptionsAdminWritePermission,
	TranslationPermission
} from '@lynx/core/src/constants'

export const Header = (): React.ReactElement | null => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isLoading, isBillingTrialPeriodForced } = useBillingConfiguration()
	const {
		config,
		profile: { loggedIn, displayName, email, permissions }
	} = useSelector((state: ApplicationState) => state)

	const handleLogoClicked = (): void => {
		navigate('/')
	}

	if (isLynx) {
		return null
	}

	const navItems: NavigationItemProps[] = [
		{
			to: '/admin',
			children: t('components.header.admin'),
			accentColor: '#F0C000',
			visible:
				loggedIn &&
				hasUserPermission(
					[TranslationPermission, SaharaPermission, ReportsPermission, SubscriptionsAdminWritePermission, SubscriptionsAdminReadPermission],
					permissions
				)
		},
		{
			to: '/more-downloads',
			children: t('components.header.download'),
			accentColor: '#DD5499',
			visible: true
		},
		{
			to: '/drive',
			children: t('components.header.drives'),
			accentColor: '#AF66FF',
			visible: loggedIn,
			permissions: OrganisationDrivePermission
		},
		{
			to: config.LYNXACTIVITIES_CLIENT,
			children: t('components.header.activities'),
			accentColor: '#55DCFF',
			visible: true,
			permissions: OrganisationActivitiesPermission
		},
		{
			to: '/plans',
			children: t('components.header.pricing'),
			accentColor: '#1CD69F',
			visible: !isLoading && !isBillingTrialPeriodForced
		},
		{
			to: '/support',
			children: t('components.header.support'),
			accentColor: '#1CD69F',
			visible: true
		}
	]

	const handleMyAccountClicked = (): void => {
		navigate('/profile')
	}

	const handleOrganisationsClicked = (): void => {
		navigate('/organisations')
	}

	const handleSignOutClicked = (): void => {
		navigate('/auth/logout')
	}

	const handleSignOutEverywhereClicked = (): void => {
		navigate('/auth/logout/everywhere')
	}

	const handleSignInClicked = (): void => {
		dispatch(setLoginModal(true, false))
	}

	const getDisplayName = (): string => {
		return displayName.includes('@') ? displayName.split('@')[0] : displayName
	}

	const profileItems: { icon: React.ReactElement; text: string; action: () => void; visible: boolean }[] = [
		{ icon: <AccountIcon />, text: t('components.profile.myAccount'), action: handleMyAccountClicked, visible: loggedIn },
		{
			icon: <OrganisationsDropdownIcon className={css.organisationIcon} />,
			text: t('components.profile.organisations'),
			action: handleOrganisationsClicked,
			visible: loggedIn
		},
		{ icon: <SignOutIcon />, text: t('components.profile.signOut'), action: handleSignOutClicked, visible: loggedIn },
		{ icon: <SignOutEverywhereIcon />, text: t('components.profile.signOutEverywhere'), action: handleSignOutEverywhereClicked, visible: loggedIn },
		{ icon: <SignInIcon />, text: t('components.profile.signIn'), action: handleSignInClicked, visible: !loggedIn }
	]

	return (
		<div className={[css['container'], ''].join(' ')}>
			<div className={css['container__left']}>
				<Logo onClick={handleLogoClicked} />
				<NavigationBar items={navItems} />
			</div>
			<div className={css['container__profile']}>
				<LanguageMenu />
				{loggedIn && <NotificationMenuItem />}
				<div className={css['container__profile__line']} />
				<ProfileDropdown navItems={navItems} name={getDisplayName()} email={email} items={profileItems} />
			</div>
		</div>
	)
}
