import { APIRequest } from '@lynx/client-core/src/api'
import { Plan } from '@lynx/client-core/src/api/Billing/Billing'
import { Badge, CancelFilledIcon, EditIcon, InvoiceIcon, LoadingSpinner, SaveIcon, SendIcon, Table } from '@lynx/client-core/src/components'
import { TableHeaders } from '@lynx/client-core/src/components/Table/interfaces'
import { showModal } from '@lynx/client-core/src/store/modal'
import { hasUserPermission } from '@lynx/core'
import { SubscriptionsAdminWritePermission } from '@lynx/core/src/constants'
import { SearchOrganisation } from '@lynx/core/src/interfaces/Organisation'
import { PlanFreeIcon, PlanOrgIcon } from 'components/PlanIcons/PlanIcons'
import { Card } from 'components/elmstone/Card'
import { useDataWithPaginationAndSearch } from 'hooks/useDataWithPaginationAndSearch'
import { CreateSubscriptionButton } from 'pages/Admin/routes/elmstone/Subscriptions/CreateSubscription/CreateSubscriptionButton/CreateSubscriptionButton'
import { OptionsContainer, getTableSearchParam, renderStorageElement, useSubscriptionPlan } from 'pages/Admin/routes/elmstone/Subscriptions/ListShared'
import { SubscriptionsOverlay } from 'pages/Admin/routes/elmstone/Subscriptions/SubscriptionsOverlay'
import { SendInvoiceIcon } from 'pages/Admin/routes/elmstone/Subscriptions/shared/SendInvoiceIcon'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { InvoiceStatus, PaymentDue, getEditColumnData } from 'pages/Admin/routes/elmstone/Subscriptions/shared/tableColumns'
import css from './OrganisationList.module.scss'
import { useChangeInvoiceStatus } from 'pages/Admin/routes/elmstone/Subscriptions/CreateSubscription/ChangeInvoiceStatus/ChangeInvoiceStatus'
import { NewSubscriptionModal } from 'pages/Admin/routes/elmstone/Subscriptions/CreateSubscription/NewSubscriptionModal'
import { OrganisationUserLicensesCol } from './OrganisationUserLicensesCol'
import { OrganisationEntryModal, OrganisationEntryModalContextType } from './modals'
interface Props {
	plans: Plan[]
}

export const OrganisationList = ({ plans }: Props): React.ReactElement => {
	const [editedStorage, setEditedStorage] = useState<number | null>(null)
	const [editedLicenses, setEditedLicenses] = useState<number | null>(null)
	const [isNewSubscriptionModalOpen, setIsNewSubscriptionModalOpen] = useState(false)
	const { getPlanStorageLimit } = useSubscriptionPlan(plans, 'organisation')
	const [sendingInvoiceId, setSendingInvoiceId] = useState<number | null>(null)
	const { profile, config } = useSelector((state: ApplicationState) => state)

	const dispatch = useDispatch()
	const fetchData = async ({
		start,
		length,
		searchText
	}: {
		start: number
		length: number
		searchText: string
	}): Promise<{ data: SearchOrganisation[]; totalCount: number }> => {
		const response = await APIRequest.Organisations.searchOrganisation({ start, length, searchText })
		return { data: response.organisations, totalCount: response.totalCount }
	}

	const {
		state: { data, totalCount, rowsPerPage, page },
		isLoading,
		setIsLoading,
		editableRowId,
		setEditableRowId,
		getData,
		changeSearchText,
		changePage
	} = useDataWithPaginationAndSearch<SearchOrganisation>({ fetchData, initialPageSize: 15 })

	const updateUserStorageLimit = async (item: SearchOrganisation, isStorageLimitModified: boolean, isUserLicensesModified: boolean): Promise<void> => {
		if (editableRowId) {
			try {
				setIsLoading(true)
				isStorageLimitModified && editedStorage && (await APIRequest.Organisations.updateUserStorageLimit(item.id, editedStorage, item.storageLimitGB))
				isUserLicensesModified && editedLicenses && (await APIRequest.Organisations.updateUserLicensesLimit(item.id, editedLicenses))
				setEditableRowId(null)
				setEditedStorage(null)
				setEditedLicenses(null)
				getData(page, getTableSearchParam(), rowsPerPage)
			} finally {
				setIsLoading(false)
			}
		}
	}

	const onEditRowClicked = (item: SearchOrganisation): void => {
		setEditableRowId(editableRowId !== item.id ? item.id : null)
		setEditedStorage(item.storageLimitGB ?? getPlanStorageLimit(item.isPro))
	}

	const handleRowClicked = (item: SearchOrganisation): void => {
		dispatch(showModal({ name: 'OrganisationEntryModal', context: { type: OrganisationEntryModalContextType, id: item.id } }))
	}

	const handleViewStripeSubscription = async (item: SearchOrganisation): Promise<void> => {
		const stripeAccount = await APIRequest.Billing.getAccountUrl('organisation', item.id)
		if (stripeAccount?.url) {
			window.open(stripeAccount.url, '_blank')
			return
		}

		dispatch(showModal({ name: 'NoStripeAccountModal' }))
	}

	const onSendInvoiceClicked = async (item: SearchOrganisation): Promise<void> => {
		try {
			setSendingInvoiceId(item.id)
			await sendInvoice(item)
			getData(page, getTableSearchParam(), rowsPerPage)
		} finally {
			setSendingInvoiceId(null)
		}
	}

	const { getDropdownEl, changeInvoiceStatusModal } = useChangeInvoiceStatus({
		onInvoiceStatusChanged: (): void => getData(page, getTableSearchParam(), rowsPerPage)
	})

	const sendInvoice = async (item: SearchOrganisation): Promise<void> => {
		await APIRequest.Billing.sendInvoice({ organisationId: item.id })
	}

	const subscriptionColumns = [
		{
			valueKey: 'paymentMethodType',
			label: 'Payment Source',
			customBodyRender: (item: SearchOrganisation) => <div>{item.subscriptionData?.paymentMethodType || ''}</div>
		},
		{
			valueKey: 'userLicensesLimit',
			label: 'User Licenses',
			customBodyRender: (item: SearchOrganisation): React.ReactElement => {
				if (!item.isPro) return <></>
				const { id, userLicensesLimit, usersCount, subscriptionData } = item
				const isEditable = editableRowId === id
				if (isEditable)
					return (
						<OrganisationUserLicensesCol
							orgItem={item}
							isLoading={isLoading}
							editedLicenses={editedLicenses}
							setEditedLicenses={setEditedLicenses}
						/>
					)
				return (
					<div>
						{usersCount} /{' '}
						{userLicensesLimit === null ? (
							Number(subscriptionData?.usersProQuantity)
						) : (
							<span className={css.userLicensesLimitCustomValue}>{userLicensesLimit}</span>
						)}
					</div>
				)
			}
		},
		{
			label: 'Payment Due',
			valueKey: 'paymentDue',
			customBodyRender: (item: SearchOrganisation) => <PaymentDue item={item} />
		},
		{
			valueKey: 'invoiceStatus',
			label: 'Invoice Status',
			customBodyRender: (item: SearchOrganisation) => <InvoiceStatus item={item} />
		}
	]

	const columns: TableHeaders = [
		{
			valueKey: 'id',
			label: 'Id',
			customBodyRender: (item: SearchOrganisation) => (
				<div className={css.idColumnContainer}>
					<div>{item.id}</div>
					{item.subscriptionData?.isReseller && (
						<Badge variant="teal" size="xs" className={css.badge}>
							RESELLER
						</Badge>
					)}
				</div>
			)
		},
		{ valueKey: 'organisationName', label: 'Name' },
		{
			valueKey: 'isPro',
			label: 'Plan',
			customBodyRender: (item: SearchOrganisation) => (item.isPro ? <PlanOrgIcon /> : <PlanFreeIcon />)
		},
		{
			valueKey: 'storageLimitGB',
			label: 'Storage',
			customBodyRender: (item: SearchOrganisation) =>
				renderStorageElement({
					id: item.id,
					storageLimitGB: item.storageLimitGB,
					editableRowId,
					setEditedStorage,
					isLoading,
					planStorageLimit: getPlanStorageLimit(item.isPro),
					editedStorage
				})
		},
		...(hasUserPermission(SubscriptionsAdminWritePermission, profile.permissions) ? subscriptionColumns : []),
		{
			valueKey: '__edit',
			label: '',
			customBodyRender: (item: SearchOrganisation): React.ReactElement => {
				const { id } = item
				const isStorageLimitModified = (item.storageLimitGB || getPlanStorageLimit(item.isPro)) !== editedStorage

				const isUserLicensesModifiedSameAsStripe = item.userLicensesLimit && item.subscriptionData?.usersProQuantity === editedLicenses // if yes then it will set to db null and use by default stripe value
				const isUserLicensesModified = Boolean(
					(item.userLicensesLimit || item.subscriptionData?.usersProQuantity) !== editedLicenses || isUserLicensesModifiedSameAsStripe
				)
				const { canMarkAsPaid, canMarkAsUncollectible, canMarkAsVoid, canSendInvoice, invoiceUrl, customerId, invoiceId } = getEditColumnData(
					item,
					config.ENVIRONMENT
				)

				const isDropdownVisible = canSendInvoice || customerId
				return (
					<OptionsContainer>
						{canSendInvoice && (
							<SendInvoiceIcon
								hideText
								item={item}
								onSendInvoiceClicked={(): Promise<void> => onSendInvoiceClicked(item)}
								sendingInvoiceId={sendingInvoiceId}
							/>
						)}
						{editableRowId !== item.id && Boolean(item.isPro) && hasUserPermission(SubscriptionsAdminWritePermission, profile.permissions) && (
							<EditIcon onClick={(): void => onEditRowClicked(item)} title="Edit Storage or Licenses" />
						)}
						{editableRowId === item.id && <CancelFilledIcon onClick={(): boolean | void => !isLoading && setEditableRowId('')} />}
						{editableRowId === item.id && (isStorageLimitModified || isUserLicensesModified) && (
							<SaveIcon
								title="Save"
								onClick={(): boolean | Promise<void> =>
									!isLoading && updateUserStorageLimit(item, isStorageLimitModified, isUserLicensesModified)
								}
							/>
						)}
						{invoiceId && (
							<a
								href={invoiceUrl}
								target="_blank"
								title="See invoice"
								onClick={(): Window | null => window.open(invoiceUrl, '_blank')}
								rel="noreferrer"
							>
								<InvoiceIcon />
							</a>
						)}

						{isDropdownVisible && (
							<SubscriptionsOverlay>
								<ul>
									{customerId && (
										<li
											onClick={(): void => {
												handleViewStripeSubscription(item)
											}}
										>
											View Stripe Account
										</li>
									)}
									{canSendInvoice && (
										<li>
											<SendInvoiceIcon
												item={item}
												onSendInvoiceClicked={(): Promise<void> => onSendInvoiceClicked(item)}
												sendingInvoiceId={sendingInvoiceId}
											/>
										</li>
									)}
									{canMarkAsPaid && invoiceId && getDropdownEl('paid', invoiceId, id, '')}
									{canMarkAsUncollectible && invoiceId && getDropdownEl('uncollectible', invoiceId, id, '')}
									{canMarkAsVoid && invoiceId && getDropdownEl('void', invoiceId, id, '')}
								</ul>
							</SubscriptionsOverlay>
						)}
					</OptionsContainer>
				)
			}
		}
	]

	const title = <CreateSubscriptionButton title="Organisations" setIsNewSubscriptionModalOpen={(): void => setIsNewSubscriptionModalOpen(true)} />

	return (
		<>
			<Card>
				<Table
					isSearchVisible
					pageSizes={[rowsPerPage]}
					onPageChange={changePage}
					totalCount={totalCount}
					onTableSearchChange={changeSearchText}
					title={title}
					headers={columns}
					items={data}
					isCustomSearchRender={true}
					onRowClick={handleRowClicked}
				/>
			</Card>
			{isNewSubscriptionModalOpen && <NewSubscriptionModal type="organisation" closeModal={(): void => setIsNewSubscriptionModalOpen(false)} />}
			<OrganisationEntryModal />
			{changeInvoiceStatusModal}
		</>
	)
}
