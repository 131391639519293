import { Cookie, Logger } from '@lynx/client-core/src/modules'
import { deleteQueryParam } from '@lynx/client-core/src/utils'
import * as Sentry from '@sentry/react'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import i18next from 'i18next'
import * as qs from 'query-string'
import React, { Suspense, useEffect, useState } from 'react'
import { initReactI18next } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { ApplicationState } from 'store'
import { readConfig } from 'store/config'
import { login, loginCookie, loginWithTokenId } from 'store/profile'
import { router } from './App.router'
import { useFetchAvatar } from '@lynx/client-core/src/hooks/useFetchAvatar'
import Backend from 'i18next-http-backend'
import { addAnalytics } from 'modules/utils'
import { LoadingSpinner, OrganisationPermissionModal } from '@lynx/client-core/src/components'
import { lastTranslationChangeTimestamp } from '@lynx/core/src/services/translations/translationCacheTimestamp'

export const App = (): React.ReactElement | null => {
	const { profile, config } = useSelector((state: ApplicationState) => state)
	const [configured, setConfigured] = useState(false)
	const dispatchThunk = useThunkDispatch()
	const { authKey } = qs.parse(location.search) as { authKey: string }
	const [languageInitialized, setLanguageInitialised] = useState<boolean>(false)

	useFetchAvatar()
	const getToken = async (): Promise<string> => {
		if (authKey) {
			try {
				const token = await dispatchThunk(loginWithTokenId(authKey))
				deleteQueryParam('authKey')
				return token
			} catch (error) {
				Logger.error(error, { userId: profile.userId, authKey })
				Sentry.captureException(error)
				window.location.href = '/error?message=' + i18next.t('pages.accessDenied.couldNotAuth')
				return ''
			}
		} else return dispatchThunk(loginCookie())
	}

	useEffect(() => {
		const mount = async (): Promise<void> => {
			const consent = Cookie.get('lynxconsent') as 'necessary' | 'all'

			if (consent === 'all' || profile.loggedIn) {
				addAnalytics()
			}

			const config = await dispatchThunk(readConfig())
			if (!config) {
				Logger.error('Config not found')
				return
			}

			const token = await getToken()
			if (!token) dispatchThunk(login(null))

			if (!languageInitialized) {
				i18next
					.use(Backend)
					.use(initReactI18next)
					.init({
						fallbackLng: 'en',
						debug: false,
						interpolation: {
							escapeValue: false
						},
						lng: 'en',
						returnNull: false,
						backend: {
							loadPath: `${config.LYNXCLOUD_CLIENT}/locales/{{lng}}/{{ns}}.json?v=${lastTranslationChangeTimestamp}`
						}
					})
			}

			setConfigured(Boolean(config))
		}
		mount()
	}, [])

	useEffect(() => {
		if (!config.SENTRY_DSN || Sentry.getCurrentHub().getClient()) return

		if (process.env.NODE_ENV === 'production') {
			Sentry.init({
				dsn: config.SENTRY_DSN,
				integrations: [new Sentry.BrowserTracing({ tracePropagationTargets: ['*'] }), new Sentry.Replay({ maskAllText: false, blockAllMedia: false })],
				tracesSampleRate: 0.5,
				environment: config.ENVIRONMENT,
				replaysSessionSampleRate: 0.1,
				replaysOnErrorSampleRate: 1.0,
				beforeSend(event) {
					const eventId = Sentry.lastEventId()
					if (event.exception) {
						Sentry.showReportDialog({ eventId })
					}
					return event
				}
			})
		}
	}, [config])

	useEffect(() => {
		if (i18next.isInitialized) {
			setLanguageInitialised(true)
			profile.lang && i18next.changeLanguage(profile.lang)
		}
	}, [i18next.isInitialized, profile.lang])

	useEffect(() => {
		Sentry.setUser(profile.loggedIn ? profile : null)
	}, [profile.loggedIn])

	if (!configured) {
		return null
	}

	return (
		<React.Fragment>
			<Suspense fallback={<LoadingSpinner />}>
				<RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
			</Suspense>
			<OrganisationPermissionModal />
		</React.Fragment>
	)
}
