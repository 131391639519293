import React, { useEffect, useState } from 'react'
import css from './Tabs.module.scss'
import { deleteQueryParam, updateQueryParam } from '../../utils'
import QueryString from 'query-string'
export const Tabs = ({
	tabs,
	selectedTab,
	setSelectedTab
}: {
	tabs: {
		label: string | React.ReactElement
		key: string
		disabled?: boolean
	}[]
	selectedTab: string
	setSelectedTab: (tab: string) => void
}): React.ReactElement | null => {
	const [initialTab] = useState<string>(selectedTab)
	const isChanged = initialTab !== selectedTab
	const [isInitialMount, setIsInitialMount] = useState<boolean>(true)

	useEffect(() => {
		const query = QueryString.parse(window.location.search.substring(1)) as { [key: string]: string }
		if (isInitialMount) {
			if (query.tab) setSelectedTab(query.tab)
			setIsInitialMount(false)
			return
		}

		if (isChanged) updateQueryParam({ ...query, tab: selectedTab })
		else deleteQueryParam('tab')
	}, [isChanged])

	return (
		<div className={css.tabs}>
			{tabs.map((tab) => (
				<div
					key={tab.key}
					className={`${css.tab} ${selectedTab === tab.key ? css.active : ''}`}
					onClick={(): void | boolean => !tab.disabled && setSelectedTab(tab.key)}
				>
					<span>{tab.label}</span>
				</div>
			))}
		</div>
	)
}

export default Tabs
