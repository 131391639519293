import { APIRequest } from '@lynx/client-core/src/api'
import { BasicButton } from '@lynx/client-core/src/components'
import ModalBase from '@lynx/client-core/src/components/Modal/Modal'
import { useState } from 'react'
import css from './OrganisationSubscriptionNewPrice.module.scss'
import { CreateFlatPricePayload, CreateTieredPricePayload, FlatPriceOption, PricingSearch, TierPrice } from '@lynx/core/src/interfaces'
import { SubscriptionCreatePriceUnit } from './SubscriptionCreatePriceUnit'
import { SubscriptionCreatePriceTiered } from './SubscriptionCreatePriceTiered'

interface Props {
	onClose: () => void
	onPriceCreated: (newPriceId: string, updatedPrices: PricingSearch['prices']) => Promise<void>
	productType: 'organisation' | 'user'
}

export const OrganisationSubscriptionNewPrice = ({ onClose, onPriceCreated, productType }: Props): React.ReactElement => {
	const [priceType, setPriceType] = useState<'per_unit' | 'tiered'>(productType === 'user' ? 'per_unit' : 'tiered')
	const [mainCurrency, setMainCurrency] = useState('gbp')
	const [isLoading, setIsLoading] = useState(false)
	const [flatPriceObject, setFlatPriceObject] = useState<FlatPriceOption[]>(initialFlat)
	const [tieredPriceObject, setTieredPriceObject] = useState<TierPrice['tiersData']>([initialTier])

	const getPerUnitPricePayload = (): CreateFlatPricePayload => {
		return {
			type: 'per_unit',
			mainCurrency: mainCurrency,
			currencyOptions: flatPriceObject
		}
	}

	const getTieredPricePayload = (): CreateTieredPricePayload => {
		return {
			type: 'tiered',
			mainCurrency: mainCurrency,
			tiersData: tieredPriceObject
		}
	}

	const onPriceCreate = async (): Promise<void> => {
		try {
			const payload = priceType === 'per_unit' ? getPerUnitPricePayload() : getTieredPricePayload()
			setIsLoading(true)
			const { newPriceId, updatedPrices } = await APIRequest.Billing.createStripePrice({ ...payload, productType })
			await onPriceCreated(newPriceId, updatedPrices)
		} catch (e) {
			// do nothing
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div>
			<ModalBase name="New Price" title="New Price" uncontrolled onHidden={(): void => onClose()} innerContainerClasses={css.modalInnerContainer}>
				<div className={css.container}>
					<div className={css.priceTypeContainer}>
						<BasicButton onClick={(): void => setPriceType('per_unit')} variant={priceType === 'per_unit' ? 'blue' : 'grey'}>
							Flat
						</BasicButton>
						<BasicButton
							onClick={(): void => setPriceType('tiered')}
							disabled={productType === 'user'}
							variant={priceType === 'tiered' ? 'blue' : 'grey'}
						>
							Tiered
						</BasicButton>
					</div>
					{priceType === 'tiered' && (
						<SubscriptionCreatePriceTiered
							mainCurrency={mainCurrency}
							setMainCurrency={setMainCurrency}
							tieredPriceObject={tieredPriceObject}
							setTieredPriceObject={setTieredPriceObject}
						/>
					)}
					{priceType === 'per_unit' && (
						<SubscriptionCreatePriceUnit
							mainCurrency={mainCurrency}
							setMainCurrency={setMainCurrency}
							flatPriceObject={flatPriceObject}
							setFlatPriceObject={setFlatPriceObject}
						/>
					)}
					<div className={css.controlBtns}>
						<BasicButton disabled={isLoading} onClick={onClose} className={css.createPriceBtn}>
							Cancel
						</BasicButton>
						<BasicButton onClick={onPriceCreate} variant="blue" className={css.createPriceBtn} isLoading={isLoading}>
							Create Price
						</BasicButton>
					</div>
				</div>
			</ModalBase>
		</div>
	)
}

export const initialTier = {
	tierCurrencyCode: 'gbp',
	tiers: [
		{
			upTo: 1,
			unitAmount: 0,
			flatAmount: 0
		},
		{
			upTo: null,
			unitAmount: 0,
			flatAmount: 0
		}
	]
}

const initialFlat = [
	{
		amountCurrencyCode: 'gbp',
		unitAmount: 0
	}
]
