import { BasicButton, CloudOrganisationIcon, Modal, OrganisationInfo, ProBadgeIcon } from '@lynx/client-core/src/components'
import { hideModal } from '@lynx/client-core/src/store/modal'
import { useThunkDispatch } from 'hooks'
import { useCurrentOrganisation } from 'pages/Organisations/hooks'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import css from './UserLeaveOrganisationModal.module.scss'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { APIRequest } from '@lynx/client-core/src/api'
import { loadOrganisations, setActiveOrganisation } from 'store/organisations'
import { useNavigate } from 'react-router-dom'

export const UserLeaveOrganisationModal = (): React.ReactElement | null => {
	const dispatch = useDispatch()
	const dispatchThunk = useThunkDispatch()
	const { t } = useTranslation()

	const {
		profile: { userId },
		organisations
	} = useSelector((state: ApplicationState) => state)
	const navigate = useNavigate()
	const organisation = useCurrentOrganisation()
	if (!organisation) return null
	const { id, organisationName, countryName, isPro } = organisation
	const handleCancelClicked = (): void => {
		dispatch(hideModal())
	}
	const orgUser = organisation.users.find((user) => user.userId === userId)

	const handleLeaveClicked = async (): Promise<void> => {
		try {
			await APIRequest.Organisations.deleteOrganisationUser(organisation.id, userId)
			const anotherOrg = organisations.organisations.find((org) => org.id !== organisation.id)
			await dispatchThunk(loadOrganisations())
			anotherOrg ? await dispatch(setActiveOrganisation(anotherOrg.id)) : navigate('/organisations')
			dispatch(hideModal())
		} catch (err) {
			// do nothing
		}
	}

	return (
		<Modal name="UserLeaveOrganisation" title={t('pages.organisationLeave.leaveOrganisation')} variant="danger">
			<div className={css['container']}>
				<p>{t('pages.organisationLeave.benefitsIfYouLeave')}</p>
				<OrganisationInfo organisationId={id} organisationCountry={countryName} organisationName={organisationName} isPro={isPro} />
				{organisation.isPro && orgUser?.isAssignedPro && (
					<div className={css.featureItem}>
						<ProBadgeIcon /> {t('pages.organisationLeave.lynxProSub')}
					</div>
				)}
				<div className={css.featureItem}>
					<CloudOrganisationIcon /> {t('pages.organisationLeave.accessDrive')}
				</div>
				<p> {t('pages.organisationLeave.makeSure')}</p>
				<div className={css['buttons']}>
					<BasicButton onClick={handleCancelClicked}>{t('pages.organisations.cancel')}</BasicButton>
					<BasicButton variant="danger" onClick={handleLeaveClicked}>
						{t('pages.organisationLeave.leaveOrganisation')}
					</BasicButton>
				</div>
			</div>
		</Modal>
	)
}
