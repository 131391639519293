import { useState, useEffect } from 'react'
import { useDebounce } from '@lynx/client-core/src/hooks'
import { Logger } from '@lynx/client-core/src/modules'

interface DataWithPaginationAndSearchProps<T> {
	fetchData: (params: { start: number; length: number; searchText: string }) => Promise<{ data: T[]; totalCount: number }>
	initialPageSize?: number
}

interface DataWithPaginationAndSearchState<T> {
	data: T[]
	totalCount: number
	searchText: string
	page: number
	rowsPerPage: number
}

interface DataWithPaginationAndSearchReturn<T> {
	state: DataWithPaginationAndSearchState<T>
	setIsLoading: (isLoading: boolean) => void
	setState: (state: DataWithPaginationAndSearchState<T>) => void
	getData: (pageNumber: number, searchValue: string, perPage?: number) => void
	isLoading: boolean
	editableRowId: string | number | null
	setEditableRowId: (id: number | string | null) => void
	changeSearchText: (text: string) => void
	changePage: (page: number) => void
}

export const useDataWithPaginationAndSearch = <T>({
	fetchData,
	initialPageSize = 30
}: DataWithPaginationAndSearchProps<T>): DataWithPaginationAndSearchReturn<T> => {
	const [state, setState] = useState<DataWithPaginationAndSearchState<T>>({
		data: [],
		totalCount: 0,
		searchText: '',
		page: 1,
		rowsPerPage: initialPageSize
	})
	const [isLoading, setIsLoading] = useState(false)
	const [editableRowId, setEditableRowId] = useState<string | number | null>(null)
	const changeSearchText = (text: string): void => {
		setState({ ...state, searchText: text })
		getData(1, text, state.rowsPerPage)
	}

	const getData = useDebounce(async (pageNumber: number, searchValue: string, perPage = state.rowsPerPage) => {
		try {
			setIsLoading(true)
			const response = await fetchData({ start: pageNumber, length: perPage, searchText: searchValue })
			setState((prevState) => ({
				...prevState,
				page: pageNumber,
				data: response.data,
				totalCount: response.totalCount
			}))
		} catch (err) {
			Logger.error(err)
		} finally {
			setIsLoading(false)
		}
	}, 300)

	const changePage = (page: number): void => {
		getData(page, state.searchText, state.rowsPerPage)
	}

	useEffect(() => {
		getData(state.page, state.searchText)
	}, [])

	return {
		state,
		setIsLoading,
		setState,
		getData,
		isLoading,
		editableRowId,
		setEditableRowId,
		changeSearchText,
		changePage
	}
}
