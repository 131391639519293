import { BasicButton, OrganisationAvatar } from '@lynx/client-core/src/components'
import css from './OrganisationAvatarEdit.module.scss'
import { useTranslation } from 'react-i18next'
import { InputMessages } from '@lynx/client-core/src/components/Input/InputMessages'
import FileInput from '@lynx/client-core/src/components/FilePicker/FileInput'
import { createCircularImage, cropImageToSquare, resizeImage } from '@lynx/client-core/src/utils'

interface Props {
	setUploadedFileAvatar: (file: File | null) => void
	uploadedFileAvatar: File | null
	avatarUrl: string
	isDriveEnabled: boolean
}

export const OrganisationAvatarEdit = ({ setUploadedFileAvatar, uploadedFileAvatar, avatarUrl, isDriveEnabled }: Props): React.ReactElement => {
	const url = uploadedFileAvatar && URL.createObjectURL(uploadedFileAvatar)
	const { t } = useTranslation()

	const onUploadedFile = async (file: File): Promise<void> => {
		const croppedImg = await cropImageToSquare(file)
		if (!croppedImg) return

		const resizedFile = await resizeImage(croppedImg, 200, 200)
		if (!resizedFile) return

		const newMimeType = 'image/png'
		const circularImg = await createCircularImage(resizedFile, newMimeType)
		setUploadedFileAvatar(circularImg)
	}

	return (
		<div className={css.container}>
			<div className={css.pictureContainer}>
				<OrganisationAvatar avatarUrl={url || avatarUrl} />
			</div>
			<div className={css.pickerContainer}>
				<FileInput accept={'.png,.bmp,.jpg,.jpeg'} onChange={onUploadedFile} style={{}} disabled={!isDriveEnabled}>
					<BasicButton size="xs" disabled={!isDriveEnabled}>
						{t('components.modals.organisationUserEditModal.changeImage')}
					</BasicButton>
				</FileInput>
				{!isDriveEnabled && (
					<InputMessages messages={[{ message: t('components.modals.organisationUserEditModal.enableDriveToChangeImage'), type: 'info' }]} />
				)}
			</div>
		</div>
	)
}
