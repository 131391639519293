import { downloadCsv } from '../../utils'
import React, { useEffect, useRef, useState } from 'react'
import { SortDirection, TableHeaders, TableProps } from './interfaces'
import css from './Table.module.css'
import { TableBody } from './TableBody'
import { TableHeader } from './TableHeader'
import { TablePagination } from './TablePagination'
import { TableToolbar } from './TableToolbar'
import { isString } from '@lynx/core/src/string'

export const Table = <TItem,>({
	initialSortHeaderKey,
	items,
	headers,
	onRowClick,
	csvFileName = 'table.csv',
	toolBarItems,
	title,
	pageSizes,
	isCustomSearchRender = false,
	totalCount,
	onPageChange,
	onTableSearchChange,
	showSearch = true,
	isSearchVisible,
	onRefetch,
	showPagination = true,
	initialPageSizeIndex = 0,
	gridTemplateOverride = undefined
}: TableProps<TItem>): React.ReactElement => {
	const [sortDirection, setSortDirection] = useState<SortDirection>('DESC')
	const paginationPageSizes = pageSizes || [10, 20, 50, 80]
	const [sortField, setSortField] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const [pageSize, setPageSize] = useState(paginationPageSizes[initialPageSizeIndex])
	const [tableSearchValue, setTableSearchValue] = useState('')
	const tableRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		onPageChange && onPageChange(currentPage)
	}, [currentPage])

	useEffect(() => {
		onTableSearchChange && onTableSearchChange(tableSearchValue)
		setCurrentPage(1)
	}, [tableSearchValue])

	useEffect(() => {
		if (items.length && initialSortHeaderKey) {
			setSortField(initialSortHeaderKey)
		}
	}, [items, initialSortHeaderKey])

	const columnsCount = headers.length
	const gridTemplate: React.CSSProperties = gridTemplateOverride || {
		display: 'grid',
		gridTemplateRows: `repeat(${columnsCount}, auto)`,
		gridTemplateColumns: `repeat(${columnsCount}, 1fr)`
	}

	const onDownloadCsv = (): void => {
		const csvItems = items.map((item) => headers.map(({ valueKey }) => item[valueKey as keyof TItem]))
		const csvHeaders = headers.map((e) => e.label)
		downloadCsv(csvItems, csvHeaders, csvFileName)
	}

	const paginatedFilteredSortedItems = (): TItem[] => {
		const filteredItems = tableSearchValue ? filterItems(items, headers, tableSearchValue) : items
		const paginatedAndFilteredItems = paginateItems(filteredItems, pageSize, currentPage)
		const sortedItems = sortDirection && sortField ? sortItems(paginatedAndFilteredItems, sortField, sortDirection, headers) : paginatedAndFilteredItems
		return sortedItems
	}

	const notPaginatedFilteredSortedItems = (): TItem[] => {
		const filteredItems = tableSearchValue ? filterItems(items, headers, tableSearchValue) : items
		const sortedItems = sortDirection && sortField ? sortItems(filteredItems, sortField, sortDirection, headers) : filteredItems
		return sortedItems
	}

	const internalItems = isCustomSearchRender ? notPaginatedFilteredSortedItems() : paginatedFilteredSortedItems()

	return (
		<div>
			<div className={css.toolbarWrapper}>
				{isString(title) ? <h1 className={css.toolbarTitle}>{title}</h1> : title}
				<TableToolbar
					isSearchVisible={isSearchVisible}
					onDownloadCsv={onDownloadCsv}
					tableSearchValue={tableSearchValue}
					setTableSearchValue={setTableSearchValue}
					tableRef={tableRef}
					toolBarItems={toolBarItems}
					showSearch={showSearch}
					onRefetch={onRefetch}
				/>
			</div>
			<div role="grid" className={css.container} ref={tableRef}>
				<TableHeader
					gridTemplate={gridTemplate}
					headers={headers}
					sortDirection={sortDirection}
					setSortDirection={setSortDirection}
					sortField={sortField}
					setSortField={setSortField}
				/>
				<TableBody gridTemplate={gridTemplate} headers={headers} items={internalItems} tableSearchValue={tableSearchValue} onRowClick={onRowClick} />
				{showPagination && (
					<TablePagination
						pageSizes={paginationPageSizes}
						currentPage={currentPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setCurrentPage={setCurrentPage}
						totalCount={totalCount || filterItems(items, headers, tableSearchValue).length}
					/>
				)}
			</div>
		</div>
	)
}

const paginateItems = <TItem,>(items: TItem[], pageSize: number, currentPage: number): TItem[] => {
	const start = (currentPage - 1) * pageSize
	const end = currentPage * pageSize
	return items.slice(start, end)
}
const sortItems = <TItem,>(items: TItem[], field: string, direction: SortDirection, headers: TableHeaders): TItem[] => {
	const isAsc = direction === 'ASC'
	const { isSortable } = headers.find(({ valueKey }) => valueKey === field) || {}

	const isBoolean = <V,>(value: V): boolean => typeof value === 'boolean'
	const isValidToSort = (): boolean => {
		const isSortDataValid = field && direction
		const isColumnSortable = isBoolean(isSortable) ? isSortable : true
		return Boolean(isSortDataValid && isColumnSortable && items.length)
	}
	const getSortMethod = (): (<TItem>(a: TItem, b: TItem) => number) => {
		const sortTypes = {
			string: <TItem,>(a: TItem, b: TItem): number => {
				const toUpperCase = <V,>(val: V): V | string => (typeof val === 'string' ? val.toUpperCase() : val)
				const valueA = toUpperCase(a[field as keyof TItem])
				const valueB = toUpperCase(b[field as keyof TItem])
				if (isAsc ? valueA < valueB : valueA > valueB) return -1
				if (isAsc ? valueA > valueB : valueA < valueB) return 1
				return 0
			},
			number: <TItem,>(a: TItem, b: TItem): number => {
				const valueA = Number(a[field as keyof TItem])
				const valueB = Number(b[field as keyof TItem])
				return isAsc ? valueA - valueB : valueB - valueA
			},
			boolean: <TItem,>(a: TItem, b: TItem): number => sortTypes.number(a, b)
		}
		const valueType = typeof items[0][field as keyof TItem]
		if (valueType === 'boolean') return sortTypes.boolean
		if (valueType === 'number') return sortTypes.number
		return sortTypes.string
	}

	return isValidToSort() ? items.sort(getSortMethod()) : items
}

const filterItems = <TItem,>(items: TItem[], headers: TableHeaders, tableSearchValue: string): TItem[] => {
	return items.filter((item) =>
		headers.reduce((acc, header) => {
			const itemValue = item[header.valueKey as keyof TItem]
			if (typeof itemValue === 'string' || typeof itemValue === 'boolean' || typeof itemValue === 'number') {
				return acc || String(itemValue).toLocaleLowerCase().includes(tableSearchValue.toLocaleLowerCase())
			}
			return acc
		}, Boolean(false))
	)
}
