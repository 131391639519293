import { APIRequest } from '@lynx/client-core/src/api'
import { DriveItemType } from '@lynx/client-core/src/api/interfaces'
import { AreYouSure, Document } from '@lynx/client-core/src/components'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { AsyncStorage, Logger } from '@lynx/client-core/src/modules'
import { deleteQueryParam, truncateFileName } from '@lynx/client-core/src/utils'
import { splitToken } from '@lynx/core'
import { Providers } from '@lynx/core/src/interfaces/ObjectStore'
import * as Modals from 'components/modals'
import { useThunkDispatch } from 'hooks/useThunkDispatch'
import i18next from 'i18next'
import { RenameModal } from 'pages/Drives/Modals/RenameModal'
import { UnavailableDrive } from 'pages/Files/UnavailableDrive'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { ApplicationState } from 'store'
import envVars from 'data/env-vars'
import {
	addFavourite,
	changeDrive,
	deleteDriveItem,
	getDrivesSizes,
	getFavourites,
	loadUserDrives,
	popDrivePath,
	refreshDrivePath,
	removeFavourite,
	setHomeDrives
} from 'store/drive'
import {
	AreYouSureModalContextType,
	CreateNewFileModalContextType,
	DeleteFileModalContextType,
	MoveModalContextType,
	PublishModalContextType,
	RenameModalContextType,
	SharingModalContextType,
	hideModal,
	showModal
} from 'store/modal'
import { Controls } from './Controls'
import css from './Drive.module.scss'
import { DriveList } from './DriveList'
import { DriveFilters } from './DriveList/DriveList.types'
import { CreateNewFileModal, CreateNewFolderModal, DeleteFileModal, MoveModal, PublishModal, UploadStateModal } from './Modals'
import { useProviderUpload } from './hooks/useProviderUpload'

interface Props {
	initialProvider?: Providers
	isHomeButton?: boolean
}

export const Drive = (props: Props): React.ReactElement => {
	const { initialProvider, isHomeButton = true } = props
	const params = useParams<{ provider: Providers }>()
	const location = useLocation()
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | 'newest' | 'oldest' | null>(null)
	const { drive: driveState, config: configState, profile: profileState } = useSelector((state: ApplicationState) => state)
	const dispatchThunk = useThunkDispatch()
	const dispatch = useDispatch()
	const previousProvider = usePrevious(initialProvider || driveState.provider)
	const previousLoggedIn = usePrevious(profileState.loggedIn)
	const navigate = useNavigate()
	const queryParams = new URLSearchParams(location.search)
	const driveId = queryParams.get('driveId') || ''

	const [selectedFilter, setSelectedFilter] = React.useState<DriveFilters>('all')

	const getAvailableDrives = async (): Promise<void> => {
		await dispatchThunk(setHomeDrives())
		await dispatchThunk(loadUserDrives())
	}

	const { upload } = useProviderUpload()

	useEffect(() => {
		const update = async (): Promise<void> => {
			const isLoggedIn = !previousLoggedIn && profileState.loggedIn
			const isProviderChanged = previousProvider !== driveState.provider
			try {
				if (isLoggedIn || isProviderChanged) await getAvailableDrives()
				isLoggedIn && (await dispatchThunk(changeDrive(initialProvider || params.provider || null, driveId)))
				isLoggedIn && (await dispatchThunk(getDrivesSizes()))
				isLoggedIn && envVars.showFavourites && (await dispatchThunk(getFavourites()))
			} catch (e) {
				Logger.error(e)
			}
		}
		update()
	}, [driveState.provider, profileState.loggedIn])

	useEffect(() => {
		const mounted = async (): Promise<void> => {
			const getFilter = async (): Promise<void> => {
				const filter = (await AsyncStorage.getItem('drive-filter')) as DriveFilters
				if (filter) setSelectedFilter(filter || 'all')
			}
			getFilter()
			await dispatchThunk(getDrivesSizes())
		}
		mounted()
	}, [])

	const handleDeleteClicked = async (item: DriveItemType): Promise<void> => {
		const { provider } = driveState
		dispatch(
			showModal({
				name: 'DeleteFileModal',
				context: {
					type: DeleteFileModalContextType,
					handleConfirm: async (): Promise<void> => {
						if (!provider) return
						const deletedItem = await APIRequest.ObjectStore.delete(provider, item.id, driveId)
						if (deletedItem) {
							dispatch(deleteDriveItem(deletedItem.id))
						}
						dispatch(hideModal())
					},
					confirmText: i18next.t('pages.drive.modals.deleteModal.delete'),
					confirmBody: i18next.t('pages.drive.modals.deleteModal.bodyText') + ` ${truncateFileName(item.name, 14)}?`
				}
			})
		)
	}

	const handleDownloadClicked = async (item: DriveItemType): Promise<void> => {
		if (!item.provider) return
		const { provider, id, name, additionalData } = item
		try {
			await APIRequest.ObjectStore.download(provider, id, name, { providerContainerId: driveId, ...additionalData })
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleUploadClicked = upload
	const handleFileDropper = async (files: FileList): Promise<void> => Array.from(files).forEach(upload)

	const handleChangeFilter = async (filter: DriveFilters): Promise<void> => {
		if (!filter) return

		setSelectedFilter(filter)
		await AsyncStorage.setItem('drive-filter', filter)
	}

	const handleCreateFolderClicked = (): void => {
		dispatch(showModal({ name: 'CreateNewFolderModal' }))
	}

	const handleRenameClicked = (driveItem: DriveItemType): void => {
		dispatch(showModal({ name: 'RenameModal', context: { type: RenameModalContextType, driveItem } }))
	}

	const handleMoveClicked = (driveItem: DriveItemType): void => {
		dispatch(showModal({ name: 'MoveModal', context: { type: MoveModalContextType, driveItem } }))
	}

	const handleHomeClicked = async (): Promise<void> => {
		try {
			await dispatchThunk(changeDrive(null))
			if (driveState.writeable) window.history.replaceState(null, '', '/drive')
			else deleteQueryParam('driveId')
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleBackClicked = async (): Promise<void> => {
		const { provider } = driveState
		try {
			await dispatchThunk(popDrivePath(provider))
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleUnlinkClicked = ({ provider, id }: DriveItemType): void => {
		dispatch(
			showModal({
				name: 'AreYouSure',
				context: {
					type: AreYouSureModalContextType,
					handleConfirm: async (): Promise<void> => {
						const res = await APIRequest.ObjectStore.unlink(provider, id)
						if (res.status === 200) {
							dispatch(hideModal())
						}

						await getAvailableDrives()
						await dispatchThunk(changeDrive(null))
						await dispatchThunk(getFavourites())
						navigate(`/drive`, { replace: true })
					},
					confirmText: i18next.t('pages.profile.unlink'),
					confirmBody: i18next.t('pages.profile.unlinkConfirm')
				}
			})
		)
	}

	const handleShareClicked = async (item: DriveItemType): Promise<void> => {
		dispatch(showModal({ name: 'SharingModal', context: { type: SharingModalContextType, driveItem: item } }))
	}

	const handlePublishClicked = async (item: DriveItemType): Promise<void> => {
		dispatch(showModal({ name: 'PublishModal', context: { type: PublishModalContextType, driveItem: item } }))
	}

	const handleRemoveClicked = async (item: DriveItemType): Promise<void> => {
		dispatch(
			showModal({
				name: 'AreYouSure',
				context: {
					type: AreYouSureModalContextType,
					handleConfirm: async (): Promise<void> => {
						const removedItem = await APIRequest.Sharing.removeSymLink(item.id)
						if (removedItem) {
							dispatch(hideModal())
						}

						await dispatchThunk(refreshDrivePath())
					},
					confirmText: 'Remove',
					confirmBody: `Are you sure you want to remove ${item.name}?`
				}
			})
		)
	}

	const handleCreateClicked = (): void => {
		dispatch(
			showModal({
				name: 'CreateNewFileModal',
				context: {
					type: CreateNewFileModalContextType
				}
			})
		)
	}

	const handleOpenClicked = (item: DriveItemType): void => {
		window.open(item.webViewLink, '_blank')
	}

	const handleEditClicked = async (item: DriveItemType): Promise<void> => {
		try {
			const { id, provider, name } = item
			const extraData = await APIRequest.ObjectStore.extra(provider, id)

			let shareOwnerId = ''
			let token = ''
			if (extraData?.type === 'lynxcloud' && extraData?.isShared) {
				const { token: shareToken } = extraData.sharing
				token = shareToken
				const tokenDetails = splitToken(shareToken)
				shareOwnerId = tokenDetails.fileOwnerId
			}

			const paths = btoa(JSON.stringify(driveState.paths))
			window.open(
				`${configState.LYNXVNC_CLIENT}?file=${id}&shareId=${token}&shareOwnerId=${shareOwnerId}&name=${name}&userId=${profileState.userId}&provider=${provider}&paths=${paths}`,
				'_blank'
			)
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleFavouriteClick = async (item: DriveItemType): Promise<void> => {
		try {
			const { id } = item

			const isFavourite = driveState.favourites.some((fav) => fav.itemId === id)

			if (isFavourite) {
				await dispatchThunk(removeFavourite(id))
			} else {
				await dispatchThunk(addFavourite(id))
			}

			// const extraData = await APIRequest.ObjectStore.extra(provider, id)
		} catch (e) {
			Logger.error(e)
		}
	}

	const handleItemClick = (action: string, item: DriveItemType): void => {
		switch (action) {
			case 'download': {
				handleDownloadClicked(item)
				break
			}
			case 'edit': {
				handleEditClicked(item)
				break
			}
			case 'rename': {
				handleRenameClicked(item)
				break
			}
			case 'delete': {
				handleDeleteClicked(item)
				break
			}
			case 'move': {
				handleMoveClicked(item)
				break
			}
			case 'open': {
				handleOpenClicked(item)
				break
			}
			case 'share': {
				handleShareClicked(item)
				break
			}
			case 'publish': {
				handlePublishClicked(item)
				break
			}
			case 'remove': {
				handleRemoveClicked(item)
				break
			}
			case 'unlink': {
				handleUnlinkClicked(item)
				break
			}
		}
	}

	if (driveState.data.id === undefined && driveState.provider) {
		return (
			<Document title={i18next.t('components.drive.driveBrowser.title')} description="CloudDrive" keywords="CloudDrive" auth={true}>
				<UnavailableDrive config={configState} provider={driveState.provider} />
			</Document>
		)
	}

	return (
		<div className={css.container}>
			<AreYouSure />
			<DeleteFileModal />
			<MoveModal />
			<RenameModal />
			<PublishModal />
			<CreateNewFileModal />
			<CreateNewFolderModal />
			<Modals.SharingModal />
			<Controls
				provider={driveState.provider}
				selectedFilter={selectedFilter}
				onCreateClicked={handleCreateClicked}
				onHomeClicked={isHomeButton ? handleHomeClicked : undefined}
				onBackClicked={handleBackClicked}
				onUploadClicked={handleUploadClicked}
				onCreateFolderClicked={handleCreateFolderClicked}
				setSortDirection={setSortDirection}
				onFilterClicked={handleChangeFilter}
			/>
			<DriveList
				selectedFilter={selectedFilter}
				sortDirection={sortDirection}
				handleItemClick={handleItemClick}
				onFileDropper={handleFileDropper}
				onFavouriteClick={handleFavouriteClick}
			/>
			<UploadStateModal />
		</div>
	)
}
