import { Toggle } from '@lynx/client-core/src/components'
import { InputMessages } from '@lynx/client-core/src/components/Input/InputMessages'
import { usePrevious } from '@lynx/client-core/src/hooks'
import { allPermissionsData } from '@lynx/core/src/constants'
import { PermissionsKeys, PermissionsType } from '@lynx/core/src/interfaces'
import { useEffect, useState } from 'react'
import css from './UserPermissions.module.scss'

interface Props {
	togglePermission: (permissionKey: PermissionsKeys, value: boolean) => Promise<void>
	permissionKeys: PermissionsKeys[]
	permissions: PermissionsType
}

export const UserPermissions = ({ permissionKeys, togglePermission, permissions }: Props): React.ReactElement => {
	const [loadingKeys, setLoadingKeys] = useState<PermissionsKeys[]>([])
	const previousPermissions = usePrevious(permissions)
	const onToggle = async (permissionKey: PermissionsKeys, value: boolean): Promise<void> => {
		try {
			setLoadingKeys([...loadingKeys, permissionKey])
			await togglePermission(permissionKey, value)
		} catch (err) {
			setLoadingKeys((keys) => keys.filter((key) => key !== permissionKey))
		}
	}

	useEffect(() => {
		const handleUpdatedKeyLoadingState = (): void => {
			if (loadingKeys.length && previousPermissions) {
				loadingKeys.forEach((key) => {
					if (permissions[key].isGranted !== previousPermissions[key].isGranted) {
						setLoadingKeys((keys) => keys.filter((k) => k !== key))
					}
				})
			}
		}
		handleUpdatedKeyLoadingState()
	}, [permissions, previousPermissions, loadingKeys])

	return (
		<div className={css.list}>
			{permissionKeys.map((permission) => {
				const { label, description } = allPermissionsData[permission]
				return (
					<div key={permission} className={css.permissionContainer}>
						<div className={`${css.permission} ${!permissions[permission] ? css.enabled : ''} `}>
							<div className={css.label}>{label}</div>
							<InputMessages messages={[{ type: 'info', message: description }]} />
						</div>
						<Toggle
							isLoading={loadingKeys.find((key) => key === permission) !== undefined}
							checked={permissions[permission].isGranted}
							onChange={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => onToggle(permission, e.target.checked)}
						/>
					</div>
				)
			})}
		</div>
	)
}
