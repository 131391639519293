import { APIRequest } from '@lynx/client-core/src/api'
import { AccountWhiteIcon, AutocompleteInput, ProBadgeIcon } from '@lynx/client-core/src/components'
import { useState } from 'react'
import css from './UserSearch.module.scss'
import { AccountProfile } from '@lynx/core/src/interfaces'

interface Props {
	onUserSelected: (organisation: AccountProfile | null) => void
}

export const UserSearch = ({ onUserSelected }: Props): React.ReactElement => {
	const [users, setUsers] = useState<AccountProfile[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const searchUser = async (searchValue: string): Promise<void> => {
		setIsLoading(true)
		try {
			const data = await APIRequest.User.getAll({ searchText: searchValue, start: 1, length: 10 })
			setUsers(data?.data.accounts || [])
		} catch (e) {
			setUsers([])
		} finally {
			setIsLoading(false)
		}
	}

	const getUserCustomItemLabel = (item: AccountProfile): React.ReactElement => {
		return (
			<div className={css.item}>
				{getUserLabel(item)}
				{item.isPro ? <ProBadgeIcon /> : null}
			</div>
		)
	}
	const getUserLabel = (item: AccountProfile): string => `${item.email}`

	return (
		<div className={css.container}>
			<AutocompleteInput
				inputIcon={AccountWhiteIcon}
				getCustomItemLabel={getUserCustomItemLabel}
				inputLabel="LYNX Cloud Account"
				fetchItems={searchUser}
				items={users.map((o) => ({ ...o, userNameLabel: getUserLabel(o) }))}
				isLoading={isLoading}
				keyPath="userNameLabel"
				onItemSelected={(item: AccountProfile | null): void => onUserSelected(item)}
			/>
		</div>
	)
}
