import { Document } from '@lynx/client-core/src/components'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Route, Routes } from 'react-router-dom'
import { ApplicationState } from 'store'
import Styles from './Admin.module.scss'
import { ElmstonePages } from './routes/elmstone/ElmstonePages'
import { ReportsPages } from './routes/reports/ReportsPages'
import { SaharaPages } from './routes/sahara/SaharaPages'
import { useSearchParams } from 'react-router-dom'
import {
	AdminPermission,
	ReportsPermission,
	SaharaPermission,
	SubscriptionsAdminReadPermission,
	SubscriptionsAdminWritePermission,
	TranslationPermission
} from '@lynx/core/src/constants'
import { hasUserPermission } from '@lynx/core'
import { PermissionsKeys } from '@lynx/core/src/interfaces'

const panels: {
	title: string
	permissions: PermissionsKeys[]
	links: { name: string; uri: string; permissions: PermissionsKeys[]; isFullReload?: boolean }[]
}[] = [
	{
		title: 'Reports',
		permissions: [ReportsPermission],
		links: [
			{
				name: 'Reg Report',
				uri: '/admin/reports/reg-report',
				permissions: [ReportsPermission]
			},
			{
				name: 'Reg Report by Month',
				uri: '/admin/reports/reg-report-by-month',
				permissions: [ReportsPermission]
			},
			{
				name: 'Reg Report by Version',
				uri: '/admin/reports/reg-report-by-version',
				permissions: [ReportsPermission]
			},
			{
				name: 'Reg Report by Country',
				uri: '/admin/reports/reg-report-by-country',
				permissions: [ReportsPermission]
			},
			{
				name: 'Reg Report by OS',
				uri: '/admin/reports/reg-report-by-os',
				permissions: [ReportsPermission]
			},
			{
				name: 'Reg Report by Recent Usage',
				uri: '/admin/reports/reg-report-recent-multiple-usage',
				permissions: [ReportsPermission]
			},
			{
				name: 'Organisation Report',
				uri: '/admin/reports/organisation-report',
				permissions: [ReportsPermission]
			},
			{
				name: 'MyScript Report',
				uri: '/admin/reports/myscript',
				permissions: [ReportsPermission]
			},
			{
				name: 'LYNX Registrations',
				uri: '/admin/reports/registrations',
				permissions: [ReportsPermission]
			}
		]
	},
	{
		title: 'Sahara',
		permissions: [SaharaPermission, SubscriptionsAdminWritePermission, SubscriptionsAdminReadPermission, TranslationPermission],
		links: [
			{
				name: 'Charts',
				uri: '/admin/sahara/charts',
				permissions: [SaharaPermission]
			},

			{
				name: 'Mailing List',
				uri: '/admin/sahara/mailing-list',
				permissions: [SaharaPermission]
			},
			{
				name: 'Whiteboard Translations',
				uri: '/admin/sahara/translations',
				permissions: [TranslationPermission]
			},
			{
				name: 'Website Translations',
				uri: '/admin/sahara/lynxTranslations',
				permissions: [TranslationPermission]
			},
			{
				name: 'Subscriptions',
				uri: '/admin/elmstone/subscriptions?hideList=true',
				permissions: [SubscriptionsAdminWritePermission, SubscriptionsAdminReadPermission]
			},
			{
				name: 'Payment Audit Trail',
				uri: '/admin/elmstone/payment-audit-trail?hideList=true',
				permissions: [AdminPermission]
			},
			{
				name: 'Enquiries',
				uri: '/admin/elmstone/enquiries',
				permissions: [SaharaPermission]
			}
		]
	},
	{
		title: 'Elmstone',
		permissions: [],
		links: [
			{
				name: 'Country Fix',
				uri: '/admin/elmstone/country-fix',
				permissions: []
			},
			{
				name: 'LYNX 6 Code Generator',
				uri: '/admin/elmstone/code-gen',
				permissions: []
			},
			{
				name: 'Feedback Log',
				uri: '/admin/elmstone/feedback',
				permissions: []
			},
			{
				name: 'Sites',
				uri: '/admin/elmstone/sites',
				permissions: []
			},
			{
				name: 'LYNX WASM',
				uri: '/admin/sahara/wasm?hideList=true',
				isFullReload: true,
				permissions: []
			},

			{
				name: 'Base64 Decoder',
				uri: '/admin/elmstone/base64',
				permissions: []
			}
		]
	}
]
export const Admin = (): React.ReactElement => {
	const { permissions: userPermissions } = useSelector((state: ApplicationState) => state.profile)

	const [searchParams] = useSearchParams()
	const isHideList = !(searchParams.get('hideList') === 'true')

	const isAccessible = (placePermissions: PermissionsKeys[]): boolean => {
		const hasPermission = hasUserPermission(placePermissions, userPermissions)

		return hasPermission
	}
	const panelElements = panels
		.filter((panel) => isAccessible(panel.permissions))
		.map((panel) => {
			const { links, title } = panel
			const uriElements = links
				.filter((link) => isAccessible(link.permissions))
				.map((uriDefinition) => {
					const { uri, name } = uriDefinition
					const isFullReload = 'isFullReload' in uriDefinition && uriDefinition.isFullReload
					return <li key={uriDefinition.name}>{isFullReload ? <a href={uri}>{name}</a> : <NavLink to={uri}>{name}</NavLink>}</li>
				})
			return (
				<div key={title}>
					<div>
						<h3>{title}</h3>
						<ul>{uriElements}</ul>
					</div>
				</div>
			)
		})
	return (
		<Document title="Admin">
			<div className={Styles.versionContainer}>Version {process.env.REACT_APP_BUILD_VERSION}</div>
			{isHideList && <div className={Styles['cards']}>{panelElements}</div>}
			<Routes>
				<Route path="elmstone/*" element={<ElmstonePages />} />
				<Route path="sahara/*" element={<SaharaPages />} />
				<Route path="reports/*" element={<ReportsPages />} />
			</Routes>
		</Document>
	)
}
