import { Badge } from '@lynx/client-core/src/components'
import { SearchOrganisation } from '@lynx/core/src/interfaces/Organisation'
import css from './tableColumns.module.scss'
import { AccountProfile } from '@lynx/core/src/interfaces'
import { getStripeDashboardPath } from '@lynx/core'

const color = {
	draft: 'blue',
	open: 'blue',
	paid: 'green',
	uncollectible: 'grey',
	void: 'grey',
	past_due: 'red'
} as const

const statusMapping = {
	draft: 'AWAITING INVOICE',
	open: 'AWAITING PAYMENT',
	paid: 'PAID',
	uncollectible: 'UNCOLLECTIBLE',
	void: 'VOID',
	past_due: 'OVERDUE'
}

export const InvoiceStatus = ({ item }: { item: SearchOrganisation | AccountProfile }): React.ReactElement => {
	const status = item.subscriptionData?.invoiceStatus
	const statusText = statusMapping[status as keyof typeof statusMapping]
	const badgeVariant = color[status as keyof typeof color]
	const badge = (
		<Badge variant={badgeVariant} size="xs">
			<div className={css.badgeStatus}>{statusText}</div>
		</Badge>
	)

	return <div className={css.statusContainer}>{status ? <div title={status}>{badge}</div> : <></>}</div>
}

export const PaymentDue = ({ item }: { item: SearchOrganisation | AccountProfile }): React.ReactElement | null => {
	const { subscriptionData } = item
	const paymentDue = subscriptionData?.paymentDue
	if (!paymentDue) return null
	const date = new Date(paymentDue * 1000)
	return <div>{paymentDue ? date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }) : ''}</div>
}

export const getEditColumnData = (
	item: SearchOrganisation | AccountProfile,
	environment: string
): {
	canSendInvoice: boolean
	canMarkAsPaid: boolean
	canMarkAsVoid: boolean
	canMarkAsUncollectible: boolean
	invoiceUrl: string
	invoiceId: string | null | undefined
	customerId: string | null | undefined
} => {
	const subscriptionData = item.subscriptionData
	const invoiceId = subscriptionData?.invoiceId
	const customerId = subscriptionData?.customerId

	const canSendInvoice = item.subscriptionData?.invoiceStatus === 'open' || item.subscriptionData?.invoiceStatus === 'draft'
	const isOpenStatus = item.subscriptionData?.invoiceStatus === 'open'
	const isUncollectibleStatus = item.subscriptionData?.invoiceStatus === 'uncollectible'
	const isPastDueStatus = item.subscriptionData?.invoiceStatus === 'past_due'

	const canMarkAsPaid = isOpenStatus || isUncollectibleStatus
	const canMarkAsVoid = isOpenStatus || isUncollectibleStatus
	const canMarkAsUncollectible = isOpenStatus || isPastDueStatus

	const invoiceUrl = `${getStripeDashboardPath(environment === 'production')}/invoices/${invoiceId}`

	return {
		canSendInvoice,
		canMarkAsPaid,
		canMarkAsVoid,
		canMarkAsUncollectible,
		invoiceUrl,
		invoiceId,
		customerId
	}
}
